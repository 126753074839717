import React from "react";
import bgCircle from "../../assets/images/icons/blue-circle.svg";
import googleplay from "../../assets/images/googleplay.png";
import appImg from "../../assets/images/app.png";
import appQr from "../../assets/images/app-qr.jpg";
import downloadAppImg from "../../assets/images/download-app.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const DownloadApp = () => {
  return (
    <>
      <section className="main-property-sec">
        <div className="container">
          <div className="partners-sec get-app">
            <div className="row">
              <div className="col-12 col-lg-12 col-xl-10 mx-auto">
                <div className="easy-stay-card">
                  <div className="row">
                    <div className="col-12 col-md-7">
                      <div className="content">
                        <h2>Download Our App</h2>
                        <ul className="content-list">
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <span>Manage your Rents &amp; Reciepts</span>
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <span>Rent a Property</span>
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            <span>Get prompt maintainance support</span>
                          </li>
                        </ul>
                        <div className="mt-5">
                          <div className="app-info-wrap">
                            <ul>
                              <li>
                                <Link to="">
                                  <img src={googleplay} alt="" />
                                </Link>
                              </li>
                              <li>
                                <Link to="">
                                  <img src={appImg} alt="" />
                                </Link>
                              </li>
                            </ul>
                            <div className="app-qr">
                              <img src={appQr} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-5 position-unset">
                      <div className="image">
                        <img src={downloadAppImg} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-imgs">
          <img src={bgCircle} className="bg-08" alt="" />
        </div>
      </section>
    </>
  );
};

export default DownloadApp;
