// validateForm.js

export const validateFormPropertyOwners = (formData) => {
  let formErrors = {};
  const errors = {};

  // First Name Validation
  if (!formData.firstName.trim()) {
    formErrors.firstName = "First Name is required";
  }

  // Last Name Validation
  if (!formData.lastName.trim()) {
    formErrors.lastName = "Last Name is required";
  }

  // Email Validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!formData.email) {
    formErrors.email = "Email is required";
  } else if (!emailRegex.test(formData.email)) {
    formErrors.email = "Enter a valid email address";
  }

  // Property Type Validation
  if (!formData.propertyType || formData.propertyType === "0") {
    formErrors.propertyType = "Select a property type";
  }

  // Contact Number Validation
  if (!formData.contactNumber) {
    formErrors.contactNumber = "Contact number is required";
  } else if (formData.contactNumber.length < 13) {
    formErrors.contactNumber = "Add a Contact number valid number";
  }

  // WhatsApp Number Validation
  if (!formData.whatsappNumber) {
    formErrors.whatsappNumber = "WhatsApp number is required";
  } else if (formData.whatsappNumber.length < 13) {
    formErrors.whatsappNumber = "Add WhatsApp number valid number";
  }

  // Property Address Validation
  if (!formData.propertyAddress) {
    formErrors.propertyAddress = "Property address is required.";
  } else if (formData.propertyAddress.length < 10) {
    formErrors.propertyAddress =
      "Property address must be at least 10 characters.";
  } else if (formData.propertyAddress.length > 255) {
    formErrors.propertyAddress =
      "Property address must not exceed 255 characters.";
  }
  return formErrors;
};
