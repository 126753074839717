import React, { useState, useEffect, useCallback, useRef } from "react";
import { Calendar } from "primereact/calendar"; // Import Calendar from primereact
import { Button, Form } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCalendarCheck,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { validateScheduleMeetingForm } from "../../utils/validation/validate-schedule-meetingForm";
import { submitScheduleVisit } from "../../services/schedule-meeting-service";
import { handleErrors } from "../../utils/errorHandler";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-video.css";
import videoThumbnail from "../../assets/images/video-thubnail.jpg";
import lgVdeo from "lightgallery/plugins/video";
import { getLeadRequestDetails } from "../../services/contact-crm-service";
const visitType = {
  virtual: "Virtual",
  person: "In Person",
};
const ScheduleMeeting = ({
  showCancel = false,
  busy,
  onCancleBtnClick,
  vitualVisit,
}) => {
  const [scheduleVisitInfo, setScheduleVisitInfo] = useState(false);
  const [visitBookingStatus, setVisitBookingStatus] = useState(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [isTimeSlotAvailable, setIsTimeSlotAvailable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); // Add state for submission status
  const [isVisitType, setVisitType] = useState(visitType.person);
  const todayDate = new Date();
  const vLlightGallery = useRef(null);

  const timeSlots = [
    { value: "", label: "Select Time" },
    { value: "1", label: "09:00 AM - 11:00 AM" },
    { value: "2", label: "11:00 AM - 01:00 PM" },
    { value: "3", label: "01:00 PM - 03:00 PM" },
    { value: "4", label: "03:00 PM - 05:00 PM" },
    { value: "5", label: "05:00 PM - 07:00 PM", disabled: !isTimeSlotAvailable },
  ];
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    arrival: "",
    visit: "",
    timeSlot: "",
  });
  const [errors, setErrors] = useState({});
  const [scheduleError, setScheduleError] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  useEffect(() => {
    const checkTimeSlotAvailability = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      setIsTimeSlotAvailable(currentHour < 19); // Disable the last time slot if it's past 7 PM
    };
    checkTimeSlotAvailability();
    const interval = setInterval(checkTimeSlotAvailability, 60000);
    return () => clearInterval(interval);
  }, []);
  // Calculate the date range: today to one month ahead
  const today = new Date();
  const oneMonthLater = new Date();
  oneMonthLater.setMonth(today.getMonth() + 1); // Move one month ahead
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "timeSlot" && value === "") {
      return; // Don't update if "Select Time" is chosen
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDateChange = (e) => {
    setFormData({ ...formData, arrival: e.value });
  };
  const handleSubmit = async () => {
    const validationErrors = validateScheduleMeetingForm({
      ...formData,
      phoneNumber: phoneNumberValue,
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const propertyUrl = window.location.href;
        const timeSlotLabel = getSelectedTimeSlotLabel(formData.timeSlot);

        const payload = {
          visitorName: formData.name,
          visitorEmail: formData.email,
          visitorContact: phoneNumberValue,
          visitDate: formData.arrival,
          visitTime: timeSlotLabel,
          propertyUrl,
        };
        const crmPayload = {
          FullName: formData.name,
          EmailId: formData.email,
          ContactNumber: phoneNumberValue,
          Country: "",
          Notes: "Schedule Visit",
        };
        // const response = await submitScheduleVisit(payload);
        // const crmLead = await getLeadRequestDetails(crmPayload);
        const [response, crmLead] = await Promise.all([
          submitScheduleVisit(payload),
          getLeadRequestDetails(crmPayload),
        ]);
        if (response.status === 200 || crmLead.status === 200) {
          setVisitBookingStatus(true);
          setSubmissionStatus("Visit booked successfully!");
        } else {
          throw new Error("Failed to book the visit.");
        }
      } catch (error) {
        const errorMessage = handleErrors(error);
        setScheduleError(errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const handleProceedClick = () => {
    const newErrors = {};

    // Validate Visit Type
    if (!formData.visit) {
      newErrors.visit = "Please select a visit type.";
    }

    // Validate Date (arrival)
    if (!formData.arrival) {
      newErrors.arrival = "Please select a date for your visit.";
    }

    // Validate Time Slot
    if (formData.timeSlot === "") {
      newErrors.timeSlot = "Please select a time slot for your visit.";
    }

    // Check if there are validation errors
    if (Object.keys(newErrors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));
      return;
    }

    setErrors({});
    setScheduleVisitInfo(true);
  };
  const handleVideoClick = () => {
    if (onCancleBtnClick) {
      onCancleBtnClick(); 
    }
  };

  const getSelectedTimeSlotLabel = (value) => {
    const selectedSlot = timeSlots.find((slot) => slot.value === value);
    return selectedSlot ? selectedSlot.label : ""; // Return label or empty if not found
  };
  // const onInit = useCallback((detail) => {
  //   if (detail) {
  //     lightGallery.current = detail.instance;
  //   }
  // }, []);

  // const onOpen = (index) => {
  //   lightGallery.current.openGallery(index);
  // };
 const handleOpenGallery = () => {
    if (formData.visit === visitType.virtual && vLlightGallery.current) {
        // Open LightGallery if Virtual Tour is active
        vLlightGallery.current.openGallery(0); // Opening the gallery at the first index
    } else {
        console.log("Gallery will not open. Virtual Tour is not active.");
    }
};

  const formatCustomDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date
      .toLocaleDateString("en-US", options)
      .replace(",", "")
      .replace(/\s/g, "-");
  };
  return (
    <div className="book-schdule-card">
      {scheduleError ? (
        <div className="alert alert-danger" role="alert">
          Something went wrong, Please try again !
        </div>
      ) : (
        <div className="schedule-visit-form">
          {scheduleVisitInfo ? (
            <>
              {visitBookingStatus ? (
                <>
                  {formData.visit === visitType.person && (
                    <div className="visit-result">
                      <div className="icon">
                        <FontAwesomeIcon icon={faCalendarCheck} />
                        <h5>Visit Booked!</h5>
                        <span>{submissionStatus}</span>
                      </div>
                      <div className="visit-timing">
                        {formatCustomDate(formData.arrival)},{" "}
                        {getSelectedTimeSlotLabel(formData.timeSlot)}
                      </div>
                    </div>
                  )}

                  {formData.visit === visitType.virtual && (
                    <div className="visit-result">
                      <div className="icon mb-0">
                        <h5 className="text-center mt-3">Virtual Tour</h5>
                        <div className="text-center mt-3 d-flex justify-content-center">
                          {formData.visit === visitType.virtual && vitualVisit && vitualVisit.length > 0 ? (
                            // Display video if available
                            <LightGallery
                              ref={vLlightGallery}
                              plugins={[lgZoom, lgVdeo]}
                              speed={500}
                              elementClassNames={"gallery"}
                              
                            >
                              <div
                                className={"gallery__item"}
                                data-lg-size="1280-720"
                                data-src={vitualVisit[0].item.fileLink}
                                data-sub-html=""
                                 //onClick={() => handleOpenGallery(0)} 
                                data-index={1}
                              >
                                <div className="video-wrap">
                                  <img
                                    className="img-responsive"
                                    src={videoThumbnail}
                                    alt="Video Thumbnail"
                                  />
                                  <FontAwesomeIcon icon={faPlayCircle} />
                                </div>
                              </div>
                            </LightGallery>
                          ) : (
                            // Display "Thanks for Submitting" if no video is available
                            <div className="alert alert-success">
                              <span>
                                Thank you! Your visit has been scheduled
                                successfully.
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div
                    className="schedule-visit-info-title"
                    onClick={() => setScheduleVisitInfo(!scheduleVisitInfo)}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <span>
                      Edit{" "}
                      {formData.visit === visitType.person && "Date & Time"}
                    </span>
                  </div>
                  <Form.Group className="form-group" controlId="name">
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your Name*"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {errors.name && (
                      <span style={{ color: "red" }}>{errors.name}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email Address*"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <span style={{ color: "red" }}>{errors.email}</span>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group" controlId="phone-number">
                    <Form.Label>Phone Number</Form.Label>
                    <PhoneInput
                      international
                      type="phone"
                      maxLength="15"
                      defaultCountry="IN"
                      value={phoneNumberValue}
                      onChange={setPhoneNumberValue}
                    />
                    {errors.phoneNumber && (
                      <span style={{ color: "red" }}>{errors.phoneNumber}</span>
                    )}
                  </Form.Group>
                  <div className="submit-btn">
                    <Button
                      className="submit-btn"
                      type="button"
                      name="date-time-submit-btn"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                  {submissionStatus && (
                    <div className="text-danger mt-3">{submissionStatus}</div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <h2>Schedule a Visit</h2>
              <Form.Group className="form-group" controlId="unit">
                <Form.Label>Select Visit Type</Form.Label>
                <Form.Select
                  className="select"
                  name="visit-type"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      arrival: todayDate.toISOString().split(".")[0] + ".000Z",
                      timeSlot: "1",
                      visit: e.target.value,
                    });
                    setVisitType(e.target.value);
                  }}
                  value={formData.visit}
                >
                  <option value="">Select Visit Type</option>
                  <option value={visitType.person}>{visitType.person}</option>
                  <option value={visitType.virtual}>{visitType.virtual}</option>
                </Form.Select>
                {errors.visit && (
                  <span style={{ color: "red" }}>{errors.visit}</span>
                )}
              </Form.Group>
              {isVisitType === visitType.person && (
                <>
                  <div className="calender mb-0">
                    <Form.Label>Select Day</Form.Label>
                  </div>
                  <div className="arrival-div form-group">
                    <Calendar
                      className="schedule-visit-calendar"
                      value={formData.arrival}
                      onChange={handleDateChange}
                      minDate={today} // Set the minimum date to today
                      maxDate={oneMonthLater}
                      showIcon
                      placeholder="Select Date"
                      dateFormat="dd-mm-yy"
                    />
                    {errors.arrival && (
                      <span style={{ color: "red" }}>{errors.arrival}</span>
                    )}
                  </div>
                  <div className="review-form mb-0">
                    <Form.Group className="form-group mb-0" controlId="unit">
                      <Form.Label>Select Time</Form.Label>
                      <Form.Select
                        className="select"
                        name="timeSlot"
                        value={formData.timeSlot}
                        onChange={handleInputChange}
                      >
                        {timeSlots.map((slot, index) => (
                          <option
                            key={index}
                            value={slot.value}
                            disabled={slot.disabled}
                          >
                            {slot.label}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.timeSlot && (
                        <span style={{ color: "red" }}>{errors.timeSlot}</span>
                      )}
                    </Form.Group>
                  </div>
                </>
              )}

              <div className="submit-btn-wrap">
                {showCancel && (
                  <Button
                    className="cancel-btn btn-secondary"
                    onClick={onCancleBtnClick}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  className="submit-btn"
                  type="button"
                  name="date-time-submit-btn"
                  onClick={handleProceedClick}
                >
                  Proceed
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default ScheduleMeeting;
