import apiClient from "./axios.config";

const wishlistService = {
  postWishlist(flatId) {
    return apiClient.post(`customer/wishlist/add?flatId=${flatId}`);
  },
  deleteWishlist(id) { 
    return apiClient.delete(`customer/wishlist/remove/${id}`);
  },
  getWishlist() {
    return apiClient.get(`customer/wishlist/get-wishlist`);
  },
};

export default wishlistService;
