import React from "react";

const TermsCondition = () => {
  return (
    <div className="privacy-policy-wrap">
      <div className="policy-item">
        <div className="title">
          <h2>Introduction</h2>
        </div>
        <div className="content" id="introduction">
          <p>
            These Website Standard Terms and Conditions written on this webpage
            shall manage your use of our website, sowerent Technology
            LLPaccessible at sowerent.com.
          </p>
          <p>
            Rentiseasy (“we” or “us” or “our”) owns and manages the website
            sowerent.com, the sowerent Technology LLP guest app, the blogs, and
            all its subdomains (collectively the “Site”), where visitors can
            find information about our company and our services. These website
            terms of service (the “Terms”) describe the rights and obligations
            of a website user or visitor (“Visitor” or “you”) in connection with
            your use of the Site. These Terms of Use relate to your use of the
            Site only, if you book accommodation through the Site, you may also
            need to enter into separate accommodation agreements.
          </p>
          <p>
            Certain capitalized terms used in this policy but not defined have
            the meaning set forth in our Privacy Policy, which also includes
            additional details about the collection and use of information at
            Sowerent.
          </p>
        </div>
      </div>
      <div className="policy-item" id="terms">
        <div className="title">
          <h2>Terms</h2>
        </div>
        <div className="content">
          <p>
            By visiting our Site in any way, including as an unregistered
            website Visitor, you acknowledge and agree to these website Terms.
            These Terms are applicable only to your use of the Site. If you use
            or access any of our physical spaces, any web-based services
            requiring registration and logging in, our referral program or other
            services or programs we provide, your use of such spaces, services
            or programs is subject to the terms and conditions you received or
            accepted when you signed up for such spaces, services or programs.
            If you do not agree with any of these terms, you are prohibited from
            using or accessing this site. If you accept or agree to these Terms
            on behalf of a company or other legal entity, you represent and
            warrant that you have the authority to bind that company or other
            legal entity to these Terms and, in such event, “you” and “your”
            will refer and apply to that company or other legal entity.
          </p>
        </div>
      </div>
      <div className="policy-item" id="content-and-use">
        <div className="title">
          <h2>Content and use</h2>
        </div>
        <div className="content">
          <p>
            Any text, images, videos, audio clips, software and other content
            available on or through the Site (collectively, “Content”) are owned
            by us and our licensors and as such are protected by international
            copyright laws. We and our licensors retain all proprietary rights
            in the Site and the Content made available on or through the Site,
            and, except as expressly set forth in these Terms, no rights are
            granted to any Content. Subject to these Terms, we grant each user
            of the Site a worldwide, non-exclusive, non-sublicensable and
            non-transferable license to use our (i.e., to download and display
            locally) Content solely for viewing, browsing and using the
            functionality of the Site. All Content is for general informational
            purposes only. You may not: modify or copy the materials; use the
            materials for any commercial purpose, or for any public display
            (commercial or non-commercial); attempt to decompile or reverse
            engineer any software contained on our Website; remove any copyright
            or other proprietary notations from the materials; or transfer the
            materials to another person or 'mirror' the materials on any other
            server. We reserve the right, but do not have any obligation to
            monitor, remove, modify or remove any Content at any time for any
            reason or for no reason at all.
          </p>
        </div>
      </div>
      <div className="policy-item" id="eligibility">
        <div className="title">
          <h2>Eligibility</h2>
        </div>
        <div className="content">
          <p>
            The Site and services and programs mentioned are available only to
            individuals who are at least 18 years old, unless we specify
            otherwise. No one under this age should visit or use the Site and no
            one under this age should provide any personal information through
            the Site.
          </p>
        </div>
      </div>
      <div
        className="policy-item"
        id="disclaimer-and-limitation-of-liability-of-content"
      >
        <div className="title">
          <h2>Disclaimer and limitation of liability of content</h2>
        </div>
        <div className="content">
          <p>
            While we strive to always keep the site's content accurate, the Site
            may contain technical inaccuracies and typographical or other errors
            in connection with information displayed on the Site, including
            without limitation to rates, fees, or availability applicable. In no
            event shall sowerent Technology LLP or Rentiseasy be liable for any
            direct, indirect, punitive, incidental, special or consequential
            damages arising out of, or in any way connected with, such errors,
            inaccuracies, or omissions. sowerent Technology LLP or Rentiseasy
            shall have the right not to honor reservations or information
            affected by such errors, inaccuracies, or omissions. sowerent
            Technology LLPor Rentiseasy shall have the right to make changes,
            corrections, cancellations, and/or improvements to such information
            or reservations based on such information, at any time, including
            after confirmation of a reservation.
          </p>
          <p>
            To the extent permitted by law, we and our affiliates, parents, and
            successors and each of our and their employees, assignees, officers,
            agents and directors (collectively, the “sowerent Technology LLP or
            Rentiseasy Parties”) disclaim all warranties and terms, express or
            implied, with respect to the Site, Content or Services (including
            third party services) on or accessible through the Site, including
            any warranties or terms of merchantability, fitness for a particular
            purpose, title, non-infringement and any implied warranties, or
            arising from course of dealing, course of performance or usage in
            trade. In no event shall the sowerent Technology LLP or Rentiseasy
            Parties be liable under contract, tort, strict liability, negligence
            or any other legal or equitable theory with respect to the Site for
            (a) any special, indirect, incidental, punitive, compensatory or
            consequential damages of any kind whatsoever (however arising) or
            (b) damages in excess of (in the aggregate) INR 1000.
          </p>
        </div>
      </div>
      <div className="policy-item" id="liability-of-links">
        <div className="title">
          <h2>Liability of links</h2>
        </div>
        <div className="content">
          <p>
            We have not reviewed all of the sites linked to our Site and we are
            not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by sowerent
            Technology LLPor Rentiseasy of the site. Use of any such linked web
            site is at the user's own risk.
          </p>
        </div>
      </div>
      <div className="policy-item" id="governing-Law">
        <div className="title">
          <h2>Governing Law</h2>
        </div>
        <div className="content">
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of the Karnataka, India.
          </p>
        </div>
      </div>
      <div className="policy-item" id="entire-agreement">
        <div className="title">
          <h2>Entire agreement</h2>
        </div>
        <div className="content">
          <p>
            These Terms constitute the entire agreement between us regarding the
            Site and supersedes and merges any prior proposals, understandings
            and contemporaneous communications. If any provision of these Terms
            is held to be invalid or unenforceable, that provision shall be
            limited or eliminated to the minimum extent necessary so that these
            Terms shall otherwise remain in full force, in effect and
            enforceable. In order for any waiver of compliance with these Terms
            to be binding, we must provide you with written notice of such
            waiver. The failure of either party to enforce its rights under
            these Terms at any time for any period will not be construed as a
            waiver of such rights.
          </p>
        </div>
      </div>
      <div className="policy-item" id="updates-to-these-terms">
        <div className="title">
          <h2>Updates to these Terms</h2>
        </div>
        <div className="content">
          <p>
            This sowerent Technology LLP or Rentiseasy Terms of Service may be
            changed or updated occasionally to meet legal, technological or
            operational requirements and standards. You are encouraged to
            frequently visit this policy to stay updated about changes. In case
            of critical changes, these will be circulated to you prior to their
            implementation. Modifications will be effective on the day they are
            published.
          </p>
        </div>
      </div>
      <div className="policy-item" id="how-to-contact-us">
        <div className="title">
          <h2>How to contact us ?</h2>
        </div>
        <div className="content">
          <p>
            If you have any questions about these Terms of Service, you may
            contact us support@sowerent.com. We will attempt to resolve any
            complaints regarding these Terms.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
