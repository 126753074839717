import React from "react";

const Card = ({ cardItem }) => {
  return (
    <div className="custom-card">
      <div className="icon">
        <img src={cardItem.iconPath} alt={cardItem.title} />
      </div>
      <div className="card-content">
        <h3>{cardItem.title}</h3>
        <p>{cardItem.description}</p>
      </div>
    </div>
  );
};

export default Card;
