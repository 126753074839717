import React, { useState, useEffect } from "react";
import bedIcon from "../../assets/images/icons/bed-icon.svg";
import bathIcon from "../../assets/images/icons/bath-icon.svg";
import BuildingIcon from "../../assets/images/icons/building-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faInr,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import dummyImg from "../../assets/images/dummy-image-landscape.jpg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../context/UserContext";

const PropertyCard = ({
  property,
  showMetaDetails = true,
  hideWishList = true,
  flatId,
}) => {
  const itemId = property.id;
  const { user, addToWishlist, removeFromWishlist } = useUser();
  const [isWishlisted, setIsWishlisted] = useState(false);

  const formatRent = (rent) => {
    return rent
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      .replace(/^(\d+)(?=(\d{2})+(?!\d))/, "$&,");
  };

  useEffect(() => {
    if (property && property.id) {
      setIsWishlisted(property.isWishlisted || false);
      // setId(property.wishlistId || null);
    }
  }, [property]);

  useEffect(() => {
    // Check if item is already in the wishlist
    setIsWishlisted(user?.WishList?.some((item) => item.flatId === flatId));
  }, [user.WishList, itemId]);

  const handleWishlist = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!user.isAuthenticated) {
      toast.error("Please log in to add items to your wishlist.");
      return;
    }

    if (isWishlisted) {
      const wishListItem = user.WishList.find((item) => item.flatId === flatId);
      if (wishListItem) {
        await removeFromWishlist(wishListItem.id);
      }
    } else {
      await addToWishlist(itemId);
    }
    setIsWishlisted(!isWishlisted); // Toggle wishlist state locally if authenticated
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    if (date < today) {
      return "Immediately";
    }
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };
  return (
    <div className="product-custom">
      {/*{/* <ToastContainer /> */}
      <div className="profile-widget">
        <div className="doc-img">
          <div className="property-img prop-img-slider">
            <div className="avail-from">
              <span className="list">Available From : </span>
              <span className="date">{formatDate(property.availableFrom)}</span>
            </div>
            {property.gallery && property.gallery.length > 0 ? (
              <Swiper
                slidesPerView={1}
                modules={[Pagination]}
                pagination={{ clickable: true }}
              >
                {property.gallery.map((propertyImage, index) => (
                  <SwiperSlide key={index}>
                    <Link
                      to={`/property/${property.flatUrl}-${flatId}`}
                      target="_blank"
                    >
                      <img
                        className="img-fluid"
                        alt=""
                        src={propertyImage.fileLink}
                      />
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Link
                to={`/property/${property.flatUrl}-${flatId}`}
                target="_blank"
              >
                <img src={dummyImg} alt="" />
              </Link>
            )}
          </div>
        </div>

        <Link
          to={`/property/${property.flatUrl}-${flatId}`}
          className="pro-content"
          target="_blank"
        >
          <div>
            <div className="title-wrap">
              <h3 className="title">{property.flatName}</h3>
              {hideWishList && (
                <div className="wishlist" onClick={handleWishlist}>
                  <span className="cursor-pointer">
                    <FontAwesomeIcon
                      icon={faHeart}
                      style={{
                        color:
                          isWishlisted && user.isAuthenticated ? "red" : "gray",
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
            <div className="price-location-wrap">
              <p>
                <span>
                  <FontAwesomeIcon icon={faLocationDot} />
                </span>
                {property.propertyLocation}, {property.city}
              </p>
              <div className="property-price">
                <FontAwesomeIcon icon={faInr} />
                {formatRent(property.flatRent)}
              </div>
            </div>
          </div>

          {showMetaDetails && (
            <>
              <ul className="d-flex details">
                <li>
                  <img src={bedIcon} alt="bed" />
                  {property.noOfBedRooms} Beds
                </li>
                <li>
                  <img src={bathIcon} alt="bath" />
                  {property.noOfBathRooms} Baths
                </li>
                <li>
                  <img src={BuildingIcon} alt="building" />
                  {property.flatSize} Sqft
                </li>
              </ul>
              {/* <ul className="property-category d-flex justify-content-between">
                <li className="view-more">
                  View Details <i className="feather-arrow-right" />
                </li>
              </ul> */}
              <div className="preffered-tanets">
                <ul>
                  {property?.preferredTenants?.split(",").map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </Link>
      </div>
    </div>
  );
};

export default PropertyCard;
