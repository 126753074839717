import apiClient from "./axios.config";
import Cookies from "js-cookie";

const billsService = {
  // Existing methods to get bills and receipts
  getBillsPayment() {
    return apiClient.get("/customer/order/bills");
  },
  getReceipts() {
    return apiClient.get("/customer/order/receipts");
  },

  // Method to get payment request details for rent
  getPaymentRequestDetails(orderId) {
    return apiClient.post(
      `/customer/order/rent-payment-request?orderGuid=${orderId}`
    );
  },
  
  // New method to get payment request details for utilities
  getUtilityPaymentRequestDetails(orderId) {
    return apiClient.post(
      `/customer/order/utility-payment-request?orderGuid=${orderId}`
    );
  },

  // New method to get payment request details for maintenance
  getMaintenancePaymentRequestDetails(orderId) {
    return apiClient.post(
      `/customer/order/maintenance-payment-request?orderGuid=${orderId}`
    );
  },

  // New method to get payment request details for other items
  getOtherPaymentRequestDetails(orderId) {
    return apiClient.post(
      `/customer/order/other-payment-request?orderGuid=${orderId}`
    );
  },
  getPendingPaymentRequest(orderGuid) {
    return apiClient.get(`/customer/order/pending-payment-request?orderGuid=${orderGuid}`);
  },

  // Method to update payment status for rent
  getPaymentSuccessDetails(payload) {
    const accessToken = Cookies.get("accessToken");
    return apiClient.put("/customer/order/rent-payment-status", payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
  },
  
  // New method to update payment status for utilities
  getUtilityPaymentSuccessDetails(payload) {
    const accessToken = Cookies.get("accessToken");
    return apiClient.put("/customer/order/utility-payment-status", payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
  },

  // New method to update payment status for maintenance
  getMaintenancePaymentSuccessDetails(payload) {
    const accessToken = Cookies.get("accessToken");
    return apiClient.put("/customer/order/maintenance-payment-status", payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
  },

  // New method to update payment status for other items
  getOtherPaymentSuccessDetails(payload) {
    const accessToken = Cookies.get("accessToken");
    return apiClient.put("/customer/order/other-payment-status", payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
  },

  updatePendingPaymentStatus(payload) {
    const accessToken = Cookies.get("accessToken");
    return apiClient.put("/customer/order/pending-payment-status", payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
  },
  
};

export default billsService;
