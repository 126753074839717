// export default Pagination;
import React, { useState } from "react";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Pagination = ({ pageS, cPage, pCount, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(cPage);

  // Calculate total number of pages
  const noOfPagesCreated = Math.floor(parseFloat(pCount) / parseInt(pageS));
  const isExtra = parseFloat(pCount) % parseInt(pageS) === 0 ? 0 : 1;
  const totalPages = noOfPagesCreated + isExtra;

  // Pagination logic
  const np =
    parseInt(currentPage) % 5 === 0
      ? parseInt(currentPage) / parseInt(5) - 1
      : parseInt(currentPage) / parseInt(5);
  const nearestNextP = (Math.floor(np) + 1) * 5;
  const pLength =
    totalPages < parseInt(nearestNextP) ? totalPages : parseInt(nearestNextP);
  const startPage = parseInt(nearestNextP) - 4;

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);

  };

  const paginatedItems = [];

  if (parseInt(currentPage) > 5) {
    paginatedItems.push(
      <li className="page-item">
        <span className="page-link pVClick" onClick={() => handlePageClick(1)}>
          1
        </span>
      </li>
    );
    paginatedItems.push(
      <li className="page-item">
        <span className="page-link pVClick" onClick={() => handlePageClick(1)}>
          ...
        </span>
      </li>
    );
  }

  for (let i = startPage; i <= pLength; i++) {
    const isActive = i === parseInt(currentPage) ? "active" : "";
    paginatedItems.push(
      <li key={i} className={`page-item ${isActive}`}>
        <span
          className={`page-link pVClick ${isActive}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </span>
      </li>
    );
  }

  if (totalPages > pLength) {
    paginatedItems.push(
      <li key="dots-next" className="page-item">
        <span
          className="page-link pVClick"
          onClick={() => handlePageClick(pLength + 1)}
        >
          ...
        </span>
      </li>
    );
    paginatedItems.push(
      <li key="last" className="page-item">
        <span
          className="page-link pVClick"
          onClick={() => handlePageClick(totalPages)}
        >
          {totalPages}
        </span>
      </li>
    );
  }

  // Handle Previous and Next buttons
  const prvPg = startPage === 1 ? 1 : startPage - 1;
  const nxtPg = totalPages > pLength ? pLength + 1 : pLength;

  const renderPrevButton = (
    <li
      className={`page-item prev ${
        parseInt(currentPage) > 1 ? "" : "disabled"
      }`}
    >
      <span
        className="page-link prVClick"
        onClick={() => handlePageClick(prvPg)}
        aria-label="Previous"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        Prev
      </span>
    </li>
  );

  const renderNextButton = (
    <li
      className={`page-item next ${
        nxtPg !== parseInt(currentPage) ? "" : "disabled"
      }`}
    >
      <span
        className="page-link nxVClick"
        onClick={() => handlePageClick(nxtPg)}
        aria-label="Next"
      >
        Next <FontAwesomeIcon icon={faArrowRight} />
      </span>
    </li>
  );

  return (
    <ul className="pagination justify-content-center">
      {renderPrevButton}
      {paginatedItems}
      {renderNextButton}
    </ul>
  );
};

export default Pagination;
