import React from "react";
import InstantMoveIcon from "../../assets/images/icons/instant-movin.png";
import SecurityDespositeIcon from "../../assets/images/icons/security-deposite.png";
import NoBrokerageIcon from "../../assets/images/icons/no-brokerage.png";
import MaintainanceIcon from "../../assets/images/icons/maintainance.png";
import Card from "../common/card";
import ownBuilding from "../../assets/images/own-building.jpg";
import { Link } from "react-router-dom";

export const HowItWork = ({
  className = "",
  isBuildingSectionRequired = true,
  isCardSectionRequired = true,
}) => {
  const HowItWorkBenefits = [
    {
      iconPath: InstantMoveIcon,
      title: "Instant Move-In",
      description:
        "Say goodbye to lengthy waits. Find, finalize, and move into your dream home without any delay.",
    },
    {
      iconPath: SecurityDespositeIcon,
      title: "Lowest Security Deposit",
      description:
        "Ease rental stress by paying a minimal security deposit. Understanding financial challenges.",
    },
    {
      iconPath: NoBrokerageIcon,
      title: "No Brokerage",
      description:
        "Enjoy no-brokerage renting with shared spaces, curated events, and a vibrant community lifestyle.",
    },
    {
      iconPath: MaintainanceIcon,
      title: "Prompt Maintenance",
      description:
        "We conduct regular property inspections and provide on-demand services to keep your space.",
    },
  ];
  return (
    <>
      {isCardSectionRequired && (
        <section className={`howit-work ${className}`}>
          <div className="container">
            <div className="benefits__row">
              {HowItWorkBenefits.map((cardItem, index) => (
                <div
                  className="benefits__col"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  key={`benefit-card-${index}`}
                >
                  <Card cardItem={cardItem} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      {isBuildingSectionRequired && (
        <section className={`own-building ${className}`}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6">
                <div className="section-heading align-items-start mb-0">
                  <h2>Own a Building ?</h2>
                  <div className="sec-line">
                    <span className="sec-line1" />
                    <span className="sec-line2" />
                  </div>
                  <h3>Let us Manage it</h3>
                  <p>
                    List your property in a few easy steps and enjoy hassle-free
                    management services, including on-time rent and proactive
                    maintenance.
                  </p>
                  <Link to="/contact-us" className="btn btn-primary">
                    Exlpore our Property Management
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="image">
                  <img src={ownBuilding} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
