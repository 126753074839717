import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const CancellationPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Cancellation Policy | Sowerent</title>
        <meta
          name="description"
          content="Read our cancellation policy to learn about the conditions under which you can cancel your booking and any associated fees."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <section className="privacy-policy-section cancellation-policy">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <div className="section-heading">
                <h1>Cancellation Policy</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={10} className="mx-auto">
              <div className="privacy-policy-wrap">
                <div className="policy-item">
                  <div className="title">
                    <h2>Cancellation and Refund Policy</h2>
                    <p className="mb-2">
                      We understand that plans can change, and we are here to
                      accommodate your needs to the best of our ability. Below
                      is our cancellation and refund policy for your reference:
                    </p>
                  </div>
                  <div className="content">
                    <h3>1. Cancellation Within 24 Hours of Booking:</h3>
                    <p>
                      If you cancel your booking within 24 hours, provided you
                      are not already occupied and your move in date is not
                      within the next 96 hours, we will refund your advance and
                      deposit after deducting a one-time administrative charge
                      of Rs. 5,000/-.
                    </p>

                    <h3>
                      2. Cancellation After 24 Hours of Booking and Before 96
                      Hours of Check-In
                    </h3>
                    <p>
                      If you cancel after 24 hours and your move in date is more
                      than 96 hours, 50% of your advance/deposit will be
                      refunded.
                    </p>
                    <h3>3. Cancellation Less Than 96 Hours Before Check-In</h3>
                    <p>
                      For cancellations made within 96 hours of your scheduled
                      check-in date, no refund will be provided.
                    </p>
                    {/* <h3>Security deposit</h3>
                    <p>
                      Amounting to as predefined and agreed upon during
                      commercial negotiations, will be payable to the Operator
                      by the Client before the aforementioned lease beginning
                      date. The deposit will be refunded to the Client within 15
                      (fifteen) days of the stated lease ending date, subject to
                      the premises, fixtures and fittings and furniture being
                      left in a good working order, allowing for reasonable wear
                      and tear, commensurate with the condition of the same when
                      the premises were handed over to the Client and pending
                      the issuance of all outstanding utility bills for the
                      rental period. The Security deposit may not be used for
                      rental payments. The Security deposit shall be returned to
                      the personal bank account of the Client, provided to the
                      Operator. In the event that any bank fees are imposed upon
                      the transfer of the deposit, the cost will be borne by the
                      Client.
                    </p> */}
                  </div>
                </div>
                <div className="policy-item">
                  <div className="title">
                    <h2>Early termination and Renewal or Extension</h2>
                  </div>
                  <div className="content">
                    <h3>Early Lease Termination</h3>
                    <p>
                      In the event that the Client wishes to terminate the
                      contract earlier than the planned duration, then he/she
                      needs to notify the Operator, by written means, at least
                      30 days in advance. Furthermore, the following terms will
                      be applicable:
                    </p>
                    <p>
                      Contracts concerning a lease period of 1 (one) month are
                      paid for in advance and hence, cannot be subject to
                      cancellation or refund.
                    </p>
                    <p>
                      For contracts with a duration of over 1 (one) month and
                      following the 30 day notice period rule, termination will
                      be granted, however the rental rate for the entire
                      contract duration (up to the requested termination date)
                      will be adjusted to the prevailing shorter duration rental
                      rate at time of booking, in case the Client spends less
                      than 9 (nine) months in total in the apartment. The rates
                      for each duration of stay to be charged on a pro-rata
                      basis and will be adjusted in accordance with the sowerent
                      Technology LLPrefund policy and the actual duration of
                      stay at the said premises.
                    </p>
                    <p>
                      In the event of a contract with duration of 11 months or
                      more, the client should notify one month in advance of
                      there intention to extend or vacate the premises
                    </p>
                    <h3>Contract Renewal/Extension</h3>
                    <p>
                      In the event that the Client wishes to renew and/or extend
                      the contract, he/she needs to notify the Operator, in
                      writing, at least thirty (30) days before the noted
                      contract expiration date. The request will be granted
                      subject to availability and the lease rate will be
                      redefined accordingly. The extension shall be considered
                      valid only if the Client has processed the payment of the
                      extension amount. The contract is valid up to the end of
                      the specified period after which it is considered null and
                      void.
                    </p>

                    <h3>Promotional or Discounted Rates</h3>
                    <p>
                      Promotional rates on the Blueground website are not
                      applicable on existing contracts but only applicable for
                      new bookings.
                    </p>
                    <h3>Third Party Payment Gateway</h3>
                    <p>
                      We use third-party payment gateway, system/s partners. We
                      do not retain any payment card information. Unless
                      otherwise stated, all payments or fees charged by
                      third-party service providers shall be borne by the client
                      themselves
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CancellationPolicy;
