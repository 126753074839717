import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { validateFormPropertyOwners } from "../utils/validation/validate-property-owners";
import { HowItWork } from "../components/home/how-it-work";
import { Helmet } from "react-helmet-async";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ListPropertyServices from "../../src/services/list-property-service";
import { handleErrors } from "../utils/errorHandler";
import { toast } from "react-toastify";

const ListYourProperty = () => {
  // State to hold form values
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    propertyType: "",
    contactNumber: "",
    whatsappNumber: "",
    propertyAddress: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // For loading state
  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState("");

  // Handle input changes
  const handleInputChange = (e, name) => {
    if (typeof e === "string" || e === null || e === undefined) {
      // Handle PhoneInput case where value may be undefined
      setFormData({
        ...formData,
        [name]: e || "", // Default to an empty string if value is undefined
      });
    } else {
      // Handle normal input case
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    const formErrors = validateFormPropertyOwners(formData); // Call validation function
    setErrors(formErrors);

    // If no errors, proceed with form submission
    if (Object.keys(formErrors).length === 0) {
      try {
        setIsSubmitting(true); // Show loading state
        setSubmissionStatus(""); // Clear previous message

        // Make the API call
        await ListPropertyServices.postPropertylisting({
          firstName: formData.firstName,
          lastName: formData.lastName,
          emailAddress: formData.email,
          propertyType: formData.propertyType,
          contactNo: formData.contactNumber,
          whatsAppNo: formData.whatsappNumber,
          propertyAddress: formData.propertyAddress,
        });

        // Show success message
        setSubmissionStatus(
          "Thank you for contacting us! We’ll be in touch soon."
        );
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response && error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage);
      } finally {
        setIsSubmitting(false); // Reset loading state
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>List Your Property | Sowerent</title>
        <meta
          name="description"
          content="List your property on sowerent Technology LLP for tenants to discover and rent easily. Fill out the details and submit your property listing."
        />
      </Helmet>
      <HowItWork isCardSectionRequired={false} className="pt-80" />
      <section className="grediant-section-bg">
        <Container>
          {submissionStatus ? ( // Check if submissionStatus has a message
            <div className="text-center">
              <h1 className="text-success">{submissionStatus}</h1>
            </div>
          ) : (
            <Row>
              <Col lg={6} md={8} className="mx-auto">
                <div className="rent-prop-title text-center">
                  <h1>For Property Owners</h1>
                  <p>Rent your property for free.</p>
                </div>
                <Form>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          id="first-name"
                          type="text"
                          name="firstName"
                          className={`form-control ${
                            errors.firstName ? "is-invalid" : ""
                          }`}
                          placeholder="Enter your first name"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          // isInvalid={!!errors.firstName} // Show error styling
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          id="last-name"
                          className={`form-control ${
                            errors.lastName ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="lastName"
                          placeholder="Enter your last name"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          // isInvalid={!!errors.lastName} // Show error styling
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email Id</Form.Label>
                        <Form.Control
                          id="email"
                          className={`form-control ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          value={formData.email}
                          onChange={handleInputChange}
                          // isInvalid={!!errors.email} // Show error styling
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Property Type</Form.Label>
                        <Form.Select
                          className={`form-control ${
                            errors.propertyType ? "is-invalid" : ""
                          }`}
                          name="propertyType"
                          value={formData.propertyType}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Property Type</option>
                          <option value="Studio Apartments">
                            Studio Apartments
                          </option>
                          <option value="1 BHK">1 BHK</option>
                          <option value="2 BHK">2 BHK</option>
                          <option value="3 BHK">3 BHK</option>
                          <option value="Bungalow">Bungalow</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.propertyType}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Contact Number</Form.Label>
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          id="contact-number"
                          maxLength="15"
                          name="contactNumber"
                          className={`form-control ${
                            errors.contactNumber ? "is-invalid" : ""
                          }`}
                          placeholder="Enter your phone number"
                          value={formData.contactNumber || ""} // Ensure value is never undefined
                          onChange={(value) =>
                            handleInputChange(value, "contactNumber")
                          } // Pass value and field name
                          // isInvalid={!!errors.contactNumber} // Show error styling
                        />

                        <div className="text-danger">
                          {" "}
                          {errors.contactNumber}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>WhatsApp Number</Form.Label>
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          id="whatsapp-number"
                          maxLength="15"
                          name="whatsappNumber"
                          className={`form-control ${
                            errors.whatsappNumber ? "is-invalid" : ""
                          }`}
                          placeholder="Enter your WhatsApp number"
                          value={formData.whatsappNumber || ""}
                          onChange={(value) =>
                            handleInputChange(value, "whatsappNumber")
                          } // Pass value and field name
                          // isInvalid={!!errors.whatsappNumber} // Show error styling
                        />
                        <div className="text-danger">
                          {errors.whatsappNumber}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="form-group">
                        <Form.Label>Property Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="propertyAddress"
                          className={`form-control ${
                            errors.propertyAddress ? "is-invalid" : ""
                          }`}
                          placeholder="Enter your property address (10-255 characters)"
                          value={formData.propertyAddress}
                          onChange={handleInputChange}
                          // isInvalid={!!errors.propertyAddress} // Show error styling
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.propertyAddress}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="text-center w-100">
                    <Button
                      type="button"
                      className="btn btn-theme-blue"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

export default ListYourProperty;
