import { isValidPhoneNumber } from "react-phone-number-input";

// utils/validation.js
export const validateUserProfile = (userProfile) => {
  const errors = {};
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // const phonePattern = /^\+?[1-9]\d{1,14}$/; // Adjust this regex based on your phone number format

  if (!userProfile.fullName) {
      errors.fullName = "Full Name is required.";
  }

  if (!userProfile.emailAddress || !emailPattern.test(userProfile.emailAddress)) {
      errors.emailAddress = "Valid Email Address is required.";
  }

//   if (!userProfile.phoneNo || !phonePattern.test(userProfile.phoneNo)) {
//       errors.phoneNo = "Valid Phone Number is required.";
//   }
  // if (!userProfile.phoneNo || userProfile.phoneNo.trim() === "") {
  //   errors.phoneNo = "Valid Phone Number is required.";
  // } else {
  //   const phoneNo = userProfile.phoneNo.replace(/\D/g, '');
  //   if (phoneNo.length !== 12) {
  //     errors.phoneNo = "Phone number must be 10 digits.";
  //   }
  // }
  if (!userProfile.phoneNo) {
    errors.phoneNo = "Phone  number is required.";
  } else if (!isValidPhoneNumber(userProfile.phoneNo)) {
    errors.phoneNo = "Please enter a valid phone number.";
  }


  return errors; // Returns an object with errors, or an empty object if no errors
};
