import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const PublicRoute = ({ element }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  useEffect(() => {
    const fetchAuthToken = async () => {
      const accessToken = Cookies.get("accessToken");
      if(accessToken){
        setIsAuthenticated(true);
        setIsLoading(false); 
      } else{
        setIsAuthenticated(false);
        setIsLoading(false); 
      }
    };
    fetchAuthToken();
    // setIsAuthenticated(!!accessToken); // Set to true if accessToken exists
  }, []); // Run only once when the component mounts
  if (isLoading) {
    return <div>Loading...</div>; // Render loading state
  }
  if (isAuthenticated) {
    element = <Navigate to="/my-account/profile" />;
    return element;
  }
  return element;
};

export default PublicRoute;