import React, { useState } from "react";
import clientReviews from "../../assets/data/people-say.json";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Testimonials = () => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = (index) => {
    setIsReadMore((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  return (
    <section className="testimonial-sec">
      <div className="container">
        <div className="section-heading">
          <h2>Testimonials</h2>
          <div className="sec-line">
            <span className="sec-line1" />
            <span className="sec-line2" />
          </div>
          <p>What our happy client says</p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Swiper
              className="testimonial-slider"
              slidesPerView={1}
              spaceBetween={24}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                576: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 16,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
              }}
            >
              {clientReviews.peopleSay.map((clientReview, index) => {
                const textWords = clientReview.review.split(" ");
                const isExpanded = isReadMore[index];
                const displayedText = isExpanded
                  ? clientReview.review
                  : textWords.slice(0, 30).join(" ");
                return (
                  <SwiperSlide key={`client-review-${index}`}>
                    <div
                      className="testimonial-card aos"
                      data-aos="fade-down"
                      data-aos-duration={2000}
                    >
                      <p>
                        {displayedText}
                        {textWords.length > 30 && (
                          <span>
                            {isExpanded ? "" : "..."}
                            <button
                              className="read-more"
                              onClick={() => toggleReadMore(index)}
                            >
                              {isExpanded ? "Read Less" : "Read More"}
                            </button>
                          </span>
                        )}
                      </p>
                      <div className="content-wrap">
                        <h4>
                          <Link to="">{clientReview.name}</Link>
                        </h4>
                        <div className="rating">
                          {Array.from({ length: clientReview.rating }).map(
                            (_, keyindex) => (
                              <span key={keyindex}>
                                <FontAwesomeIcon icon={faStar} />
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
