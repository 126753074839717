import { isValidPhoneNumber } from "react-phone-number-input";

export const validateForm = (formData) => {
  const errors = {};

  // Name validation
  if (!formData.name.trim()) {
    errors.name = "Name is required";
  }

  // Phone number validation (check if it's empty and contains only digits)
  // if (!formData.phone.trim()) {
  //   errors.phone = "Phone number is required";
  // } else if (!/^\+?\d{12}$/.test(formData.phone)) { // Validate 10 digits (with optional country code)
  //   errors.phone = "Phone number must be exactly 10 digits";
  // }
  if (!formData.phone) {
    errors.phone = "Phone  number is required.";
  } else if (!isValidPhoneNumber(formData.phone)) {
    errors.phone = "Please enter a valid phone number.";
  }


  // Email validation
  if (!formData.email.trim()) {
    errors.email = "Email is required";
  } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
    errors.email = "Enter a valid email address";
  }

  // Country validation
  if (formData.country === "0") {
    errors.country = "Please select a country";
  }

  // Subject validation
  if (!formData.subject.trim()) {
    errors.subject = "Subject is required";
  }

  // Description validation
  if (!formData.description.trim()) {
    errors.description = "Description is required";
  }

  return errors; // Return all errors
};
