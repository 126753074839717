import apiClient from "./axios.config";

const FeedbackService = {
 
  postFeedback(supportGuid, rating, comments) {
    return apiClient.post("/customer/feedback/add", {
      supportGuid,
      rating, // Add the selected rating here
      comments // Add comments here
    });
  },
  getFeedbackByCustomer() {
    return apiClient.get("/customer/feedback/feedbacks-by-customer");
  }
};

export default FeedbackService;
