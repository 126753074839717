import React from "react";
import counterIcon1 from "../../assets/images/icons/counter-icon-1.svg";
import counterIcon2 from "../../assets/images/icons/counter-icon-2.svg";
import counterIcon3 from "../../assets/images/icons/counter-icon-3.svg";
import counterIcon4 from "../../assets/images/icons/counter-icon-4.svg";

const HomeCounter = () => {
  return (
    <section className="counter-sec pt-80">
      <div className="container">
        <div className="row align-items-center">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-3 col-sm-3 col-6 d-flex">
              <div
                className="counter-box flex-fill"
                data-aos="fade-down"
                data-aos-duration={2000}
              >
                <div className="counter-icon">
                  <img src={counterIcon1} alt="" />
                </div>
                <div className="counter-value">
                  <h3 className="dash-count">
                    <span className="counter-up">1000</span>+
                  </h3>
                  <h5>Listings include</h5>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-3 col-sm-3 col-6 d-flex">
              <div
                className="counter-box flex-fill"
                data-aos="fade-down"
                data-aos-duration={2000}
              >
                <div className="counter-icon">
                  <img src={counterIcon2} alt="" />
                </div>
                <div className="counter-value">
                  <h3 className="dash-count">
                    <span className="counter-up">3000</span>+
                  </h3>
                  <h5>Agents Listed</h5>
                </div>
              </div>
            </div> */}
            <div className="col-lg-3 col-md-3 col-sm-3 col-6 d-flex">
              <div
                className="counter-box flex-fill"
                data-aos="fade-down"
                data-aos-duration={2000}
              >
                <div className="counter-icon">
                  <img src={counterIcon3} alt="" />
                </div>
                <div className="counter-value">
                  <h3 className="dash-count">
                    <span className="counter-up">2000</span>+
                  </h3>
                  <h5>Sales Completed</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-6 d-flex">
              <div
                className="counter-box flex-fill"
                data-aos="fade-down"
                data-aos-duration={2000}
              >
                <div className="counter-icon">
                  <img src={counterIcon4} alt="" />
                </div>
                <div className="counter-value">
                  <h3 className="dash-count">
                    <span className="counter-up">1500</span>+
                  </h3>
                  <h5>Users</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCounter;
