import apiClient from "./axios.config";
import Cookies from "js-cookie";

const bookingService = {
  getBookingToken(payload) {
    return apiClient.post("/customer/order/token", payload);
  },
  getBookingBasicDetails(orderId ) {
    return apiClient.get(`/customer/order/basic?orderGuid=${orderId}`);
  },
  getPaymentRequestDetails(orderId) {
    return apiClient.post(
      `/customer/order/token/payment-request?orderGuid=${orderId}`
    );
  },
  getPaymentSuccessDetails(payload) {
    const accessToken = Cookies.get("accessToken");
    return apiClient.put("/customer/order/token/payment-status", payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
        accept: "*/*",
      },
    });
  },
};

export default bookingService;
