import React, { useCallback, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  faArrowUpShortWide,
  faBorderAll,
  faFilter,
  faList,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import PropertyCard from "../components/common/property-card";
import propertyService from "../services/propertyService";
import Pagination from "../components/common/Pagination";
import CustomDropdown from "../components/common/CustomDropdown";
import PopertyLocationSearch from "../components/common/property-location-search";
import { handleErrors } from "../utils/errorHandler";
import { ErrorHandlingDesign } from "../components/common/errorHandlingDesign";
import { ProgressSpinner } from "primereact/progressspinner";
import { Helmet } from "react-helmet-async";

const PropertyListingNavigate = () => {
  // window.location.reload();
  const routeParams = useParams();
  const { state } = useLocation();
  const { location } = routeParams;
  const formattedLocation = location.replace(/-/g, " ");
  const queryParamsObj = { location: formattedLocation };
  const [selectOptions, setSelectedOptions] = useState([]);
  const [cPage, setCPage] = useState(1);
  const [pageS, setPageS] = useState(16);
  const [isGridView, setIsGridView] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState();
  const [locationError, setLocationError] = useState(null);
  const [allLocations, setAllLocations] = useState();
  const [shouldUpdateLocations, setShouldUpdateLocations] = useState(true);
  const moreFilters = [
    {
      filterType: "Tags",
      filterOptions: ["New Arrivals", "Upcoming Property"],
    },
    {
      filterType: "Available For",
      filterOptions: ["Couple", "Bachelors", "Family"],
    },
    {
      filterType: "Furnishing Type",
      filterOptions: ["Fully Furnished", "Semi Furnished", "Unfurnished"],
    },
    {
      filterType: "Managed By",
      filterOptions: ["SoWeRent", "Owner"],
    },
    {
      filterType: "Availability",
      filterOptions: ["Immediate", "Within 10 Days"],
    },

    {
      filterType: "Carpet Area",
      filterOptions: ["Below 500", "500 - 1000", "1000 - 1500", "1500 - 1200"],
    },
  ];
  const [propertyTypeItems, setPropertyTypeItems] = useState([]);
  const [propertyType, setPropertyType] = useState(queryParamsObj.bhkType);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [formValues, setFormValues] = useState({
    location: queryParamsObj.location,
  });

  const budgetList = ["Premium 30k+", "Affordable 20k+", "Thrifty <20k"];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBhkTypes = async () => {
      try {
        const response = await propertyService.getBhkTypes();

        if (response.data.isSuccess) {
          setPropertyTypeItems(
            response.data.result.map((item) => item.bhkType)
          );
        } else {
          setError("BHK Types not found");
        }
      } catch (error) {
        setError("BHK Types not found");
      } finally {
        setLoading(false);
      }
    };

    fetchBhkTypes();
  }, []);

  const mapCarpetAreaToRange = (carpetArea) => {
    switch (carpetArea) {
      case "Below 500":
        return [0, 500];
      case "500 - 1000":
        return [500, 1000];
      case "1000 - 1500":
        return [1000, 1500];
      case "1500 - 2000":
        return [1500, 2000];
      default:
        return [0, 5000]; // Default if no selection
    }
  };
  const mapBudgetToRange = (budgetType) => {
    switch (budgetType) {
      case "Premium 30k+":
        return [30000, 100000];
      case "Affordable 20k+":
        return [20000, 100000];
      case "Thrifty <20k":
        return [0, 20000];
      default:
        return [0, 1000000];
    }
  };

  const fetchProperties = useCallback(
    async (cPage = 1) => {
      // Update state based on query params
      if (location?.search !== "") {
        setPropertyType(queryParamsObj.bhkType);
        setLocations([queryParamsObj.location]);

        setSelectedOptions((prevSelectedOptions) => {
          const { location, ...updatedFilter } = queryParamsObj;

          return {
            ...updatedFilter,
          };
        });

        setFormValues({ ...formValues, location: queryParamsObj.location });
      }
      const payload = {
        pageSize: pageS,
        pageNo: cPage,
        locations: [formValues.location],
        bhkType: propertyType,
      };
      setIsPropertyLoading(true);

      try {
        const response = await propertyService.getPropertyListing(payload);
        setPropertyList(response.data.result || []);
      } catch (error) {
        const errorMessage = handleErrors(error);
        setError(errorMessage);
      } finally {
        setIsPropertyLoading(false);
      }
    },
    [propertyType, selectedLocation]
  );
  const toCamelCase = (str) => {
    return str
      .replace(/\s(.)/g, (match, group1) => group1.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match, group1) => group1.toLowerCase());
  };

  const fetchAllLocations = async () => {
    try {
      const response = await propertyService.getPropertyLocations();
      const allLocations = response.data?.result || [];
      if (Array.isArray(allLocations)) {
        setLocations(allLocations.map((item) => item.locationName));
        setAllLocations(allLocations.map((item) => item.locationName));
      } else {
        setLocations([]);
      }
    } catch (error) {
      // console.error("Error fetching property locations:", error);
      setLocations([]);
      setLocationError("Error fetching locations.");
    }
  };
  
  useEffect(() => {
    fetchAllLocations();
  }, []);
  
  const handleFilterClick = (filterType, filterItem) => {
    let searchQuery = {
      location: location,
    };
    if (filterType === "Budget") {
      const [budgetFromVal, budgetToVal] = mapBudgetToRange(filterItem);
      searchQuery = {
        ...searchQuery,
        budgetFrom: budgetFromVal,
        budgetTo: budgetToVal,
      };
    } else if (filterType === "Property Type") {
      searchQuery = {
        ...searchQuery,
        bhkType: filterItem,
      };
    } else if (filterType === "Carpet Area") {
      const [carpetAreaFromVal, carpetAreaToVal] =
        mapCarpetAreaToRange(filterItem);
      searchQuery = {
        ...searchQuery,
        carpetAreaFrom: carpetAreaFromVal,
        carpetAreaTo: carpetAreaToVal,
      };
    } else if (filterType === "Sort By") {
      const sortByText = filterItem === "Price Low to High" ? "L2H" : "H2L";
      searchQuery = {
        ...searchQuery,
        sortBy: sortByText,
      };
    } else if (filterType === "Locations") {
      searchQuery = {
        ...searchQuery,
        location: filterItem,
      };
    } else {
      const paramKey = toCamelCase(filterType);
      searchQuery = {
        ...searchQuery,
        [paramKey]: filterItem,
      };
    }
    const queryParams = new URLSearchParams(searchQuery).toString();
    // console.log('query params being passed', queryParams);
    const params = new URLSearchParams(queryParams);
    const locationValue = params.get('location');
    // console.log('Location value:', locationValue);
    if (locationValue) {
      navigate(`/properties?${queryParams}`);
      return;
    } else {
      // console.log('entered to set the values of all the locations');
      setFormValues((prevValues) => ({
        ...prevValues,
        location: '',
      }));
      // console.log('form valueeeeeeeeeeeeeeeeeee',formValues.location);
      setLocations([...allLocations]);
      return;
    }
    
  };
  // console.log('jnejndejdnform value',formValues.location);
   useEffect(() => {
    if(formValues.location){
      console.log('location',locations);
    } else{
      fetchAllLocations();
      // console.log('locationnnnnnnnnpppppppppppp',locations);
      return;

    }
  }, [formValues.location]);

  useEffect(() => {
    fetchProperties(); // Update URL when any filter changes
    // updateSearchParams();
  }, [fetchProperties]);

  const handlePageChange = (newPage) => {
    setCPage(newPage);
    fetchProperties(newPage);
  };

  // const handlePageSizeChange = (event) => {
  //   setPageS(parseInt(event.target.value));
  //   setCPage(1);
  // };

  const fetchLocationSuggestions = async (value) => {
    if (value.trim() === "") {
      setLocations([]);
      return;
    }
    // setIsLoading(true);
    const payload = {
      locName: value,
    };
    try {
      const response = await propertyService.getLocationSuggestions(payload);
      const locationsres = response.data.result || [];
      setLocations(locationsres.map((item) => item.locationName));
    } catch (error) {
      setLocationError("No Locations FOund");
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formValues.location) {
      fetchLocationSuggestions(formValues.location);
    }
  }, [formValues.location]);

  

  // useEffect(() => {
  //   // Reload when location or filters change
  //   window.location.reload();
  // }, [state.location]);

  const handleInputChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      location: value,
    }));
    if (value === "") {
      formValues.location = "";
      handleFilterClick("Locations", "");
      setLocations([...allLocations]);
    }
  };

  const handleSuggestionClick = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      location: value,
    }));
    handleFilterClick("Locations", value);
  };
  const clearAllFilter = () => {
    const searchQuery = {
      location: location,
    };
    const queryParams = new URLSearchParams(searchQuery).toString();
    navigate(`/properties?${queryParams}`);
    return;
  };

  const handleSortSelect = (sortingType) => {
    handleFilterClick("Sort By", sortingType);
  };

  return (
    <>
      <Helmet>
        <title>
          {selectedLocation.length > 0 ? selectedLocation : "Bangalore"} -
          Property Details | Sowerent
        </title>
        <meta
          name="description"
          content={`Discover more about ${selectedLocation.length > 0 ? selectedLocation : "Bangalore"
            }. Learn about the features, pricing, and amenities of this property listed at Sowerent.`}
        />
      </Helmet>
      <section className="custom-breadcrumb dark property-listing-breadcrumb">
        <Container>
          <Row>
            <Col sm={12}>
              <ul className="bread-crumb-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="#">
                    {selectedLocation.length > 0
                      ? selectedLocation
                      : "Bangalore"}
                  </Link>
                </li>
                {location && (
                  <li>
                    <span>{location}</span>
                  </li>
                )}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="property-filter-section">
        <Container>
          <Row>
            <Col>
              <div className="property-filter-card">
                <div className="filter-card">
                  <div className="search-wrap">
                    <PopertyLocationSearch
                      preSelectedValue={formValues.location}
                      onChange={handleInputChange}
                      handleSubmitAfterSuggestionClick={handleSuggestionClick}
                      locations={locationError ? locationError : locations}
                      value={formValues.location}
                    />
                  </div>
                  <div className="right-col">
                    <div className="filters-wrap">
                      <CustomDropdown
                        title="Budget"
                        items={budgetList}
                        onSelect={handleFilterClick}
                        isSearchEnabled={false}
                      />
                      <CustomDropdown
                        title="Property Type"
                        items={propertyTypeItems}
                        onSelect={handleFilterClick}
                        isSearchEnabled={false}
                      />
                    </div>
                    <div className="more-filters">
                      <button
                        className="filter-btn"
                        onClick={() => setShowModal(true)}
                      >
                        <span>More Filters</span>
                        <FontAwesomeIcon icon={faFilter} />
                      </button>
                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        className="property-filter-modal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            Discover various home aesthetics with our
                            customization choices
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="filter-body-wrap tab-show">
                            <h2>Budget</h2>
                            <div className="filter-list-wrap">
                              {budgetList.map((filterItem, i) => (
                                <div
                                  key={`filter-item-${i}`}
                                  className="filter-list-item"
                                  onClick={() =>
                                    handleFilterClick("Budget", filterItem)
                                  }
                                >
                                  {filterItem}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="filter-body-wrap tab-show">
                            <h2>Property Type</h2>
                            <div className="filter-list-wrap">
                              {propertyTypeItems.map((filterItem, i) => (
                                <div
                                  key={`filter-item-${i}`}
                                  className="filter-list-item"
                                  onClick={() =>
                                    handleFilterClick(
                                      "Property Type",
                                      filterItem
                                    )
                                  } // Add click handler
                                >
                                  {filterItem}
                                </div>
                              ))}
                            </div>
                          </div>
                          {moreFilters.map((filter, index) => (
                            <div
                              className="filter-body-wrap"
                              key={`more-filter-wrap-${index}`}
                            >
                              <h2>{filter.filterType}</h2>
                              <div className="filter-list-wrap">
                                {filter.filterOptions.map((filterItem, i) => (
                                  <div
                                    key={`filter-item-${i}`}
                                    className="filter-list-item"
                                    onClick={() =>
                                      handleFilterClick(
                                        filter.filterType,
                                        filterItem
                                      )
                                    } // Add click handler
                                  >
                                    {filterItem}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={clearAllFilter}>
                            Clear All
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div className="sorting-dropdown">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="sort-btn"
                        >
                          Sort By <FontAwesomeIcon icon={faArrowUpShortWide} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleSortSelect("Price Low to High")
                            }
                          >
                            Price: Low to High
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleSortSelect("Price High to Low")
                            }
                          >
                            Price: High to Low
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="applied-filters">
                  {/* <h5>Applied Filters</h5> */}
                  <ul className="applied-filter-list">
                    {Object.entries(selectOptions).map(([key, value]) => (
                      <li key={key}>
                        <span>{value}</span>
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={clearAllFilter}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="property-listing-section">
        <Container>
          <Row>
            <Col>
              {propertyList.length === 0 && (
                <h5 className="text-center">No Properties Found</h5>
              )}
              {propertyList.length > 0 && (
                <div className="property-list-title">
                  <h1>
                    {propertyList[0].totalCount}
                    {propertyList[0].totalCount > 1
                      ? " Apartments"
                      : " Apartment"}{" "}
                    for Rent in {queryParamsObj.location || "Bangalore"}
                  </h1>
                  <div className="grid-view-option">
                    <ul>
                      <li
                        className={isGridView ? "active" : ""}
                        onClick={() => setIsGridView(true)}
                      >
                        <FontAwesomeIcon icon={faBorderAll} />
                        Grid View
                      </li>
                      <li
                        className={!isGridView ? "active" : ""}
                        onClick={() => setIsGridView(false)}
                      >
                        <FontAwesomeIcon icon={faList} />
                        List View
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          {error ? (
            <ErrorHandlingDesign errorMessage={error} />
          ) : (
            <div
              className={`property-card__row row-gap-gutter ${!isGridView ? "property-list-view" : ""
                }`}
            >
              {isPropertyLoading ? (
                <div className="min-h-300 mx-auto">
                  <ProgressSpinner
                    style={{ width: "50px", height: "50px" }}
                    strokeWidth="3"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                </div>
              ) : (
                propertyList.map((property) => (
                  <div className="property-card__col" key={property.id}>
                    <PropertyCard
                      property={{
                        ...property,
                        gallery: property.gallery.filter(
                          (media) => media.fileType !== "Video"
                        ),
                      }}
                      flatId={property.id}
                    />
                  </div>
                ))
              )}
            </div>
          )}
          {propertyList.length > 0 && (
            <Col lg={12}>
              <div className="grid-pagination">
                <Pagination
                  onPageChange={handlePageChange}
                  pageS={pageS}
                  cPage={cPage}
                  pCount={propertyList[0]?.totalCount}
                />
              </div>
            </Col>
          )}
        </Container>
      </section>
    </>
  );
};

export default PropertyListingNavigate;
