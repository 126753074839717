import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  const privacyInfoCollect = [
    {
      category: "A. Identifiers",
      examples:
        "A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver’s license number, passport number, or other similar identifiers.",
    },
    {
      category: "B. Personal Information Categories",
      examples:
        "A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.",
    },
    {
      category: "C. Protected classification characteristics",
      examples:
        "Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).",
    },
    {
      category: "D. Commercial information",
      examples:
        "Records of personal property, solutions purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.",
    },
    {
      category: "E. Biometric information",
      examples:
        "Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.",
    },
    {
      category: "F. Internet or other similar network activity",
      examples:
        "Visitor's and User's browsing and ‘click-stream’ activity on the Services, session heatmaps and scrolls, non-identifying information regarding the Visitor’s or User’s device, operating system, internet browser, screen resolution, language and keyboard settings, internet service provider, referring/exit pages, date/time stamps, etc.",
    },
    {
      category: "G. Geo-location data",
      examples: "Physical location or movements.",
    },
    {
      category: "H. Sensory data",
      examples:
        "Audio, electronic, visual, thermal, olfactory, or similar information.",
    },
    {
      category: "I. Professional or employment-related information",
      examples: "Current or past job history or performance evaluations.",
    },
    {
      category:
        "J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99))",
      examples:
        "Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.",
    },
    {
      category: "K. Inferences drawn from other personal information",
      examples:
        "Profile reflecting a person’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.",
    },
    {
      category: "L. Equipment information",
      examples:
        "	Information about your internet connection, the equipment you use to access our Website, and usage details.",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Sowerent</title>
        <meta
          name="description"
          content="Read the privacy policy of sowerent Technology LLP regarding data collection, usage, and security."
        />
      </Helmet>
      <section className="privacy-policy-section">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <div className="section-heading">
                <h1>Privacy Policy</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={3} lg={4}>
              <div className="table-of-content fancy-scroll">
                <h2>Table of Content</h2>
                <ul>
                  <li>
                    <a href="#introduction">Introduction</a>
                  </li>
                  <li>
                    <a href="#information-we-collect">Information we collect</a>
                  </li>
                  <li>
                    <a href="#how-do-we-collect-this-information">
                      How Do We Collect This Information
                    </a>
                  </li>
                  <li>
                    <a href="#how-do-we-use-this-information">
                      How do we use this information
                    </a>
                  </li>
                  <li>
                    <a href="#how-do-we-disclose-this-personal-information">
                      How do we disclose this personal information
                    </a>
                  </li>
                  <li>
                    <a href="#what-are-your-rights-in-relation-to-your-personal">
                      What are your rights in relation to your personal
                      information
                    </a>
                  </li>
                  <li>
                    <a href="#information-security-and-privacy-incident-reporting">
                      Information security and privacy incident reporting
                    </a>
                  </li>
                  <li>
                    <a href="#interest-based-advertising">
                      Interest-based advertising
                    </a>
                  </li>
                  <li>
                    <a href="#communication-from-sowerent">
                      Communication from Sowerent
                    </a>
                  </li>
                  <li>
                    <a href="#opting-out-of-collection-of-information-by-third-parties">
                      Opting out of collection of information by third parties
                    </a>
                  </li>
                  <li>
                    <a href="#third-party-links-and-websites">
                      Third-party links and websites
                    </a>
                  </li>
                  <li>
                    <a href="#children’s-privacy">Children’s privacy</a>
                  </li>
                  <li>
                    <a href="visitors-from-outside-the United-States — Cross-Border">
                      Visitors from outside the United States — Cross-Border
                      Transfer
                    </a>
                  </li>
                  <li>
                    <a href="#updates-to-this-policy">Updates to this policy</a>
                  </li>
                  <li>
                    <a href="#how-to-contact-us">How to contact us ?</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={9} lg={8}>
              <div className="privacy-policy-wrap">
                <div className="policy-item" id="introduction">
                  <div className="title">
                    <h2>1. Introduction</h2>
                  </div>
                  <div className="content">
                    <h3>Welcome To Sowerent’s Privacy Policy!</h3>
                    <p>
                      At Sowerent, we believe in being transparent about how we
                      conduct our business. In fact it is one of our core
                      values. We, therefore, would like to be very transparent
                      about the way we collect and use your personal data. This
                      privacy policy (“Policy”) was developed by sowerent
                      Technology LLPHoldings Limited UK (“us” or “we” or
                      “Sowerent”) in order for you to understand how we, our
                      subsidiaries and affiliates collect, use, communicate,
                      protect, disclose, and make use of personal information in
                      connection with our website (“Site”), our blogs (“Blogs”),
                      our mobile application (“App”) or other service
                      (collectively “Services” and each individually as
                      “Service”). This Policy also tells you about your rights
                      and choices with respect to your Personal Information,
                      defined as information relating to an identified or
                      identifiable individual, and how you can contact us if you
                      have any questions or concerns.
                    </p>
                    <p>
                      We encourage you to read the Policy carefully before you
                      use any of our Services. This Policy applies to all of our
                      Services that link to it, including when you interact with
                      us in other contexts. Your continued use of our Services
                      constitutes consent to the Policy. If you have any
                      questions or concerns regarding this policy, please
                      contact us <Link to="">support@Sowerent.com</Link>
                    </p>
                  </div>
                </div>
                <div className="policy-item" id="information-we-collect">
                  <div className="title">
                    <h2>2. Information we collect</h2>
                  </div>
                  <div className="content">
                    <DataTable
                      value={privacyInfoCollect}
                      showGridlines
                      stripedRows
                    >
                      <Column field="category" header="Category" />
                      <Column field="examples" header="	Examples" />
                    </DataTable>
                  </div>
                </div>
                <div
                  className="policy-item"
                  id="how-do-we-collect-this-information"
                >
                  <div className="title">
                    <h2>3. How Do We Collect This Information</h2>
                    <p className="mb-4">
                      We collect information mainly in three ways:
                    </p>
                  </div>
                  <div className="content">
                    <ul>
                      <li>
                        We collect information through your use of the Services.
                        When you visit or use our Services, including when you
                        browse the Website or any User Website, register a User
                        Account, make an inquiry or a booking of a property,
                        when you download our App, we are aware of it and will
                        usually gather, collect and record such uses, sessions
                        and related information, either independently or with
                        the help of third-party services, including through the
                        use of “cookies” and other tracking technologies.
                      </li>
                      <li>
                        We collect information which you provide us voluntarily.
                        For example, we collect the Personal Information you
                        provide us when you register to our Services; when you
                        sign in to our Services via third party services such as
                        Facebook or Google; when you make inquiries or bookings
                        for certain apartments; when you submit or upload such
                        Personal Information as you use any of our Services;
                        and/or when you contact us directly by any communication
                        channel (e.g., telephone, email).
                      </li>
                      <li>
                        We also collect information from third party sources as
                        described in Section 11 below.
                      </li>
                    </ul>
                    <p>
                      Some information you provide through the Services is
                      collected and processed by third parties on our behalf.
                      For example, when you make an online booking through the
                      Services, we need to collect your credit or debit card
                      information. This information is collected and processed
                      by third-party payment processors. In the event that a
                      credit report is required to use a Service, you may be
                      asked to provide your Social Security number ('SSN'). When
                      SSNs are required, we use technology to pass that
                      information directly to the third-party providers who need
                      the information to process the credit or background check
                      report.
                    </p>
                  </div>
                </div>
                <div
                  className="policy-item"
                  id="how-do-we-use-this-information"
                >
                  <div className="title">
                    <h2>4. How do we use this information</h2>
                    <p className="mb-4">We use the collected information to:</p>
                  </div>
                  <div className="content">
                    <ul>
                      <li>
                        Provide you with our Services as an unregistered or
                        registered user of the Site, process transactions and
                        send related information such as confirmations and
                        invoices.
                      </li>
                      <li>
                        Send you technical notices, updates, security alerts and
                        support and administrative messages.
                      </li>
                      <li>
                        Respond to your comments, questions and requests and
                        provide customer service.
                      </li>
                      <li>
                        Manage and improve the performance of our products and
                        Services.
                      </li>
                      <li>
                        Communicate with you about products, services, offers,
                        promotions, rewards and events offered by sowerent
                        Technology LLPand others, and provide news and
                        information we think will be of interest to you.
                      </li>
                      <li>For audits and reporting purposes.</li>
                      <li>
                        Detect, investigate and prevent fraudulent transactions
                        and other illegal activities and protect the rights and
                        property of Sowerent, as well as that of our employees,
                        our agents or others, and to comply with applicable law
                        and government requests.
                      </li>
                      <li>
                        Provide and deliver the Services, process transactions
                        and send related information such as confirmations and
                        invoices.
                      </li>
                      <li>
                        Monitor and analyze trends, usage and activities in
                        connection with our Services.
                      </li>
                      <li>
                        Revise, modify, and update existing Services and develop
                        new Services.
                      </li>
                      <li>
                        Carry out any other purpose described to you at the time
                        the information was collected.
                      </li>
                    </ul>
                    <p>
                      We only collect personal information that is required for
                      us to collect by our legal obligations or is necessary to
                      fulfill the Services, as described here. We may retain
                      your Personal Information for as long as necessary to
                      fulfill the purpose for which it was collected or to
                      comply with legal or regulatory requirements or as
                      otherwise disclosed to you.
                    </p>
                  </div>
                </div>
                <div
                  className="policy-item"
                  id="how-do-we-disclose-this-personal-information"
                >
                  <div className="title">
                    <h2>5. How do we disclose this personal information</h2>
                    <p className="mb-4">
                      We may disclose personal information in the following
                      circumstances and to the following parties:
                    </p>
                  </div>
                  <div className="content">
                    <ul>
                      <li>
                        Other companies belonging to or directly affiliated to
                        sowerent Technology LLP and always in accordance with
                        this policy. Note that sharing of Personal Information
                        from Sowerent subsidiaries and affiliated companies in
                        the European Union with Sowerent’s United States
                        subsidiary, adheres to the EU-US Privacy Shield
                        frameworks.
                      </li>
                      <li>
                        Certain service providers perform a number of services
                        on our behalf, such as web hosting, email service
                        providers, payment processing, analytics, etc. To do so,
                        we sometimes need to share your personal information
                        with them for the purposes described above. These
                        service providers are located around the world.
                      </li>
                      <li>
                        Certain third-parties when required by law, and when
                        necessary to provide, and to protect Our Services.
                      </li>
                      <li>
                        Certain third-party partners, after we receive your
                        explicit consent. We will request your consent to share
                        personal information about you with third parties so
                        that they may provide you with special offers,
                        promotional materials, and other materials that may be
                        of interest to you.
                      </li>
                      <li>
                        Aggregated information that does not identify specific
                        individuals.
                      </li>
                      <li>
                        Third parties involved in a corporate transaction
                        involving all or part of sowerent Technology LLP assets.
                      </li>
                    </ul>
                    <p>
                      To clarify, sowerent Technology LLP may share your
                      Personal Information in manners other than as described
                      above, pursuant to your explicit approval, or if we are
                      legally obligated to do so.
                    </p>
                  </div>
                </div>
                <div
                  className="policy-item"
                  id="what-are-your-rights-in-relation-to-your-personal"
                >
                  <div className="title">
                    <h2>
                      6. What are your rights in relation to your personal
                      information
                    </h2>
                    <p className="mb-4">
                      It is important that you have control over your Personal
                      Information. That’s why we are taking steps to enable you
                      to access, receive a copy of, update, amend, delete, or
                      limit the use of your Personal Information.
                    </p>
                    <p>
                      Individuals located in certain jurisdictions, including
                      the European Economic Area, California, and other US
                      states that have enacted consumer privacy laws, have
                      certain statutory rights in relation to their personal
                      data. Specifically, they have the following rights:
                    </p>
                  </div>
                  <div className="content">
                    <ul>
                      <li>
                        Right of access: you have the right to obtain
                        confirmation that your personal data is processed, and
                        in the case of processing, information regarding the
                        purposes of your data processing, the retention period
                        (and the criteria used to determine this period) of the
                        data, the recipients to whom your personal data has been
                        or will be disclosed, and a copy of your personal data
                        that we maintain.
                      </li>
                      <li>
                        Right to data portability: you have the right to receive
                        your personal data that you have provided to us, in a
                        structured, commonly used and machine-readable format.
                      </li>
                      <li>
                        Right to rectification or correction: you have the right
                        to obtain rectification of any inaccurate or incomplete
                        personal data that we maintain about you.
                      </li>
                      <li>
                        Right to erasure (right to be forgotten): you have the
                        right, in some circumstances, to obtain the erasure of
                        the personal data that we maintain about you. The right
                        to be forgotten is not unreservedly guaranteed. It is
                        limited especially when colliding with the right of
                        freedom of expression and information. Other exceptions
                        are if the processing of data which is subject to an
                        erasure request is necessary to comply with legal
                        obligations, for archiving purposes in the public
                        interest, scientific or historical research purposes or
                        statistical purposes or for the defence of legal claims.
                      </li>
                      <li>
                        Right to restriction of processing: you have the right,
                        in some circumstances, to obtain restriction of
                        processing to the personal data that we maintain about
                        you.
                      </li>
                      <li>
                        Right to object: you have the right to object to any
                        processing of your personal data that we maintain, but
                        there may be compelling reasons for continuing this
                        processing from our side. In any case we will assess
                        your request and respond to you accordingly. Marketing
                        activities are not included in the aforementioned
                        compelling reasons.
                      </li>
                    </ul>
                    <p>
                      Before disclosing the requested records of Personal
                      Information we keep of you, we may ask you for additional
                      information in order to confirm your identity and for
                      security purposes. We reserve the right to charge a fee
                      where permitted by law (e.g., if your request is unfounded
                      or excessive).
                    </p>
                    <p>
                      sowerent Technology LLP does not “sell” or “share” your
                      Personal Information as we understand those terms are used
                      under the Digital Personal Data Protection Act.
                    </p>
                    <p>
                      If you believe that sowerent Technology LLP has failed to
                      take appropriate action in responses to your privacy
                      rights request, you have the right to appeal and can do so
                      by contact us as described below. You also have the right
                      to file a complaint with your local supervisory authority
                      for data protection (but we still recommend that you
                      contact us first).
                    </p>
                    <p>
                      In case you wish to exercise one of these rights, please
                      contact us at privacy@theSowerent.com. We will make all
                      reasonable efforts to honor your request promptly (unless
                      we require further information from you in order to
                      fulfill your request), subject to legal and other
                      permissible considerations.
                    </p>
                    <p>
                      We will not discriminate against you for exercising any of
                      your rights described here.
                    </p>
                  </div>
                </div>
                <div
                  className="policy-item"
                  id="information-security-and-privacy-incident-reporting"
                >
                  <div className="title">
                    <h2>
                      7. Information security and privacy incident reporting
                    </h2>
                  </div>
                  <div className="content">
                    <p>
                      We implement reasonable organizational and technical
                      measures in order to safeguard your Personal Information
                      against accidental or unlawful destruction, loss, change
                      or damage. Having said that, no security program is 100%
                      foolproof and thus we cannot guarantee the absolute
                      security of your personal or other information.
                    </p>
                  </div>
                </div>
                <div className="policy-item" id="interest-based-advertising">
                  <div className="title">
                    <h2>8. Interest-based advertising</h2>
                  </div>
                  <div className="content">
                    <p>
                      We participate in interest-based advertising. This means
                      that when you use the Services, we or third party service
                      providers or advertisers may use cookies (that they
                      collect or that we provide to them) or other similar
                      technologies to collect information about your use of the
                      Services (including mobile applications) or your online
                      activities over time and across different websites and
                      devices. Collected information may include the content you
                      view, the date and time that you view this content, and
                      the website that referred you to the Services, and this
                      information may be associated with your unique browser,
                      device identifier, or Internet Protocol (IP) address.
                      These practices help tailor advertisements that are
                      relevant and useful to you. These tailored advertisements
                      may appear on the Services or on other websites,
                      applications or properties. For more information and to
                      opt-out of most third-party cookie collection, you can
                      visit
                      <Link
                        to={"https://optout.aboutads.info/?c=2&lang=EN"}
                        target="_blank"
                      >
                        https://optout.aboutads.info/?c=2&lang=EN
                      </Link>{" "}
                      or{" "}
                      <Link
                        to={"https://optout.networkadvertising.org/?c=1"}
                        target="_blank"
                      >
                        https://optout.networkadvertising.org/?c=1
                      </Link>
                      .
                    </p>
                    <p>
                      In addition, if you have provided your email address to
                      us, we may use a scrambled, unreadable form (a hash) of
                      your email address to deliver tailored advertisements to
                      you on the Services or on other websites. We do not access
                      or collect any of the contents of your email messages in
                      providing interest-based advertising. Our use of
                      information received from Gmail APIs will adhere to
                      Google’s Limited Use Requirements.
                    </p>
                  </div>
                </div>
                <div className="policy-item" id="communication-from-sowerent">
                  <div className="title">
                    <h2>9. Communication from Sowerent</h2>
                  </div>
                  <div className="content">
                    <p>
                      We may use your Personal Information to send you
                      promotional content and messages by email, text messages,
                      direct text messages, marketing calls and similar forms of
                      communication. If you do not wish to receive such
                      promotional messages or calls, you may notify us at any
                      time or follow the “unsubscribe” link. We take steps to
                      limit the promotional content we send you to a reasonable
                      and proportionate level, and to send you information which
                      we believe may be of interest or relevance to you, based
                      on your information.
                    </p>
                    <p>
                      Also, we may contact you with important information
                      regarding our Services, or your use thereof. For example,
                      we may send you billing information; replies to your
                      support ticket or email; send you reminders or warnings
                      regarding upcoming or late payments for your current or
                      upcoming subscriptions; or notify you of material changes
                      in our Services. It is important that you are always able
                      to receive such messages. For this reason, you are not
                      able to opt-out of receiving such Service and Billing
                      Messages unless you are no longer a sowerent Technology
                      LLPVisitor or User.
                    </p>
                  </div>
                </div>
                <div className="policy-item" id="sowerent-job-applications">
                  <div className="title">
                    <h2>10. sowerent Technology LLP job applications</h2>
                  </div>
                  <div className="content">
                    <p>
                      sowerent Technology LLP welcomes all qualified Applicants
                      to apply to any of the open positions published on
                      https://Sowerent.workable.com (being updated), by sending
                      us their contact details and CV (“Applicants’
                      Information”) via email or through any other means. We
                      understand that privacy and discreteness are crucial to
                      our Applicants, and are committed to keep Applicants’
                      Information private and use it solely for Sowerent’s
                      internal recruitment purposes (including for identifying
                      Applicants, evaluating their applications, making hiring
                      and employment decisions, and contacting Applicants by
                      phone or in writing). Please note that sowerent Technology
                      LLPmay retain Applicants’ Information submitted to it for
                      no longer than two years after the applied position has
                      been filled or closed. This is done so we can re-consider
                      Applicants for other positions and opportunities at
                      Sowerent; so we can use their Applicants Information as a
                      reference for future applications submitted by them; and
                      if the Applicant is hired, for additional employment and
                      business purposes related to his/her work at Sowerent. If
                      you previously submitted your Applicants’ Information to
                      Sowerent, and now wish to access it, update it or have it
                      deleted from Sowerent’s systems, please contact us{" "}
                      <Link to={"mailto:support@Sowerent.com"}>
                        support@Sowerent.com
                      </Link>
                      .
                    </p>
                  </div>
                </div>
                <div
                  className="policy-item"
                  id="opting-out-of-collection-of-information-by-third-parties"
                >
                  <div className="title">
                    <h2>
                      11. Opting out of collection of information by third
                      parties
                    </h2>
                  </div>
                  <div className="content">
                    <p>
                      Sowerent's third-party ad servers, ad network providers,
                      third-party advertisers may provide you with
                      advertisements that you may see on the Services or on
                      other affiliated websites. To improve the relevancy and
                      help measure the effectiveness of such advertisements, the
                      sowerent Technology LLP Ad Providers may use cookies, web
                      beacons, clear gifs or similar technologies. These are
                      used to record users' activity, such as the pages visited,
                      and to learn what types of information are of most
                      interest to the users. As noted above, you can learn more
                      and opt-out, by visiting
                      <Link to={"https://optout.aboutads.info/?c=2&lang=EN"}>
                        https://optout.aboutads.info/?c=2&lang=EN
                      </Link>{" "}
                      or
                      <Link to={"https://optout.networkadvertising.org/?c=1"}>
                        https://optout.networkadvertising.org/?c=1
                      </Link>
                      .
                    </p>
                  </div>
                </div>
                <div
                  className="policy-item"
                  id="third-party-links-and-websites"
                >
                  <div className="title">
                    <h2>12. Third-party links and websites</h2>
                  </div>
                  <div className="content">
                    <p>
                      Throughout the Services, we may link to the websites of
                      other companies and/or individuals. These third-party
                      websites may collect information about users on those
                      websites, and Sowerent's privacy policy does not extend to
                      these external websites and third parties. Please refer
                      directly to these third parties and websites regarding
                      their privacy policies.
                    </p>
                  </div>
                </div>
                <div className="policy-item" id="children’s-privacy">
                  <div className="title">
                    <h2>13. Children’s privacy</h2>
                  </div>
                  <div className="content">
                    <p>
                      Our Services are addressed to individuals, who are at
                      least 18 years old, unless we specify otherwise. For
                      individuals under 18, we do not knowingly collect personal
                      data, without prior parental consent. If you find out that
                      your child has provided us with any personal data, without
                      your prior consent, please inform us, in order to delete
                      this information and terminate any account that your child
                      may have created with us.
                    </p>
                  </div>
                </div>
                <div
                  className="policy-item"
                  id="visitors-from-outside-the United-States — Cross-Border"
                >
                  <div className="title">
                    <h2>
                      14. Visitors from outside the United States — Cross-Border
                      Transfer
                    </h2>
                  </div>
                  <div className="content">
                    <p>
                      The data protection and other applicable laws of the
                      United States or other countries may not be as
                      comprehensive as those laws or regulations in your country
                      or may otherwise differ from the data protection or
                      consumer protection laws in your country. Your information
                      may be available to government authorities under lawful
                      orders and law applicable in such jurisdictions. By using
                      the Services and/or providing Personal Information to us,
                      you consent to transfer of your information to our
                      facilities as described in this Privacy Policy.
                    </p>
                  </div>
                </div>
                <div className="policy-item" id="updates-to-this-policy">
                  <div className="title">
                    <h2>15. Updates to this policy</h2>
                  </div>
                  <div className="content">
                    <p>
                      This sowerent Technology LLP Site Privacy Policy may be
                      changed or updated occasionally to meet legal,
                      technological or operational requirements and standards.
                      You are encouraged to frequently visit this policy to stay
                      updated about changes. In case of critical changes, these
                      will be circulated to you prior to their implementation.
                      Modifications will be effective on the day they are
                      published.
                    </p>
                  </div>
                </div>
                <div className="policy-item" id="how-to-contact-us">
                  <div className="title">
                    <h2>16. How to contact us</h2>
                  </div>
                  <div className="content">
                    <p>
                      If you have any questions about this Privacy Policy or
                      wish to exercise any of your rights, you may contact us
                      support@Sowerent.com. We will attempt to resolve any
                      complaints regarding the use of your Personal Information
                      in accordance with this Privacy Policy.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PrivacyPolicy;
