import { faInr } from "@fortawesome/free-solid-svg-icons";
import { IoMdPrint } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Row , Spinner} from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import billsService from "../../services/bills-payments-service"; // Import billsService
import { toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css";
import { usePrint } from "print-react-component"; // Import usePrint
import personalInfoServices from "../../services/profile-personal-info";
import Logo from "../../assets/images/sowerent-logo-blue.png";
import { handleErrors } from "../../utils/errorHandler";
import { useNavigate } from "react-router-dom";

const BillsPayments = ({ rowData }) => {
  const navigate = useNavigate();

  const [billData, setBillData] = useState({
    rents: [],
    utilities: [],
    oItems: [],
    maintenance: [],
  });
  // Reference to the receipt container
  const [receiptData, setReceiptData] = useState([]); // State for receipts
  // Initialize the navigate function
  const { holder, printReactNode } = usePrint(); // usePrint hook
  const [profileData, setProfileData] = useState(null);
  const [loadingBills, setLoadingBills] = useState(false); 

  const fetchBillsData = async () => {
    setLoadingBills(true);
    try {
      const response = await billsService.getBillsPayment();
      if (response.data.isSuccess) {
        setBillData(response.data.result);
      }
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    } finally{
      setLoadingBills(false);
    }
  };

  useEffect(() => {
    async function fetchProfile() {
      try {
        const response = await personalInfoServices.getProfileInfo();
        if (response.data.isSuccess) {
          setProfileData(response.data.result); // Set the profile data
        }
      } catch (error) {
        const errorMessage = handleErrors(error);
        toast.error(errorMessage);
      }
    }

    fetchProfile();
  }, []);

  const fetchReceiptsData = async () => {
    try {
      const response = await billsService.getReceipts();
      if (response.data.isSuccess) {
        setReceiptData(response.data.result);
      }
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    fetchBillsData();
    fetchReceiptsData();
  }, []);

  // Helper function to format dates as "Jan-11-2024"
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const options = {  month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-GB", { month: "short" });
    const year = date.getFullYear();
    let hour = date.getHours();
    const minute = String(date.getMinutes()).padStart(2, "0");
    const ampm = hour >= 12 ? "pm" : "am";
    hour = hour % 12;
    hour = hour ? String(hour).padStart(2, "0") : "12";

    return `${day}-${month}-${year} ${hour}:${minute} ${ampm}`;
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData.paymentStatus === "Success") {
      return <span className="badge badge-success">Success</span>;
    } else if (rowData.paymentStatus === "Initiated") {
      return <span className="badge badge-warning">Initiated</span>;
    }
    return rowData.paymentStatus; // Default text if status does not match
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // maintenance
  const handleMaintenancePayment = async (maintenance) => {
    try {
      const paymentRequest =
        await billsService.getMaintenancePaymentRequestDetails(
          maintenance.orderGuid
        );
      const paymentRequestDetails = paymentRequest.data.result;
      await initiateMaintenancePayment(
        paymentRequestDetails,
        maintenance.orderGuid
      );
    } catch (error) {
    }
  };

  const initiateMaintenancePayment = async (
    paymentRequestDetails,
    orderGuid
  ) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: "rzp_test_obKqWxOCW5u38m",
      amount: paymentRequestDetails.maintenanceAmount * 100, // Convert to paise
      currency: "INR",
      name: "SoWeRent",
      description: "Maintenance Payment",
      image: "https://sowerent.com/assets/images/logo/sowerent-logo-blue.png",
      order_id: paymentRequestDetails.paymentId,
      handler: async (response) => {
        const data = {
          OrderGuid: orderGuid,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        };
        try {
          const result = await billsService.getMaintenancePaymentSuccessDetails(
            data
          );
          if (result.data.isSuccess) {
            toast.success("Maintenance payment successful!");
            fetchBillsData();
            fetchReceiptsData();
          } else {
            toast.error("Maintenance payment verification failed.");
          }
        } catch (error) {
          let errorMessage = handleErrors(error);
          if (error.response) {
            if (error.response.status === 404) {
              errorMessage =
                error.response.data.message ;
            } else if (error.response.status === 500) {
              errorMessage =
                error.response.data.message;
            }
          }
          toast.error(errorMessage);
        }
      },
      prefill: {
        name: paymentRequestDetails.userName,
        email: paymentRequestDetails.emailAddress,
        contact: paymentRequestDetails.contactNo,
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // oItems
  const handleOtherPayment = async (otherItem) => {
    try {
      const paymentRequest = await billsService.getOtherPaymentRequestDetails(
        otherItem.orderGuid
      );
      const paymentRequestDetails = paymentRequest.data.result;
      await initiateOtherPayment(paymentRequestDetails, otherItem.orderGuid);
    } catch (error) {
    }
  };

  const initiateOtherPayment = async (paymentRequestDetails, orderGuid) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: "rzp_test_obKqWxOCW5u38m",
      amount: paymentRequestDetails.totalAmount,
      currency: "INR",
      name: "SoWeRent",
      description: "Other Payment",
      image: "https://sowerent.com/assets/images/logo/sowerent-logo-blue.png",
      order_id: paymentRequestDetails.paymentId,
      handler: async (response) => {
        const data = {
          OrderGuid: orderGuid,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        };
        try {
          const result = await billsService.getOtherPaymentSuccessDetails(data);
          if (result.data.isSuccess) {
            toast.success("Other payment successful!");
            fetchBillsData();
            fetchReceiptsData();
          } else {
            toast.error("Other payment verification failed.");
          }
        } catch (error) {
          let errorMessage = handleErrors(error);
          if (error.response) {
            if (error.response.status === 404) {
              errorMessage =
                error.response.data.message ;
            } else if (error.response.status === 500) {
              errorMessage =
                error.response.data.message;
            }
          }
          toast.error(errorMessage);
        }
      },
      prefill: {
        name: paymentRequestDetails.userName,
        email: paymentRequestDetails.emailAddress,
        contact: paymentRequestDetails.contactNo,
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  //Utility
  const handleUtilityPayment = async (utility) => {
    try {
      const paymentRequest = await billsService.getUtilityPaymentRequestDetails(
        utility.orderGuid
      );
      const paymentRequestDetails = paymentRequest.data.result;
      await initiateUtilityPayment(paymentRequestDetails, utility.orderGuid);
    } catch (error) {
    }
  };
  const initiateUtilityPayment = async (paymentRequestDetails, orderGuid) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: "rzp_test_obKqWxOCW5u38m",
      amount: paymentRequestDetails.utilityAmount,
      currency: "INR",
      name: "SoWeRent",
      description: "Utility Payment",
      image: "https://sowerent.com/assets/images/logo/sowerent-logo-blue.png",
      order_id: paymentRequestDetails.paymentId,
      handler: async (response) => {
        const data = {
          OrderGuid: orderGuid,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        };
        try {
          const result = await billsService.getUtilityPaymentSuccessDetails(
            data
          );
          if (result.data.isSuccess) {
            toast.success("Utility payment successful!");
            fetchBillsData();
            fetchReceiptsData();
          } else {
            toast.error("Utility payment verification failed.");
          }
        } catch (error) {
          let errorMessage = handleErrors(error);
          if (error.response) {
            if (error.response.status === 404) {
              errorMessage =
                error.response.data.message ;
            } else if (error.response.status === 500) {
              errorMessage =
                error.response.data.message;
            }
          }
          toast.error(errorMessage);
        }
      },
      prefill: {
        name: paymentRequestDetails.userName,
        email: paymentRequestDetails.emailAddress,
        contact: paymentRequestDetails.contactNo,
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  //Rents
  const handleRentPayment = async (rents) => {
    try {
      // Fetch payment request details
      const paymentRequest = await billsService.getPaymentRequestDetails(
        rents.orderGuid
      );
      const paymentRequestDetails = paymentRequest.data.result;

      // Load Razorpay SDK
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      const options = {
        key: "rzp_test_obKqWxOCW5u38m",
        amount: paymentRequestDetails.rentAmount,
        currency: "INR",
        name: "SoWeRent",
        description: "Rent Payment",
        image: "https://sowerent.com/assets/images/logo/sowerent-logo-blue.png",
        order_id: paymentRequestDetails.paymentId,
        handler: async (response) => {
          const data = {
            OrderGuid: rents.orderGuid,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };
          try {
            const result = await billsService.getPaymentSuccessDetails(data);
            if (result.data.isSuccess) {
              toast.success("Rent Payment successful!");
              fetchBillsData();
              fetchReceiptsData();
            } else {
              toast.error("Payment verification failed.");
            }
          } catch (error) {
            let errorMessage = handleErrors(error);
            if (error.response) {
              if (error.response.status === 404) {
                errorMessage =
                  error.response.data.message ;
              } else if (error.response.status === 500) {
                errorMessage =
                  error.response.data.message;
              }
            }
            toast.error(errorMessage);
          }
        },
        prefill: {
          name: paymentRequestDetails.userName,
          email: paymentRequestDetails.emailAddress,
          contact: paymentRequestDetails.contactNo,
        },
        theme: {
          color: "#61dafb",
        },
      };

      // Open Razorpay checkout
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {

    }
  };


  const renderBillCard = (title, item, amountKey, dateKey) => (
    <div className="bill-details__col">
      <div className="card">
        <div className="bill-info-wrap">
          <div className="bill-name">{title}</div>
          <div className="bill-info">
            {formatDate(item[dateKey]) || "Date not available"}
          </div>
        </div>
        <div className="bill-amount">
          <FontAwesomeIcon icon={faInr} />
          {item[amountKey] || "0"}
        </div>
        <div className="pay-bill">
          <Button
            className="btn-theme-blue py-1"
            onClick={() => {
              if (title.startsWith("Rental")) {
                handleRentPayment(item);
              } else if (title.startsWith(item.utilityType)) {
                handleUtilityPayment(item);
              } else if (title.startsWith("Maintenance")) {
                handleMaintenancePayment(item);
              } else if (title.startsWith(item.itemName)) {
                handleOtherPayment(item);
              }
            }}
          >
            Pay Now
          </Button>
        </div>
      </div>
    </div>
  );

  // const groupBillsByFlat = () => {
  //   const flats = new Set([
  //     ...billData.rents.map((b) => b.flatNo),
  //     ...billData.utilities.map((b) => b.flatNo),
  //     ...billData.oItems.map((b) => b.flatNo),
  //     ...billData.maintenance.map((b) => b.flatNo),
  //   ]);

  //   return Array.from(flats).map((flatNo) => ({
  //     flatNo,
  //     rents: billData.rents.filter((b) => b.flatNo === flatNo),
  //     utilities: billData.utilities.filter((b) => b.flatNo === flatNo),
  //     oItems: billData.oItems.filter((b) => b.flatNo === flatNo),
  //     maintenance: billData.maintenance.filter((b) => b.flatNo === flatNo),
  //   }));
  // };


  // const formatDatemonth = (date) => {
  //   if (!date) return "N/A";
  //   const options = { month: "short" }; // Extracts the month's full name
  //   return new Date(date).toLocaleDateString("en-US", options);
  // };
  
  
  return (
    <div className="bill-payment-wrapper">
      {holder}
      <div className="my-account-title">
        <h2>Bills & Payments</h2>
      </div>
       <div className="bill-detail-content">
        <div className="bill-details__row">
          {billData.rents.length === 0 &&
          billData.utilities.length === 0 &&
          billData.oItems.length === 0 &&
          billData.maintenance.length === 0 ? (
            <div className="no-property-message">
            <h6 className="no-data-found">No pending Bills
            </h6>
              {loadingBills && (
                <div className="text-center my-4">
                  <Spinner animation="border" variant="primary" /> {/* Loader */}
                </div>
              )}
              </div>
          ) : (
            <>
              {/* Rental Section */}
              {billData.rents.length > 0 &&
                billData.rents
                  .filter((rent) => rent.currentRentAmount > 0)
                  .map((rent, index) => (
                    <React.Fragment key={index}>
                      {renderBillCard(
                        "Rental -(F-" + rent.flatNo + ")",
                        rent,
                        "currentRentAmount",
                        "fromDate"
                      )}
                    </React.Fragment>
                  ))}

              {/* Utilities Section */}
              {billData.utilities.length > 0 &&
                billData.utilities
                  .filter((utility) => utility.utilityAmount > 0)
                  .map((utility, index) => (
                    <React.Fragment key={index}>
                      {renderBillCard(
                        utility.utilityType + "-(F-" + utility.flatNo + ")",
                        utility,
                        "utilityAmount",
                        "fromDate"
                      )}
                    </React.Fragment>
                  ))}

              {/* Other Items Section */}
              {billData.oItems.length > 0 &&
                billData.oItems
                  .filter((item) => item.totalAmount > 0)
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      {renderBillCard(
                        item.itemName + "-(F-" + item.flatNo + ")",
                        item,
                        "totalAmount",
                        "updatedOn"
                      )}
                    </React.Fragment>
                  ))}

              {/* Maintenance Section */}
              {billData.maintenance.length > 0 &&
                billData.maintenance
                  .filter((maintenance) => maintenance.maintenanceAmount > 0)
                  .map((maintenance, index) => (
                    <React.Fragment key={index}>
                      {renderBillCard(
                        "Maintenance -(F-" + maintenance.flatNo + ")",
                        maintenance,
                        "maintenanceAmount",
                        "fromDate"
                      )}
                    </React.Fragment>
                  ))}
            </>
          )}
        </div>
       </div>
       

        <Row>
        <Col>
          <div className="renewal-property">
            <div className="my-account-subtitle">
              <h3>Receipts</h3>
            </div>
            <DataTable
              value={receiptData}
              paginator
              rows={10}
              showGridlines
              stripedRows
            >
              <Column
                header="Property"
                body={(rowData) => (
                  <div>
                    <div>
                      <b>{rowData.propertyName}</b>
                    </div>
                    <small>
                      <i>
                        {formatDate(rowData.fromDate)} to{" "}
                        {formatDate(rowData.toDate)}
                      </i>
                    </small>
                  </div>
                )}
              />
              <Column field="itemName" header="Remark" />
              <Column
                field="amount"
                header="Amount"
                body={(rowData) => (
                  <div>
                    <FontAwesomeIcon icon={faInr} /> {/* INR Icon */}
                    {rowData.amount}
                  </div>
                )}
              />
              <Column
                field="paymentStatus"
                header="Status"
                body={statusBodyTemplate}
              />
              <Column
                header="Payment Id"
                body={(rowData) => (
                  <div>
                    <div className="text-success">{`${rowData.paymentId}`}</div>
                    <small>{`${formatDateTime(rowData.paidOn)}`}</small>
                  </div>
                )}
              />
                <Column
                field="receipt"
                header="Receipt"
                body={(rowData) => {
                  if (rowData.paymentStatus === "Success") {
                    return (
                      <div className="receipt-pdf-wrapper">
                        <IoMdPrint
                          style={{
                            cursor: "pointer",
                            width: "50px",
                            fontSize: "30px",
                          }}
                          onClick={() =>
                            printReactNode(
                              <div className="main-wrapper">
                                <div className="container">
                                  <div className="row justify-content-center">
                                    <div className="col-xl-9 col-md-8">
                                      <div className="settings-widget profile-details mar-top-20">
                                        <div className="settings-menu invoice-list-blk p-0">
                                          <div className="card pro-post border-0 mb-0">
                                            <div className="card-body">
                                              <div className="invoice-item">
                                                <div
                                                  className="wrapper-payment"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div
                                                    className="col-md-6"
                                                    style={{ width: "30%" }}
                                                  >
                                                    <div className="invoice-logo">
                                                      <img
                                                        src={Logo}
                                                        className="img-fluid"
                                                        alt="Logo"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="col-md-6"
                                                    style={{ width: "50%" }}
                                                  >
                                                    <p className="invoice-details">
                                                      <strong>
                                                        #238/239, 2nd floor,
                                                        Raheja Arcade,
                                                        Koramangala, Bangalore,
                                                        Karnataka-560095
                                                      </strong>
                                                      <br />
                                                      <strong>
                                                        Email :
                                                      </strong>{" "}
                                                      helpdesk@sowerent.com
                                                      <br />
                                                      <strong>
                                                        Call Us At :
                                                      </strong>{" "}
                                                      +91 8867319933
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="invoice-item">
                                                <div
                                                  className="row"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="col-md-12">
                                                    <div className="bg-light-gray-2 p-2 mb-3">
                                                      <span>
                                                        <strong>
                                                          Recipt Date:
                                                        </strong>{" "}
                                                        {formatDate(
                                                          rowData.updatedOn
                                                        )}
                                                      </span>
                                                      <br />
                                                      <span>
                                                        <strong>Status:</strong>{" "}
                                                        Paid
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {profileData && (
                                                <div className="invoice-item">
                                                  <div
                                                    className="row"
                                                    style={{ width: "100%" }}
                                                  >
                                                    <div className="col-md-12">
                                                      <div className="bg-light-gray-2 p-2 mb-3">
                                                        <h5>Billing Info</h5>
                                                        <p>
                                                          <strong>Name:</strong>{" "}
                                                          {
                                                            profileData.firstName
                                                          }{" "}
                                                          {profileData.lastName}
                                                          <br />
                                                          <strong>
                                                            Email:
                                                          </strong>{" "}
                                                          {
                                                            profileData.emailAddress
                                                          }
                                                          <br />
                                                          <strong>
                                                            Phone:
                                                          </strong>{" "}
                                                          {profileData.phoneNo}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                              <div className="invoice-item invoice-table-wrap">
                                                <div className="row">
                                                  <div className="col-md-12">
                                                    <div className="table-responsive">
                                                      <table className="invoice-table table table-bordered">
                                                        <thead>
                                                          <tr>
                                                            <th>Property</th>
                                                            <th>Period</th>
                                                            <th>Payment ID</th>
                                                            <th className="text-center">
                                                              Paid on
                                                            </th>
                                                            <th className="text-end">
                                                              Amount
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              {" "}
                                                              {
                                                                rowData.propertyName
                                                              }
                                                              - Flat No-
                                                              {rowData.flatNo}
                                                            </td>
                                                            <td>
                                                              {formatDate(
                                                                rowData.fromDate
                                                              )}{" "}
                                                              to{" "}
                                                              {formatDate(
                                                                rowData.toDate
                                                              )}
                                                            </td>
                                                            <td style={{color:"green"}}>
                                                              {
                                                                rowData.paymentId
                                                              }
                                                            </td>
                                                            <td className="text-center">
                                                              {formatDate(
                                                                rowData.paidOn
                                                              )}
                                                            </td>
                                                            <td className="text-end">
                                                              ₹{rowData.amount}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        />
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </DataTable>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BillsPayments;
