import { isValidPhoneNumber } from "react-phone-number-input";

export const validateFlatmateForm = (formData) => {
  const errors = {};

  // Validate first name
  if (!formData.firstName || formData.firstName.trim() === "") {
    errors.firstName = "First name is required";
  } else if (formData.firstName.length < 1 || formData.firstName.length > 50) {
    errors.firstName = "First name must be between 1 and 50 characters";
  }

  // Validate last name
  if (!formData.lastName || formData.lastName.trim() === "") {
    errors.lastName = "Last name is required";
  } else if (formData.lastName.length < 1 || formData.lastName.length > 50) {
    errors.lastName = "Last name must be between 1 and 50 characters";
  }

  // Validate email
  if (!formData.email || formData.email.trim() === "") {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = "Email address is invalid";
  } else if (formData.email.length < 1 || formData.email.length > 100) {
    errors.email = "Email address must be between 1 and 100 characters";
  }

  // // Validate contact number
  // if (!formData.contactNumber || formData.contactNumber.trim() === "") {
  //   errors.contactNumber = "Contact number is required";
  // } else if (formData.contactNumber.length < 1 || formData.contactNumber.length > 15) {
  //   errors.contactNumber = "Contact number must be between 1 and 15 digits";
  // }

  // if (!formData.contactNumber.trim()) {
  //   errors.contactNumber = "Contact number is required";
  // } else if (!/^\+?\d{12}$/.test(formData.contactNumber)) { // Validate 10 digits (with optional country code)
  //   errors.contactNumber = "Contact number must be 10 digits";
  // }
  if (!formData.contactNumber) {
    errors.contactNumber = "Contact  number is required.";
  } else if (!isValidPhoneNumber(formData.contactNumber)) {
    errors.contactNumber = "Please enter a valid phone number.";
  }

  // Validate relationship
  if (!formData.relationship || formData.relationship.trim() === "") {
    errors.relationship = "Relationship is required";
  } else if (formData.relationship.length < 1 || formData.relationship.length > 20) {
    errors.relationship = "Relationship must be between 1 and 20 characters";
  }

  // Validate age
  if (!formData.age || isNaN(formData.age)) {
    errors.age = "Age is required and must be a number";
} else if (formData.age <= 0 || formData.age > 99) {
    errors.age = "Age must be a  number between 1 and 99";
}


  // Validate document type (idType)
  if (!formData.documentType || formData.documentType.trim() === "") {
    errors.documentType = "Document type is required";
  } else if (formData.documentType.length < 1 || formData.documentType.length > 20) {
    errors.documentType = "Document type must be between 1 and 20 characters";
  } else if (!["Adhar", "Pan"].includes(formData.documentType)) {
    errors.documentType = "Document type must be either 'Adhar' or 'Pan'";
  }
  
  const aadharRegex = /^\d{12}$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  // Validate Aadhar/PAN number (idNo)
  if (!formData.adharPanNumber || formData.adharPanNumber.trim() === "") {
    errors.adharPanNumber = "Document number (Aadhar/Pan) is required";
  } else if (formData.documentType === "Adhar" && !aadharRegex.test(formData.adharPanNumber.trim())) {
    errors.adharPanNumber = "Aadhar number must be exactly 12 digits";
  } else if (formData.documentType === "Pan" && !panRegex.test(formData.adharPanNumber.trim())) {
    errors.adharPanNumber = "PAN number must be in the format: ABCDE1234F";
  }

  return errors;
};
