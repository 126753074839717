import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import "swiper/css/navigation";
import BlogCard from "../common/blog-card";
import blogService from "../../services/blogService";
import { ProgressSpinner } from "primereact/progressspinner";
import { handleErrors } from "../../utils/errorHandler";
import { ErrorHandlingDesign } from "../common/errorHandlingDesign";

const HomeBlogs = () => {
  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to fetch property listing
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const response = await blogService.getFeaturedBlog();
        setBlogList(response.data.result || []);
      } catch (error) {
        const errorMessage = handleErrors(error);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);
  return (
    <section className="latest-blog-sec">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="section-heading text-center">
              <h2>Blogs</h2>
              <div className="sec-line">
                <span className="sec-line1" />
                <span className="sec-line2" />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {error ? (
              <ErrorHandlingDesign errorMessage={error} />
            ) : loading ? (
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="3"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            ) : (
              <Swiper
                className="news-slider"
                slidesPerView={1}
                spaceBetween={24}
                autoplay={true}
                modules={[Pagination]}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  576: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  991: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                  },
                }}
              >
                {blogList.map((blogItem, index) => {
                  return (
                    <SwiperSlide key={`news-events-${index}`}>
                      <BlogCard blogItem={blogItem} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBlogs;
