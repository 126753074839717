// src/services/scheduleVisitService.js
import apiClient from "./axios.config";
import Cookies from "js-cookie";

const headers = {
  Authorization: `Bearer ${Cookies.get("accessToken")}`, // Use token if available
};

// Schedule Visit form submission
export const submitScheduleVisit = async (visitData) => {
  const payload = {
    propertyId: visitData.propertyId, // Optionally include this if needed
    visitDate: visitData.visitDate,
    visitTime: visitData.visitTime,
    visitorName: visitData.visitorName,
    visitorEmail: visitData.visitorEmail,
    visitorContact: visitData.visitorContact,
    propertyUrl: visitData.propertyUrl, // Include propertyUrl in the payload
  };

  try {
    const response = await apiClient.post("/website/schedule-visit", payload, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("Error scheduling visit:", error);
    throw error;
  }
};
