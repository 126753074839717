import React, { useEffect, useRef, useState } from "react";

const PopertyLocationSearch = ({
  onChange,
  handleSubmitAfterSuggestionClick,
  locations = [],
  preSelectedValue = "",
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown open/close
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInputOnChange = (e) => {
    const { value } = e.target;
    onChange(value);
    setSearchTerm(value);
    setIsOpen(true);
    // if (value === "") setIsOpen(false);
  };

  const handleFocus = () => {
    if (locations.length > 0) {
      setIsOpen(true);
    }
  };

  const handleSuggestionClick = (location) => {
    setSearchTerm(location);
    setIsOpen(false);
    handleSubmitAfterSuggestionClick(location);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <input
        type="text"
        className="form-control"
        placeholder="Search various locations"
        value={preSelectedValue !== "" ? preSelectedValue : value}
        onChange={(e) => handleSearchInputOnChange(e)}
        onFocus={handleFocus}
      />
      {isOpen && (
        <div className="custom-filter-modal-wrapper">
          <div className="filter-modal-body">
            {!locations.length && <></>}
            {Array.isArray(locations) && locations.length > 0 ? (
              <ul className="locality-list">
                {locations.map((location, index) => (
                  <li
                    className="locality-list-item"
                    key={`locality-list-${index}`}
                    onClick={() => handleSuggestionClick(location)}
                  >
                    {location}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No data found</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopertyLocationSearch;
