// validation.js
export const validateContactNumber = (contactNumber) => {
  const formattedNumber = contactNumber.replace(/^\+91/, ''); // Remove +91 if present
  const isValid = /^\d{10}$/.test(formattedNumber); // Checks for exactly 10 digits
  if (!isValid) {
    return "Please enter a valid Indian 10-digit phone number.";
  }
  return "";
};


export const validateOtp = (otp) => {
  const isValid = otp.every((digit) => /^\d$/.test(digit)); // Checks if each field is a single digit
  if (!isValid) {
    return "Please enter a valid 4-digit OTP.";
  }
  return "";
};
