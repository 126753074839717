// routes.js

import Booking from "../pages/booking";
import HomePage from "../pages/home-page";
import LoginPage from "../pages/login";
import PropertyDetail from "../pages/property-details";
import PropertyListing from "../pages/property-listing";
import ConfirmBooking from "../pages/booking-confirmation";
import MyAccount from "../pages/my-account";
import MyProfile from "../components/my-account/myprofile";
import WishList from "../components/my-account/wishlist";
import AssignedProperty from "../components/my-account/assigned-property";
import BillsPayments from "../components/my-account/bills-payments";
import SupportRequest from "../components/my-account/support-request";
import Feedback from "../components/my-account/feedback";
import AboutUs from "../pages/about-us";
import FaqPage from "../pages/faq";
import PrivacyPolicy from "../pages/privacy-policy";
import TermsConditions from "../pages/terms-conditions";
import CancellationPolicy from "../pages/cancellation-policy";
import BlogPage from "../pages/blogs";
import BlogPost from "../pages/blog-post";
import ListYourProperty from "../pages/list-your-property";
import ContactUs from "../pages/contact-us";
import RegistrationPage from "../pages/registration";
import Checkout from "../pages/checkout";
import BookingFailure from "../pages/booking-failure";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import PropertyListingNavigate from "../pages/property-listing-navigate";
import PageNotFound from "../pages/page-not-found";

const routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "property/:slug",
    element: <PropertyDetail />,
  },
  {
    path: "properties",
    element: <PropertyListing />,
  },
  {
    path: "login",
    element: <PublicRoute element={<LoginPage />} />,
  },

  {
    path: "registration",
    element: <PublicRoute element={<RegistrationPage />} />,
  },
  {
    path: "/booking",
    element: <PrivateRoute element={<Booking />} />,
  },
  {
    path: "/checkout",
    element: <PrivateRoute element={<Checkout />} />,
  },
  {
    path: "/booking-confirmation",
    element: <PrivateRoute element={<ConfirmBooking />} />,
  },
  {
    path: "/booking-failure",
    element: <PrivateRoute element={<BookingFailure />} />,
  },

  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/blogs",
    element: <BlogPage />,
  },
  {
    path: "/blogs/:slug",
    element: <BlogPost />,
  },
  {
    path: "/faq",
    element: <FaqPage />,
  },
  {
    path: "/list-your-property",
    element: <ListYourProperty />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-conditions",
    element: <TermsConditions />,
  },
  {
    path: "/cancellation-policy",
    element: <CancellationPolicy />,
  },
  {
    path: "rent/:slug/:location",
    element: <PropertyListingNavigate />,
  },
  {
    path: "/my-account",
    element: <PrivateRoute element={<MyAccount />} />,
    children: [
      { path: "profile", element: <PrivateRoute element={<MyProfile />} /> },
      { path: "wishlist", element: <PrivateRoute element={<WishList />} /> },
      {
        path: "assigned-property",
        element: <PrivateRoute element={<AssignedProperty />} />,
      },
      {
        path: "bills-payments",
        element: <PrivateRoute element={<BillsPayments />} />,
      },
      {
        path: "support-request",
        element: <PrivateRoute element={<SupportRequest />} />,
      },
      { path: "feedback", element: <PrivateRoute element={<Feedback />} /> },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export default routes;
