import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import aboutBanner from "../assets/images/about-us-banner.jpg";
import aboutImage from "../assets/images/about-us-image.png";
import ownerIcon from "../assets/images/owner-icon.jpg";
import tenantIcon from "../assets/images/tenant-icon.jpg";
import { HowItWork } from "../components/home/how-it-work";
import Testimonials from "../components/home/testimonials";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us - Sowerent</title>
        <meta
          name="description"
          content="Learn more about Sowerent, your trusted partner in property management, offering comprehensive solutions for both property owners and tenants in the city."
        />
        <meta
          name="keywords"
          content="Sowerent, property management, rental solutions, leasing services, tenant support"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section className="custom-breadcrumb">
        <Container>
          <Row>
            <Col sm={12}>
              <ul className="bread-crumb-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="#">About Us</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about-us-page">
        <Container fluid className="px-0">
          <div className="about-banner__row">
            <div className="about-banner__col">
              <div className="about-banner">
                <img src={aboutBanner} alt="" />
              </div>
            </div>
            <div className="about-banner__col">
              <div className="section-heading">
                <span>Resolving a Typical Dilemma</span>
                <h2>Discovering a Rental Home in the Bustling City</h2>
                <div className="about-us-content">
                  <p>
                    At Sowerent, we take the hassle out of property management
                    by offering seamless rental and leasing solutions. Our
                    platform is designed to cater to both property owners and
                    tenants, ensuring that every aspect of property management
                    is handled efficiently. With a commitment to transparency,
                    professionalism, and reliability, we aim to redefine the
                    rental experience in today’s fast-paced market.
                  </p>
                  <p>
                    As a trusted property management company, we provide a range
                    of services that simplify the rental process—whether you’re
                    looking to lease your property or find the perfect home.
                  </p>
                  {/* <p>
                  As a trusted property management company, we provide a range
                  of services that simplify the rental process—whether you’re
                  looking to lease your property or find the perfect home. From
                  marketing your property to managing tenant relationships,
                  handling maintenance requests, and ensuring timely rent
                  collection, we are your comprehensive partner in property
                  management.
                </p> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <HowItWork className="bg-light-gray" isBuildingSectionRequired={false} />
      <section className="text-section">
        <Container>
          <Row>
            <Col lg={10} className="about-para mx-auto">
              <div className="about-para mx-auto">
                <p>
                  As a trusted property management company, sowerent Technology
                  LLPoffers a comprehensive suite of services that streamline
                  the rental process. We handle everything from marketing and
                  listing properties to screening tenants, negotiating leases,
                  and managing rental payments. Our goal is to alleviate the
                  burden on property owners by taking care of the details,
                  allowing them to focus on what matters most. On the tenant
                  side, we ensure that the search for a home is stress-free,
                  providing a wide array of listings and the tools necessary to
                  secure a property quickly and efficiently.
                </p>
                <p>
                  We also understand the importance of ongoing property
                  maintenance and support. Sowerent’s team works closely with
                  property owners to ensure that maintenance requests are
                  addressed promptly, keeping properties in optimal condition
                  and tenants satisfied. We believe in fostering positive
                  landlord-tenant relationships and making sure that every
                  issue, from minor repairs to major concerns, is handled with
                  care and attention. Our integrated maintenance system
                  simplifies communication, reducing delays and ensuring smooth
                  operations.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="community-section">
        <Container>
          <Row>
            <Col lg={11} className=" mx-auto">
              <div className="community-card">
                <div className="community__row">
                  <div className="community__col">
                    <div className="left-content">
                      <img src={aboutImage} alt="" />
                    </div>
                  </div>
                  <div className="community__col">
                    <div className="right-content">
                      <div className="section-heading">
                        <span>our community</span>
                        <h2>Over 1500 tenants across the city</h2>
                        <p>
                          As sowerent Technology LLP expanded, we saw the
                          opportunity to create a connected community for our
                          tenants, especially those new to a city. Beyond
                          providing homes, Sowerent fosters a network where
                          renters can easily connect and support each other. We
                          aim to build a sense of belonging through shared
                          interests like hiking, food festivals, and book clubs,
                          turning sowerent Technology LLP into more than just a
                          rental service—a platform for building lasting
                          relationships and community.
                        </p>
                        <p>
                          The sowerent Technology LLP community represents{" "}
                          <strong>"A home beyond your four walls."</strong> In
                          the often overwhelming experience of settling into a
                          new city, building connections can be daunting, but at
                          Sowerent, we offer a built-in network of fellow
                          renters who are ready to connect.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Testimonials />
      <section className="become-part">
        <Container>
          <Row className="row-gap-gutter">
            <Col lg={3} md={12}>
              <div className="left-content text-center text-lg-start">
                Be part of our ever growing family
              </div>
            </Col>
            <Col lg={9} md={10} className="mx-auto mx-lg-0">
              <div className="right-content">
                <div className="looking-for-wrap">
                  <div className="become-tenant">
                    <div className="icon">
                      <img src={tenantIcon} alt="" />
                    </div>
                    <div className="wrap">
                      <div className="title">Looking for Home ?</div>
                      <Link to="/properties" target="_blank">
                        Search Homes{" "}
                        <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                      </Link>
                    </div>
                  </div>
                  <div className="become-owner">
                    <div className="icon">
                      <img src={ownerIcon} alt="" />
                    </div>
                    <div className="wrap">
                      <div className="title">Are you house owner ?</div>
                      <Link to="/list-your-property" target="_blank">
                        List your Property{" "}
                        <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutUs;
