import apiClient from "./axios.config";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const submitContactForm = async (formData) => {
  try {
    const payload = {
      cName: formData.name,
      cEmail: formData.email,
      cContact: formData.phone,
      cSubject: formData.subject,
      cMessage: formData.description,
    };

    const response = await apiClient.post("/website/contact-us", payload, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error submitting contact form:", error);
    throw error; 
  }
};

// export const submitLeadRequest = async (data) => {
//   try {
//     const response = await apiClient.post("https://host601.nextwebi.com/api/LHLead/lead-request", data);
//     return response;
//   } catch (error) {
//     throw error; 
//   }
// };
