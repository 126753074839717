// utils/validateBookingForm.js

export const validateBookingForm = (formData) => {
  const errors = {};

  // Moving Date validation
  if (!formData.reqFrom) {
    errors.reqFrom = 'Moving Date is required';
  }

  // Stay Type validation
  // if (!formData['stay-type']) {
  //   errors['stay-type'] = 'Stay Type is required';
  // }

  // Duration validation
  if (!formData['sayDuration']) {
    errors['sayDuration'] = 'Duration is required';
  }

  // No. of Guests validation
  if (!formData.guests) {
    errors.guests = 'Number of Guests is required';
  }

  // Unit validation
  // if (!formData.unit) {
  //   errors.unit = 'Flat No is required';
  // }

  // Terms and Conditions validation
  if (!formData.terms) {
    errors.terms = 'You must agree to the terms and conditions';
  }
  return errors;
};
