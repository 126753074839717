import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarFilled } from "@fortawesome/free-solid-svg-icons";

const StarRating = ({ rating }) => {
  return (
    <div className="star-rating">
      {[...Array(rating)].map((_, index) => (
        <FontAwesomeIcon key={index} icon={faStarFilled} color="gold" />
      ))}
    </div>
  );
};

export default StarRating;
