import React from "react";

const PageNotFound = () => {
  return (
    <div className="error-box">
      {/* <img src="assets/img/404.png" className="img-fluid" alt="Page not found"> */}
      <h1>Oops! Page Not Found!</h1>
      <p>The page you requested was not found.</p>
      <div className="back-button">
        <a href="/" className="btn">
          Back to Home
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
