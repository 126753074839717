import React from "react";
import faqs from "../../assets/data/faqs.json";
import Accordion from "react-bootstrap/Accordion";
import faqImg from "../../assets/images/faq-img.jpg";

const FaqSection = () => {
  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="faq-img">
              <img src={faqImg} alt="" />
            </div>
          </div>
          <div className="col-lg-8">
            <div
              className="section-heading"
              data-aos="fade-down"
              data-aos-duration={2000}
            >
              <h2>Frequently Asked Questions</h2>
              <div className="sec-line">
                <span className="sec-line1" />
                <span className="sec-line2" />
              </div>
              <p>
                Get quick answers to make your sowerent Technology LLP
                experience smooth and hassle-free.
              </p>
            </div>
            <Accordion defaultActiveKey="0">
              {faqs.homePage.map((faq, index) => {
                return (
                  <Accordion.Item
                    key={index}
                    eventKey={`${index}`}
                    className="faq-card"
                  >
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body>
                      <div className="faq-info">{faq.answer}</div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
