import React from "react";
import { NavLink } from "react-router-dom";
import userIcon from "../../assets/images/icons/user-info.png";
import wishlistIcon from "../../assets/images/icons/wishlist-menu.png";
import assignedPropIcon from "../../assets/images/icons/assigned-property-menu.png";
import billsIcon from "../../assets/images/icons/bills-menu.png";
import supportMenuIcon from "../../assets/images/icons/support-menu.png";
import feedbackIcon from "../../assets/images/icons/reviews-menu.png";

const SideBar = () => {
  return (
    <div className="my-account-sidebar">
      <ul className="sidebar-list">
        <li>
          <NavLink to="profile">
            <img src={userIcon} alt="" /> My Profile
          </NavLink>
        </li>
        <li>
          <NavLink to="wishlist">
            <img src={wishlistIcon} alt="" /> Wishlist
          </NavLink>
        </li>
        <li>
          <NavLink to="assigned-property">
            <img src={assignedPropIcon} alt="" /> Assigned Property
          </NavLink>
        </li>
        <li>
          <NavLink to="bills-payments">
            <img src={billsIcon} alt="" /> Bills & Payments Info
          </NavLink>
        </li>
        <li>
          <NavLink to="support-request">
            <img src={supportMenuIcon} alt="" /> Support Request
          </NavLink>
        </li>
        <li>
          <NavLink to="feedback">
            <img src={feedbackIcon} alt="" /> Feedback
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
