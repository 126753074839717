import React from "react";
import PropertyCard from "../common/property-card";
import { Col, Row } from "react-bootstrap";
import { useUser } from "../context/UserContext";

const WishList = () => {
  const { user } = useUser();

  if (!user.isAuthenticated) {
    return <div>Please log in to view your wishlist.</div>;
  }

  return (
    <div className="wishlist-property">
      <div className="my-account-title">
        <h2>My Wishlist</h2>
      </div>
      <Row className="row-gap-gutter">
        {user.WishList.length > 0 ? (
          user.WishList.map((property) => (
            <Col lg={4} key={property.id}>
              <PropertyCard
                property={{
                  ...property,
                  gallery: property.gallery.filter(
                    (media) => media.fileType !== "Video"
                  ),
                }}
                flatId={property.flatId}
              />
            </Col>
          ))
        ) : (
          <div>No items in your wishlist.</div>
        )}
      </Row>
    </div>
  );
};

export default WishList;
