import React, { useEffect, useState } from "react";

import bgIcon1 from "../../assets/images/icons/red-circle.svg";
import bgIcon2 from "../../assets/images/icons/blue-circle.svg";
import bgIcon3 from "../../assets/images/price-bg.png";
import propertyService from "../../services/propertyService";
import PropertyCard from "../common/property-card";
import { ProgressSpinner } from "primereact/progressspinner";
import { handleErrors } from "../../utils/errorHandler";
import { ErrorHandlingDesign } from "../common/errorHandlingDesign";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Col } from "react-bootstrap";

const FeaturedProperties = () => {
  const [propertyList, setPropertyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Update on window resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Function to fetch property listing
    const fetchProperties = async () => {
      setLoading(true);
      try {
        const response = await propertyService.getFeaturedProperty();
        setPropertyList(response.data.result || []);
      } catch (error) {
        const errorMessage = handleErrors(error);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  return (
    <section className="feature-property-sec">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Featuring Selected Properties for Rent</h2>
          <div className="sec-line">
            <span className="sec-line1" />
            <span className="sec-line2" />
          </div>
        </div>
        <div className="row row-gap-gutter">
          {error ? (
            <ErrorHandlingDesign errorMessage={error} />
          ) : loading ? (
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="3"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          ) : isMobile ? (
            <Col className="feature-slider">
              <Swiper
                slidesPerView={1.3}
                modules={[Pagination]}
                spaceBetween={24}
                pagination={{
                  clickable: true,
                }}
              >
                {propertyList.map((property) => (
                  <SwiperSlide key={property.id}>
                    <PropertyCard 
                      property={{ ...property, gallery: property.gallery.filter((media) => media.fileType !== "Video") }} 
                      flatId={property.id} 
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          ) : (
            propertyList.map((property) => (
              <div
                className="col-12 col-md-4 col-lg-3 col-sm-6"
                key={property.id}
              >
                <PropertyCard 
                  property={{ ...property, gallery: property.gallery.filter((media) => media.fileType !== "Video") }} 
                  flatId={property.id} 
                />
              </div>
            ))
          )}
        </div>
      </div>
      <div className="bg-imgs">
        <img src={bgIcon3} className="bg-01" alt="" />
        <img src={bgIcon2} className="bg-02" alt="" />
        <img src={bgIcon1} className="bg-03" alt="" />
      </div>
    </section>
  );
};

export default FeaturedProperties;
