import { faCheckCircle, faInr } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import bookingService from "../services/bookingService";
import { handleErrors } from "../utils/errorHandler";
import { ErrorHandlingDesign } from "../components/common/errorHandlingDesign";
import { Helmet } from "react-helmet-async";
import { usePrint } from "print-react-component";
import Logo from "../../src/assets/images/sowerent-logo-blue.png";
import jsPDF from "jspdf"; // Import jsPDF
import html2pdf from "html2pdf.js";
const ConfirmBooking = () => {
  const [basicDetails, setBasicDetails] = useState([]);
  const [error, setErrors] = useState(null);
  const location = useLocation();
  const { orderId } = location.state || {};
  const { paymentId } = location.state || {};
  const { holder } = usePrint();

  useEffect(() => {
    const fetchBasicDetails = async () => {
      try {
        const basicDetails = await bookingService.getBookingBasicDetails(
          orderId,
          paymentId
        );
        setBasicDetails(basicDetails.data.result);
      } catch (error) {
        const errorMessage = handleErrors(error);
        setErrors(errorMessage);
      }
    };

    fetchBasicDetails();
  }, [orderId, paymentId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  const todayDate = new Date();
  // Function to generate and download PDF
  const downloadPDF = () => {
    const doc = new jsPDF();
    const content = `
      <div class="main-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="">
              <div class="settings-widget profile-details mar-top-20">
                <div class="settings-menu invoice-list-blk p-0">
                  <div class="card pro-post border-0 mb-0">
                    <div class="card-body">
                      <div class="invoice-item">
                        <div class="wrapper-payment" style="width: 100%">
                          <div class="col-md-6" style="width: 30%">
                            <div class="invoice-logo">
                              <img src="${Logo}" class="img-fluid" alt="Logo" />
                            </div>
                          </div>
                          <div class="col-md-6" style="width: 50%">
                            <p class="invoice-details">
                              <strong>#238/239, 2nd floor, Raheja Arcade, Koramangala, Bangalore, Karnataka-560095</strong>
                              <br />
                              <strong>Email:</strong> helpdesk@sowerent.com
                              <br />
                              <strong>Call Us At:</strong> +91 8867319933
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="invoice-item">
                        <div class="row" style="width: 100%">
                          <div class="col-md-12">
                            <div class="bg-light-gray-2 p-2 mb-3">
                            
                            <span><strong>Booking Id:</strong>${
                              basicDetails.orderId
                            }</span>
                            <br />
                            <span><strong>Booking Date:</strong> ${formatDate(
                              todayDate
                            )}</span>
                            <br />
                              <span><strong>Status:</strong> Paid</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="invoice-item">
                        <div class="row" style="width: 100%">
                          <div class="col-md-12">
                            <div class="bg-light-gray-2 p-2 mb-3">
                              <h5>Billing Info</h5>
                              <p>
                                <strong>Name:</strong> ${basicDetails.userName}
                                <br />
                                <strong>Email:</strong> ${
                                  basicDetails.emailAddress
                                }
                                <br />
                                <strong>Phone:</strong> ${
                                  basicDetails.contactNo
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="invoice-item invoice-table-wrap">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="table-responsive">
                              <table class="invoice-table table table-bordered">
                                <thead>
                                  <tr>
                                  <th>Flat No</th>
                                    <th>Property Name</th>
                                    <th>Payment ID</th>
                                    <th>Period</th>
                                    <th class="text-center">Paid on</th>
                                    <th class="text-end">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                  <td>${basicDetails.flatNo}</td>
                                    <td>${basicDetails.propertyName}</td>
                                    <td style="color : green;">${paymentId}</td>
                                    <td>${formatDate(
                                      basicDetails.moveInDate
                                    )} - ${formatDate(
      basicDetails.moveOutDate
    )}</td>
                                    <td class="text-center">${formatDate(
                                      todayDate
                                    )}</td>
                                    <td class="text-end">₹ ${
                                      basicDetails.totalAmount
                                    }</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

    // Use html2pdf.js to generate the PDF
    const element = document.createElement("div");
    element.innerHTML = content;
    html2pdf().from(element).save("booking_receipt.pdf");
  };

  return (
    <>
      <Helmet>
        <title>Booking Confirmation | Sowerent</title>
        <meta
          name="description"
          content="Thank you for your booking. View and confirm your booking details with Sowerent."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <section className="section-padding booking-confirmation">
        {error ? (
          <ErrorHandlingDesign errorMessage={error} />
        ) : (
          <Container className="booking-container">
            <Row>
              <Col xs={12}>
                <div className="booking-confirmation-card">
                  {holder}
                  <div className="title">
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <h1>Booking Confirmed</h1>
                  </div>
                  <div className="bookig-details">
                    <h2 className="inner-title">Booking Details</h2>
                    <div className="bookig-details-grid">
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Booking Number</span>
                          <div className="bookig-detail">
                            {basicDetails.orderId}
                          </div>
                        </div>
                      </div>
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Booking Date</span>
                          <div className="bookig-detail">
                            {formatDate(todayDate)}
                          </div>
                        </div>
                      </div>
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Duration</span>
                          <div className="bookig-detail">
                            {formatDate(basicDetails.moveInDate)} -{" "}
                            {formatDate(basicDetails.moveOutDate)}
                          </div>
                        </div>
                      </div>
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Email</span>
                          <div className="bookig-detail">
                            {basicDetails.emailAddress}
                          </div>
                        </div>
                      </div>
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Phone</span>
                          <div className="bookig-detail">
                            {basicDetails.contactNo}
                          </div>
                        </div>
                      </div>
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Property Name</span>
                          <div className="bookig-detail">
                            {basicDetails.propertyName}
                          </div>
                        </div>
                      </div>
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Property Address</span>
                          <div className="bookig-detail">
                            {basicDetails.propertyAddress}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="payment-details">
                    <h2 className="inner-title">Payment Details</h2>
                    <div className="bookig-details-grid">
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Total Amount</span>
                          <div className="bookig-detail">
                            <FontAwesomeIcon icon={faInr} />
                            {basicDetails.totalAmount}
                          </div>
                        </div>
                      </div>
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Paid Amount</span>
                          <div className="bookig-detail">
                            <FontAwesomeIcon icon={faInr} />{" "}
                            {basicDetails.paidAmount}
                          </div>
                        </div>
                      </div>
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Remaining Amount</span>
                          <div className="bookig-detail">
                            <FontAwesomeIcon icon={faInr} />{" "}
                            {basicDetails.balanceAmount}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="bookig-details-item">
                        <div className="content-wrap">
                          <span>Payment Status</span>
                          <div className="bookig-detail">
                            {basicDetails.orderStatus}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="download-invoice">
                    <Button className="download-btn" onClick={downloadPDF}>
                      Download PDF
                    </Button>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="dashboard-link">
                  <Link to={"/my-account/profile"}>
                    <Button>Go to Dashboard</Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </>
  );
};

export default ConfirmBooking;
