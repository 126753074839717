// /utils/validation.js

export const validateFirstName = (firstName) => {
  if (!firstName) return "First name is required.";
  if (firstName.length < 1 || firstName.length > 50)
    return "First name must be between 1 and 50 characters.";
  return null;
};

export const validateLastName = (lastName) => {
  if (!lastName) return "Last name is required.";
  if (lastName.length < 1 || lastName.length > 50)
    return "Last name must be between 1 and 50 characters.";
  return null;
};

export const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
  if (!email) return "Email address is required.";
  if (email.length < 1 || email.length > 50)
    return "Email must be between 1 and 50 characters.";
  if (!emailPattern.test(email)) return "Email address is invalid.";
  return null;
};

export const validatePhoneNo = (phoneNo) => {
  const phonePattern = /^[0-9]*$/; // Ensure only digits are allowed
  if (!phoneNo) return "Phone number is required.";
  
  if (phoneNo.length !== 10) // Ensure phone number is exactly 10 digits
    return "Please enter a valid Indian 10 digit phone number.";

  if (!phonePattern.test(phoneNo))
    return "Phone number is invalid. Only digits are allowed.";
    
  return null;
};

