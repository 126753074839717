import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CRM_API_URL,
});

const headers = {
  accept: "*/*",
  "Content-Type": "multipart/form-data",
};
export const getLeadRequestDetails = async (formData) => {
  const response = await axiosInstance.post("LHLead/lead-request", formData, {
    headers,
  });
  return response.data;
};
