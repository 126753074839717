import React, { useEffect, useState } from "react";
import LineBg from "../../assets/images/line-bg.png";
import { Link } from "react-router-dom";
import propertyService from "../../services/propertyService"; // import your property service

const Footer = ({}) => {
  const [locations, setLocations] = useState([]);
  const [bhkTypes, setBhkTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  // const location = 'platinum-city';
  // const city = 'bangalore';

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch locations and bhk types in parallel
        const [locationResponse, bhkResponse] = await Promise.all([
          propertyService.getPropertyLocations(),
          propertyService.getBhkTypes(),
        ]);
        setLocations(locationResponse.data.result);
        setBhkTypes(bhkResponse.data.result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-border-img">
          <img src={LineBg} alt="Footer Line" />
        </div>
        <div className="container">
          <div className="row row-gap-gutter">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="footer-widget-list">
                <div className="footer-content-heading">
                  <h4>Rooms for Rent in Bangalore</h4>
                </div>
                <ul>
                  {locations.slice(0, 9).map((location, index) => (
                    <li key={index}>
                      <Link
                        target="_blank"
                        to={`rent/room-for-rent-in-${location.cityName.toLowerCase()}/${location.locationName
                          .toLowerCase()
                          .replace(/\s+/g, "-")}`}
                      >
                        {`Room for rent in ${location.locationName}`}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="footer-widget-list">
                <div className="footer-content-heading">
                  <h4>Flats for Rent in Bangalore</h4>
                </div>
                <ul>
                  {locations.slice(9, 18).map((location, index) => (
                    <li key={index}>
                      <Link
                        target="_blank"
                        to={`rent/flats-for-rent-in-${location.cityName.toLowerCase()}/${location.locationName
                          .toLowerCase()
                          .replace(/\s+/g, "-")}`}
                      >
                        {`Flats for rent in ${location.locationName}`}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="footer-widget-list">
                <div className="footer-content-heading">
                  <h4>No brokerage flats for rent</h4>
                </div>
                <ul>
                  {locations.slice(0, 9).map((location, index) => (
                    <li key={index}>
                      <Link
                        target="_blank"
                        to={`rent/no-brokerage-flats-for-rent-in-${location.cityName.toLowerCase()}/${location.locationName
                          .toLowerCase()
                          .replace(/\s+/g, "-")}`}
                      >
                        {`No brokerage flats for rent in ${location.locationName}`}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ">
              <div className="footer-widget-list">
                <div className="footer-content-heading">
                  <h4>No commission flats for rent</h4>
                </div>
                <ul>
                  {locations.slice(9, 18).map((location, index) => (
                    <li key={index}>
                      <Link
                        target="_blank"
                        to={`rent/no-commission-flats-for-rent-in-${location.cityName.toLowerCase()}/${location.locationName
                          .toLowerCase()
                          .replace(/\s+/g, "-")}`}
                      >
                        {`No commission flats for rent in  ${location.locationName}`}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6  col-6 tab-show footer-tab-about footer-main-links">
              <div className="footer-widget-list">
                <div className="footer-content-heading">
                  <h4>About</h4>
                </div>
                <ul>
                  <li>
                    <Link to="about-us">About</Link>
                  </li>
                  <li>
                    <Link to="blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link to="faq">Faq</Link>
                  </li>
                  <li>
                    <Link to="terms-conditions">Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link to="privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="cancellation-policy">Cancellation Policy</Link>
                  </li>
                  <li>
                    <Link to="contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-content">
            <div className="copyright">
              <p>
                Copyright 2024 - All right reserved SoWeRent || Developed by{" "}
                <a
                  className="orange-link"
                  href="https://www.nextwebi.com/"
                  target="_blank"
                >
                  Nextwebi
                </a>
              </p>
            </div>
            <div className="right-content">
              <ul>
                {/* <li>
                  <Link to="about-us">About</Link>
                </li>
                <li>
                  <Link to="blogs">Blogs</Link>
                </li>
                <li>
                  <Link to="faq">Faq</Link>
                </li> */}
                <li>
                  <Link to="terms-conditions">Terms &amp; Conditions</Link>
                </li>
                <li>
                  <Link to="privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="cancellation-policy">Cancellation Policy</Link>
                </li>
                {/* <li>
                  <Link to="contact-us">Contact Us</Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
