import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
const CustomDropdown = ({
  title,
  items = [],
  onSelect,
  isSearchEnabled = true,
  filtertype,
  selectOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState(items.slice(0, 10));
  const [filteringtype, setFilteringType] = useState('');
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    if (filtertype == 'bhktype') {
      setFilteringType(selectOptions.bhkType);
    } else if(filtertype == 'bugetlist'){
      setFilteringType(selectOptions.budget);
    }
  })


  const handleSelect = (item) => {
    onSelect(title, item);
    setIsOpen(false);
  };

  return (
    <div className={`custom-dropdown ${isOpen ? "open-modal" : ""}`}>
      <button className="custom-dropdown-title" onClick={toggleDropdown}>
        <span>{title}</span>
        <FontAwesomeIcon icon={faChevronDown} />
      </button>

      {isOpen && (
        <div className="custom-filter-modal-wrapper">
          <div className="filter-body-overlay" onClick={toggleDropdown}></div>
          <div className="filter-modal-body">
            {/* Show search input only if enabled */}
            {isSearchEnabled && (
              <div className="filter-search">
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder={`Search ${title}`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Form.Group>
              </div>
            )}
            <div className="locality-list">
              {items.length > 0 ? (
                items.map((item, index) => (
                  <div
                    key={index}
                    className={`locality-list-item ${item === filteringtype ? 'disabled' : ''}`}
                    onClick={item === filteringtype ? null : () => handleSelect(item)}
                    style={item === filteringtype ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                  >
                    {item}
                  </div>
                ))
              ) : (
                <div className="no-results">No results found.</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomDropdown;
