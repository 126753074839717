import React, { useEffect, useState } from "react";
import RentPropImg from "../../assets/images/icons/rent-icon.svg";
import { Search } from "react-feather";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import PopertyLocationSearch from "../common/property-location-search";
import propertyService from "../../services/propertyService";

const Banner = () => {
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    stayType: 0,
    propertyType: 0,
    location: "",
  });

  const [bhkTypes, setBhkTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [locations, setLocations] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(true);
  const [bhkError, setBhkError] = useState(null);
  const [locationError, setLocationError] = useState(null);

  // Fetch BHK types from the service
  useEffect(() => {
    const fetchBhkTypes = async () => {
      try {
        const response = await propertyService.getBhkTypes();

        if (response.data.isSuccess) {
          setBhkTypes(response.data.result);
        } else {
          setBhkError("No BHK Type Found");
        }
      } catch (error) {
        setBhkError("No BHK Type Found");
      } finally {
        setLoading(false);
      }
    };

    fetchBhkTypes();
  }, []);

  useEffect(() => {
    const fetchAllLocations = async () => {
      try {
        const response = await propertyService.getPropertyLocations();
        const allLocations = response.data?.result || [];
        if (Array.isArray(allLocations)) {
          setAllLocations(allLocations.map((item) => item.locationName));
          setLocations(allLocations.map((item) => item.locationName));
        } else {
          setLocations([]);
        }
      } catch (error) {
        setLocations([]);
        setLocationError("Error fetching locations.");
      }
    };

    fetchAllLocations();
  }, []);

  const fetchLocationSuggestions = async (value) => {
    if (value.trim() === "") {
      setLocations([]);
      return;
    }
    setIsLoading(true);
    const payload = {
      locName: value,
    };
    try {
      const response = await propertyService.getLocationSuggestions(payload);
      const locationsres = response.data.result || [];
      setLocations(locationsres.map((item) => item.locationName));
    } catch (error) {
      setLocationError("No Locations Found");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formValues.location) {
      fetchLocationSuggestions(formValues.location);
    }
  }, [formValues.location]);

  const handleInputChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      location: value,
    }));
    if (!value) {
      setLocations((prevValues) => [...allLocations]);
    }
  };

  const handleSubmit = () => {
    // e.preventDefault();
    // const validationErrors = validateBannerForm(formValues);
    // setErrors(validationErrors);
    const selectedPropertyType =
      bhkTypes.find((type) => type.bhkType === formValues.propertyType)
        ?.bhkType || "";
    let qryParam = {};
    if (formValues.location !== "" && selectedPropertyType !== "") {
      qryParam = {
        location: formValues.location,
        bhkType: selectedPropertyType,
      };
      const queryParams = new URLSearchParams(qryParam).toString();
      navigate(`/properties?${queryParams}`);
    } else if (selectedPropertyType !== "") {
      qryParam = {
        bhkType: selectedPropertyType,
      };

      const queryParams = new URLSearchParams(qryParam).toString();
      navigate(`/properties?${queryParams}`);
    } else if (formValues.location !== "") {
      qryParam = {
        location: formValues.location,
      };
      const queryParams = new URLSearchParams(qryParam).toString();
      navigate(`/properties?${queryParams}`);
    } else {
      navigate(`/properties`);
    }
  };

  const handleSuggestionClick = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      location: value,
    }));
    setSelectedOption(false);
  };

  return (
    <section className="banner-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-10">
            <div className="banner-content" data-aos="fade-down">
              <h1>Trusted Rentals for Long and Short Stay</h1>
              <span> No Brokerage, No Commission</span>
              <p>
                Choose from over 1000+ fully managed flats at affordable prices!
              </p>
              {/* <div className="banner-map">
                <img src={banner} alt="" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-8 col-lg-10">
            <div className="banner-search" data-aos="fade-down">
              <div className="banner-tab">
                <ul className="nav nav-tabs" id="bannerTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="rent-property"
                      data-bs-toggle="tab"
                      href="#rent_property"
                      role="tab"
                      aria-controls="rent_property"
                      aria-selected="false"
                    >
                      <img src={RentPropImg} alt="" />
                      Properties for Rent
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="bannerTabContent">
                <div
                  className="tab-pane fade show active"
                  id="rent_property"
                  role="tabpanel"
                  aria-labelledby="rent-property"
                >
                  <div className="banner-tab-property">
                    <div className="banner-property-info">
                      <div className="banner-property-grid">
                        <div className="select-wrap">
                          <span className="drop-down-arrow"></span>
                          <Form.Select
                            className="select"
                            name="stayType"
                            value={formValues.stayType}
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                stayType: e.target.value,
                              });
                            }}
                          >
                            <option value={0}>Stay Type</option>
                            <option value={2}>Short Term</option>
                            <option value={3}>Long Term</option>
                          </Form.Select>
                          {errors.stayType && (
                            <p className="error-text">{errors.stayType}</p>
                          )}
                        </div>
                      </div>
                      <div className="banner-property-grid">
                        {error ? (
                          <div className="col-12 text-center">
                            <h5 className="custom-error">{error}</h5>
                          </div>
                        ) : (
                          <div className="select-wrap">
                            <span className="drop-down-arrow"></span>
                            <Form.Select
                              className="select"
                              name="propertyType"
                              value={formValues.propertyType}
                              onChange={(e) => {
                                setFormValues({
                                  ...formValues,
                                  propertyType: e.target.value,
                                });
                              }}
                            >
                              <option value={0}>Property Type</option>
                              {bhkError ? (
                                <option>{bhkError}</option>
                              ) : (
                                bhkTypes.map((bhk, index) => (
                                  <option key={index} value={bhk.bhkType}>
                                    {bhk.bhkType}
                                  </option>
                                ))
                              )}
                            </Form.Select>
                            {errors.propertyType && (
                              <p className="error-text">
                                {errors.propertyType}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="banner-property-grid">
                        <PopertyLocationSearch
                          value={formValues.location}
                          onChange={handleInputChange}
                          handleSubmitAfterSuggestionClick={
                            handleSuggestionClick
                          }
                          locations={locationError ? locationError : locations}
                        />
                        {errors.location && (
                          <p className="error-text">{errors.location}</p>
                        )}
                      </div>
                      <div className="banner-property-grid">
                        <button
                          // type="submit"
                          className="btn-primary"
                          onClick={handleSubmit}
                        >
                          <Search />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
