import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TermsCondition from "../components/common/terms-condition";
const TermsConditions = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Sowerent</title>
        <meta
          name="description"
          content="Read the Terms and Conditions for using sowerent Technology LLP platform and services."
        />
      </Helmet>

      <section className="privacy-policy-section">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <div className="section-heading">
                <h1>Terms & Conditions</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={3} lg={4}>
              <div className="table-of-content fancy-scroll">
                <h2>Table of Content</h2>
                <ul>
                  <li>
                    <a href="#introduction">Introduction</a>
                  </li>
                  <li>
                    <a href="#terms">Terms</a>
                  </li>
                  <li>
                    <a href="#content-and-use">Content and use</a>
                  </li>
                  <li>
                    <a href="#eligibility">Eligibility</a>
                  </li>
                  <li>
                    <a href="#disclaimer-and-limitation-of-liability-of-content">
                      Disclaimer and limitation of liability of content
                    </a>
                  </li>
                  <li>
                    <a href="#liability-of-links">Liability of links</a>
                  </li>
                  <li>
                    <a href="#governing-Law">Governing Law</a>
                  </li>
                  <li>
                    <a href="#entire-agreement">Entire agreement</a>
                  </li>
                  <li>
                    <a href="#updates-to-these-terms">Updates to these Terms</a>
                  </li>
                  <li>
                    <a href="#how-to-contact-us">How to contact us</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={9} lg={8}>
              <TermsCondition />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TermsConditions;
