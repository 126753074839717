import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";

const FileDropper = ({ onFileSelect }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null); // Create a ref for the file input

  // Handle file select
  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    onFileSelect([...selectedFiles, ...files]); // Pass all files to the parent
  };
  // Handle file drop
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    onFileSelect([...selectedFiles, ...files]); // Pass all files to the parent
    setIsDragging(false);
  };

  // Drag over event
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  // Drag leave event
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  // Remove selected file
  const removeFile = (fileToRemove) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
    onFileSelect(updatedFiles); // Update the parent with new list
  };


  // Trigger file input click
  const handleLabelClick = () => {
    fileInputRef.current.click(); // Programmatically click the file input
  };

  return (
    <Form.Group controlId="attachment" className="form-group">
      <Form.Label>Attachments (if any)</Form.Label>
      <div
        className={`file-dropper ${isDragging ? "dragging" : ""}`} 
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <Form.Control
          type="file"
          name="attachment"
          onChange={handleFileSelect}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <label className="file-dropper-label" onClick={handleLabelClick}>
          {selectedFiles.length === 0 ? (
            <span>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add or Drop Files Here
            </span>
          ) : (
            <span>{selectedFiles.length} file(s) selected</span>
          )}
        </label>
      </div>
      {selectedFiles.length > 0 && (
        <div className="file-preview">
          {selectedFiles.map((file, index) => (
            <div key={index} className="file-thumbnail">
              {file.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  className="thumbnail-image"
                />
              ) : (
                <div className="file-icon">
                  <span>{file.name}</span>
                </div>
              )}
              <Button
                size="sm"
                onClick={() => removeFile(file)}
                className="remove-button"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </div>
          ))}
        </div>
      )}
    </Form.Group>
  );
};

export default FileDropper;
