import React, { useEffect, useState } from "react";
import FeedbackService from "../../services/feedback-service";
import { Card } from "react-bootstrap";
import excellentIcon from "../../assets/images/excellent.png";
import goodIcon from "../../assets/images/good.png";
import averageIcon from "../../assets/images/average.png";
import badIcon from "../../assets/images/bad.png";
import veryBadIcon from "../../assets/images/very-bad.png";
import StarRating from "../common/StarRating"; // Import the StarRating component if using star ratings
import { ProgressSpinner } from "primereact/progressspinner";
import { handleErrors } from "../../utils/errorHandler";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const FeedBack = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [expandedFeedback, setExpandedFeedback] = useState(null); // Track the expanded feedback ID
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await FeedbackService.getFeedbackByCustomer();
        if (response.data) {
          setFeedbacks(response.data.result);
        }
      } catch (error) {
        const errorMessage = handleErrors(error);
        toast.error(errorMessage);

      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
    fetchFeedbacks();
  }, []);

  // Define a mapping for rating to icon image
  const ratingImageMap = {
    5: excellentIcon,
    4: goodIcon,
    3: averageIcon,
    2: badIcon,
    1: veryBadIcon,
  };

  const formatDated = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options).replace(/ /g, '-');
  };
  // const [isExpanded, setIsExpanded] = useState(false); // Track expanded state

  // Handle toggle for Read More / Read Less
  const toggleExpand = (feedBackGuid) => {
    setExpandedFeedback((prev) =>
      prev === feedBackGuid ? null : feedBackGuid
    ); // Toggle the expanded feedback ID
    
  
  };

  return (
    <div className="feedback-wrapper">
      <div className="my-account-title">
        <h2>Feedback</h2>
      </div>
      <div className="feedback-list">
        {loading ? ( // Check if loading
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        ) : feedbacks.length === 0 ? ( // Check if feedbacks are empty
          <div className="no-feedback-message">
            <h5 className="no-data-found">No Feedback</h5>
          </div>
        ) : (
          feedbacks.map((feedback) => (
            <Card key={feedback.feedBackGuid} className="mb-1">
              <Card.Body>
                <div className="feedback-icon-wrraper">
                  <div>
                    {ratingImageMap[feedback.rating] && (
                      <div className="feedback-icon">
                        <img
                          src={ratingImageMap[feedback.rating]}
                          alt={`Rating ${feedback.rating}`}
                          width={60}
                          height={60}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <div>
                      <StarRating rating={feedback.rating} />
                    </div>
                  </div>
                </div>
                <Card.Text className="mt-2">
                  {expandedFeedback === feedback.feedBackGuid
                    ? feedback.comments
                    : feedback.comments.length > 30
                    ? `${feedback.comments.substring(0, 100)}...`
                    : feedback.comments}
                  {feedback.comments.length > 30 && (
                    <span
                      className="read-more"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleExpand(feedback.feedBackGuid)}
                    >
                      {expandedFeedback === feedback.feedBackGuid
                        ? " Read Less"
                        : " Read More"}
                    </span>
                  )}
                </Card.Text>
                <Card.Text>
                  <strong>Date:</strong> {formatDated(feedback.createdOn)}
                </Card.Text>
              </Card.Body>
            </Card>
          ))
        )}
      </div>
    </div>
  );
};

export default FeedBack;
