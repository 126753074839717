import apiClient from "./axios.config";

const assignedService = {
  getAssignedService() {
    return apiClient.get("/customer/assigned-prop/details");
  },
  getAssignedRenewal() {
    return apiClient.get("/customer/assigned-prop/renewal-status");
  },
  getAgreementById(documentId) {
    return apiClient.get(`/customer/assigned-prop/agreement/${documentId}`);
  },
  getEsignPreview() {
    return apiClient.post("/customer/assigned-prop/esign-preview");
  },
  sendForEsign(agreementId) {
    return apiClient.post(
      `/customer/assigned-prop/esign-send?agreementId=${agreementId}`
    );
  },
  checkSignStatus(agreementId) {
    return apiClient.get(
      `/customer/assigned-prop/esign-status?agreementId=${agreementId}`
    );
  },
  extendAgreement(noOfMonths, orderGuid) {
    return apiClient.post(
      `/customer/order/extend?noOfMonths=${noOfMonths}&orderGuid=${orderGuid}`
    );
  },
};

export default assignedService;
