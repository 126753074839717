import apiClient from "./axios.config";

const propertyService = {
  getPropertyListing(payload) {
    return apiClient.post("/website/property/listings", payload);
  },

  getFeaturedProperty() {
    return apiClient.get("/website/property/featured");
  },

  getPropertyLocations() {
    return apiClient.get("/website/property/locations");
  },

  getPropertyDetail(payload) {
    return apiClient.get(
      `/website/property-detail?flatUrl=${payload.flatUrl}&flatId=${payload.flatId}`
    );
  },

  getLocationSuggestions(payload) {
    return apiClient.get(
      `/website/property/locations/autocomplete?locName=${payload.locName}`
    );
  },
  getBhkTypes() {
    return apiClient.get("/website/property/bhk-types");
  },
};

export default propertyService;
