// src/validations/witnessValidation.js

import { isValidPhoneNumber } from "react-phone-number-input";

export const validateWitnessForm = (formData) => {
  const errors = {};

  // Name validation: required, maxLength 50
  if (!formData.name || formData.name.length > 50) {
    errors.name = "Name is required ";
  }

  // Email validation: required, maxLength 100, and valid email format
  if (!formData.email || formData.email.length > 100) {
    errors.email = "Email is required .";
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = "Email is invalid.";
  }



  // if (!formData.phoneNumber || formData.phoneNumber.trim() === "") {
  //   errors.phoneNumber = "Phone number is required";
  // } else {
  
  //   const phoneNumber = formData.phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
  //   if (phoneNumber.length !== 12) {
  //     errors.phoneNumber = "Phone number must be 10 digits";
  //   }
  // }

  if (!formData.phoneNumber) {
    errors.phoneNumber = "Phone  number is required.";
  } else if (!isValidPhoneNumber(formData.phoneNumber)) {
    errors.phoneNumber = "Please enter a valid phone number.";
  }

  // Aadhar Number validation: required, exactly 12 digits
  if (!formData.aadharNumber || formData.aadharNumber.length !== 12) {
    errors.aadharNumber = "Aadhar number must be exactly 12 digits.";
  }

  return errors;
};
