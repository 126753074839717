import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { validatePersonalInfo } from "../../utils/validation/validate-personal-info";
import personalInfoServices from "../../services/profile-personal-info";
// import { Calendar } from "primereact/calendar";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"; // Add this line
import { handleErrors } from "../../utils/errorHandler";
import Select from "react-select"; // Import React-Select
import countryList from "react-select-country-list";
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
const PersonalInfo = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    fatherName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    dob: "",
    nationality: "",
    pincode: "",
    altEmail: "",
    altPhoneNo: "",
    city: "",
    state: "",
    country: "",
    streetAddress: "",
    whatsApp: "",
  });
  const { updateUserProfile, user } = useUser();
  const [verificationStatus, setVerificationStatus] = useState({
    email: "",
    phone: "",
    whatsApp: "",
  });

  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalInputName, setModalInputName] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [enteredValue, setEnteredValue] = useState("");
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [isPhoneInput, setIsPhoneInput] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const today = new Date();
  const options = countryList().getData();

  async function fetchProfileData() {
    try {
      const response = await personalInfoServices.getProfileInfo();
      const data = response.data.result;
      const convertDateToDisplayFormat = (date) => {
        if (date) {
          const [day, month, year] = date.split("-");
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          const monthIndex = monthNames.indexOf(month);
          if (monthIndex >= 0) {
            return `${String(day).padStart(2, "0")}/${String(
              monthIndex + 1
            ).padStart(2, "0")}/${year}`;
          }
        }
        return "";
      };
      setFormData({
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        fatherName: data.fatherName || "",
        email: data.emailAddress || formData.email || "",
        phoneNumber: data.phoneNo
          ? data.phoneNo.startsWith("+")
            ? data.phoneNo
            : `+91${data.phoneNo}`
          : "",
        gender: data.gender || "",
        dob: convertDateToDisplayFormat(data.dob) || "",
        nationality: data.nationality || "",
        // whatsApp: data.whatsApp
        //   ? data.whatsApp.startsWith("+")
        //     ? data.whatsApp
        //     : `+${data.whatsApp}`
        //   : "",
        whatsApp: data.whatsApp || "",
        pincode: data.pincode || "",
        altEmail: data.altEmail || "",
        altPhoneNo: data.altPhoneNo || "",
        city: data.city || "",
        state: data.state || "",
        country: data.country || "",
        streetAddress: data.streetAddress || "",
      });
      setVerificationStatus({
        email: data.emailStatus,
        phone: data.phoneStatus,
        whatsApp: data.whatsAppStatus,
      });
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    }
  }
  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleInputChange = (e, selectedOption) => {
    if (e) {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (selectedOption) {
      setFormData({
        ...formData,
        country: selectedOption.label,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Automatically insert `/` after day or month
    let formattedValue = value;
    if (/^\d{2}$/.test(value)) {
      // Add `/` after entering two digits for the day
      formattedValue = `${value}/`;
    } else if (/^\d{2}\/\d{2}$/.test(value)) {
      // Add `/` after entering two digits for the month
      formattedValue = `${value}/`;
    }

    setFormData({ ...formData, [name]: formattedValue });

    const ddmmyyyyPattern =
      /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d$/;
    if (ddmmyyyyPattern.test(formattedValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: "",
      }));
    } else if (formattedValue.length === 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: "Invalid date format. Use dd/mm/yyyy.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: "",
      }));
    }
  };

  const validateDateFormat = (date) => {
    const ddmmyyyyPattern =
      /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d$/;
    const ddmmmyyyyPattern =
      /^(0[1-9]|[12][0-9]|3[01])-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/;
    return ddmmyyyyPattern.test(date) || ddmmmyyyyPattern.test(date);
  };
  const handlePhoneChange = (value, key) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value || "",
    }));
  };

  const handleSubmit = async () => {
    const formErrors = validatePersonalInfo(formData);
    setErrors(formErrors);

    if (!formData.dob) {
      formErrors.dob = "Date of Birth is required.";
    } else if (!validateDateFormat(formData.dob)) {
      formErrors.dob = "Invalid date format. Use dd/mm/yyyy";
    } else {
      const [day, month, year] = formData.dob.split("/");
      const dob = new Date(`${year}-${month}-${day}`);
      const today = new Date();
      const age = today.getFullYear() - dob.getFullYear();
      const monthDifference = today.getMonth() - dob.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < dob.getDate())
      ) {
      }

      if (age < 18) {
        formErrors.dob = "You must be at least 18 years old.";
      }
    }
    if (Object.keys(formErrors).length === 0) {
      try {
        setIsSaving(true);
        setIsButtonDisabled(true);
        const dob = formData.dob;
        const [day, month, year] = dob.split("/");

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const formattedDob = `${String(day).padStart(2, "0")}-${
          monthNames[parseInt(month, 10) - 1]
        }-${year}`;
        const payload = { ...formData, dob: formattedDob };
        await updateUserProfile(payload);
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response) {
          if (error.response.status === 500) {
            errorMessage = error.response.data.message;
          }
        }
        toast.error(errorMessage);
      } finally {
        setIsSaving(false);
        setIsButtonDisabled(false);
      }
    }
  };

  const handleInputClick = (inputName) => {
    setModalInputName(inputName);
    const inputValue =
      inputName === "Email" ? formData.email : formData.whatsApp; // Use formData.whatsApp

    setEnteredValue(inputValue || ""); // Set entered value to current WhatsApp number or empty
    setShowModal(true);
    setOtpSent(false);
    setOtp(new Array(4).fill("")); // Reset OTP
    setIsPhoneInput(inputName === "WhatsApp Number");
  };

  const handleSendOtp = async () => {
    setIsSendingOtp(true);
    try {
      if (modalInputName === "Email") {
        await personalInfoServices.sendEmailOtp(enteredValue);
      } else if (modalInputName === "WhatsApp Number") {
        // Ensure you use enteredValue here
        await personalInfoServices.sendWhatsAppOtp(enteredValue); // Use enteredValue instead of formData.whatsApp
      }
      setOtpSent(true);
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response) {
        if (error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
      }
      toast.error(errorMessage);
    } finally {
      setIsSendingOtp(false);
    }
  };

  const handleVerifyOtp = async () => {
    const otpString = otp.join("");
    setIsVerifyingOtp(true); // Start loading
    try {
      let response;
      if (modalInputName === "Email") {
        response = await personalInfoServices.verifyEmailOtp(otpString);
        if (response && response.data.result) {
          setFormData((prevData) => ({
            ...prevData,
            email: enteredValue,
          }));
          setVerificationStatus((prevStatus) => ({
            ...prevStatus,
            email: "Verified",
          }));
          setShowModal(false);
        }
      } else if (modalInputName === "WhatsApp Number") {
        response = await personalInfoServices.verifyWhatsAppOtp(otpString);

        if (response && response.data.result) {
          setFormData((prevData) => ({
            ...prevData,
            whatsApp: enteredValue, // Update the formData for WhatsApp
          }));
          setVerificationStatus((prevStatus) => ({
            ...prevStatus,
            whatsApp: "Verified",
          }));
        }
      }
      toast.success("Otp Verified successfully!");
      fetchProfileData();
      setShowModal(false);
    } catch (error) {
      let errorMessage = handleErrors(error);

      if (error.response && error.response.data) {
        const { message, responseCode } = error.response.data;

        if (responseCode === 500 && message === "Invalid OTP") {
          errorMessage = "The OTP you entered is invalid. Please try again.";
        } else {
          errorMessage = message || errorMessage;
        }
      }

      toast.error(errorMessage);
    }
  };

  const handleOtpChange = (element, index) => {
    let otpArray = [...otp];
    otpArray[index] = element.value;
    setOtp(otpArray);
    if (element.nextSibling && element.value !== "") {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        const previousInput = document.querySelector(
          `input[data-index='${index - 1}']`
        );
        if (previousInput) {
          previousInput.focus();
        }
      }
    }
  };

  const whatsAppCountry =
    user?.userInfo?.whatsApp !== "" &&
    verificationStatus?.whatsApp === "Unverified";

  // const minimumAgeDate = new Date(
  //   today.getFullYear() - 18,
  //   today.getMonth(),
  //   today.getDate()
  // );

  return (
    <div className="personal-info">
      <div className="my-account-title">
        <div className="title-wrap">
          <h2>Personal Details</h2>
        </div>
      </div>
      <div className="profile-info-wrap">
        {/* <ToastContainer /> */}
        <Row>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>First Name</Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder="Your Name*"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                isInvalid={!!errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Last Name</Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder="Your Name*"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                isInvalid={!!errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Father Name</Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder="Your Father Name*"
                name="fatherName"
                value={formData.fatherName}
                onChange={handleInputChange}
                isInvalid={!!errors.fatherName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fatherName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>
              Email{" "}
              <span className="verified">
                {verificationStatus.email === "Verified" ? (
                  <FontAwesomeIcon icon={faCheckCircle} color="green" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} color="red" />
                )}
              </span>
            </Form.Label>
            <div className="form-group">
              <div
                className="email-display"
                onClick={() => handleInputClick("Email")}
              >
                {formData.email || "No Email Provided"}
              </div>
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>
              Phone Number{" "}
              <span className="verified">
                {verificationStatus.phone === "Verified" ? (
                  <FontAwesomeIcon icon={faCheckCircle} color="green" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} color="red" />
                )}
              </span>
            </Form.Label>
            <Form.Group className="form-group">
              <PhoneInput
                international
                defaultCountry="IN"
                type="text"
                placeholder="Your Phone Number*"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(value) => handlePhoneChange(value, "phoneNumber")}
                className={errors.phoneNumber ? "is-invalid" : ""}
                disabled={verificationStatus.phone === "Verified"}
              />

              <Form.Control.Feedback type="invalid">
                {errors.phoneNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Group className="form-group">
              <Form.Label>Gender</Form.Label>
              <Form.Select
                className=" form-control"
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                isInvalid={!!errors.gender}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.gender}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>DOB</Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                name="dob"
                placeholder="dd/mm/yyyy"
                maxLength={10}
                value={formData.dob}
                onChange={handleChange}
                className={errors.dob ? "is-invalid" : ""}
              />
              {errors.dob && <p style={{ color: "red" }}>{errors.dob}</p>}
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Nationality</Form.Label>
            <Form.Group className="form-group">
              <Form.Select
                name="nationality"
                value={formData.nationality}
                onChange={handleInputChange}
                isInvalid={!!errors.nationality}
              >
                <option value="">Select your Nationality*</option>
                <option value="Indian">Indian</option>
                <option value="NRI">NRI</option>
                <option value="Foreigner">Foreigner</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.nationality}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="profile-info-fields">
        <h4>Others Details</h4>
        <Row>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Alternate Email</Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder="abc@xyz.com*"
                name="altEmail"
                value={formData.altEmail}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>
              WhatsApp Number
              <span className="verified">
                {verificationStatus.whatsApp === "Verified" ? (
                  <FontAwesomeIcon icon={faCheckCircle} color="green" />
                ) : (
                  <FontAwesomeIcon icon={faTimesCircle} color="red" />
                )}
              </span>
            </Form.Label>
            <Form.Group className="form-group">
              <PhoneInput
                international
                name="whatsApp"
                maxLength="15"
                // value={`${whatsAppCountry} ? "+91 "${formData.whatsApp} : ${formData.whatsApp}`}
                value={
                  whatsAppCountry
                    ? `+91 ${formData.whatsApp}`
                    : `+${formData.whatsApp}`
                }
                onChange={(value) => handlePhoneChange(value, "whatsApp")}
                onClick={() => handleInputClick("WhatsApp Number")} // Opens modal on click
              />
            </Form.Group>
          </Col>

          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Alternate Number</Form.Label>
            <Form.Group className="form-group">
              <PhoneInput
                international
                defaultCountry="IN"
                maxLength="15"
                value={formData.altPhoneNo}
                onChange={(value) => handlePhoneChange(value, "altPhoneNo")}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="profile-info-fields">
        <h4>Permanent Address</h4>
        <Row>
          <Col xs={12} xl={12}>
            <Form.Label>Street Address</Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Enter your Street Address*"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleInputChange}
                isInvalid={!!errors.streetAddress} // Show error styling
              />
              <Form.Control.Feedback type="invalid">
                {errors.streetAddress}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>City</Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder="Enter your City*"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                isInvalid={!!errors.city}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>State</Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder="Enter your State*"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                isInvalid={!!errors.state}
              />
              <Form.Control.Feedback type="invalid">
                {errors.state}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Pincode</Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                type="number"
                placeholder="Enter your pincode*"
                name="pincode"
                value={formData.pincode}
                onChange={handleInputChange}
                isInvalid={!!errors.pincode}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pincode}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Country</Form.Label>
            <Form.Group className="form-group">
              <Select
                options={options}
                placeholder="Select your Country*"
                value={options.find(
                  (option) => option.label === formData.country
                )}
                onChange={(selectedOption) =>
                  handleInputChange(null, selectedOption)
                } // Pass null for 'e'
                isClearable
                className={errors.country ? "is-invalid" : ""}
              />

              {errors.country && (
                <div className="invalid-feedback">{errors.country}</div>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}></Col>
        </Row>
      </div>

      <div className="save-btn">
        <Button
          type="button"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          {isSaving ? "Saving..." : "Save Details"}
        </Button>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="verify-info-popup"
      >
        <Modal.Header closeButton>
          <h4 className="mb-0">Verify {modalInputName}</h4>
        </Modal.Header>
        <Modal.Body>
          {!otpSent ? (
            isPhoneInput ? (
              <PhoneInput
                international
                defaultCountry="IN"
                placeholder="Enter phone number"
                value={formData.whatsApp} // Change from whatsappNumber to whatsApp
                onChange={(value) => setEnteredValue(value)}
              />
            ) : (
              <Form.Group>
                <Form.Control
                  type={modalInputName === "Email" ? "email" : "text"}
                  placeholder={modalInputName}
                  value={enteredValue}
                  onChange={(e) => setEnteredValue(e.target.value)}
                />
              </Form.Group>
            )
          ) : null}

          {!otpSent ? (
            <Button
              className="mt-4"
              variant="primary"
              onClick={handleSendOtp}
              disabled={isSendingOtp || !enteredValue}
            >
              {isSendingOtp ? "Sending..." : "Send OTP"}
            </Button>
          ) : null}

          {otpSent && (
            <div className="otp-section">
              {" "}
              <Form.Label className="">
                Enter the OTP sent to your {modalInputName}:
              </Form.Label>
              <div
                className="otp-input-container"
                style={{ display: "flex", gap: "10px" }}
              >
                {otp.map((data, index) => (
                  <input
                    type="text"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={(e) => handleOtpChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    data-index={index}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      fontSize: "20px",
                      border: "1px solid #ced4da",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </div>
              <Button
                className="resend-button-wrapper mt-2"
                onClick={handleVerifyOtp}
                disabled={isVerifyingOtp}
              >
                {isVerifyingOtp ? "Verifying..." : "Verify OTP"}
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PersonalInfo;
