import apiClient from "./axios.config";

const SupportRequestService = {
  postSupportRequest(payload) {
    return apiClient.post("customer/support/create", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getSupportTickets() {
    return apiClient.get("customer/support/tickets-by-customer");
  },

  updateSupportRequest(id, payload) {
    return apiClient.put(`customer/support/update`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        id: id,
      },
    });
  },
  getSupportTicketDetails(supportTicketGuid) {
    return apiClient.get(
      `customer/support/ticketdetails?supportTicketGuid=${supportTicketGuid}`
    );
  },
};

export default SupportRequestService;
