import apiClient from "./axios.config";

const flatmateServices ={

  postflatmate(payload) {
    return apiClient.post("/customer/flatmate", payload);
  },
  getflatmate() {
    return apiClient.get("/customer/flatmate");
  },
  deleteflatmate(id) {
    return apiClient.delete(`/customer/flatmate/${id}`); 
  },
  getFlatmateById(id) {
    return apiClient.get(`/customer/flatmate/${id}`); 
  },
  updateFlatmate(payload) {
    return apiClient.put(`/customer/flatmate`, payload); 
  },
}
export default flatmateServices;