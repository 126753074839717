import apiClient from "./axios.config";

const witnesslistServices = {
  postWitnesslist(payload) {
    return apiClient.post("/customer/witness", payload);
  },
  getWitnessList() {
    return apiClient.get("/customer/witness");
  },

};

export default witnesslistServices;
