import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import {
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ErrorHandlingDesign = ({errorMessage}) => {
  return (
    <>
    <section className="section-padding min-h-vh-50">
        <Container className="h-100">
          <Row className="align-items-center h-100">
            <Col>
              {/* <Alert variant="danger" className="text-center">
                {error}
              </Alert> */}
              <div className="no-property-found">
                <FontAwesomeIcon icon={faExclamationCircle} />
                <h5>{errorMessage}</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
