import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlogCard from "../components/common/blog-card";
import blogService from "../services/blogService";
import Pagination from "../components/common/Pagination";
import { handleErrors } from "../utils/errorHandler";
import { ErrorHandlingDesign } from "../components/common/errorHandlingDesign";
import { Helmet } from "react-helmet";
// import { Helmet } from "react-helmet";

const BlogPage = () => {
  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cPage, setCPage] = useState(1);
  const [pageS, setPageS] = useState(12);

  const fetchBlogs = async (pageNo) => {
    setLoading(true);
    const payload = {
      pageSize: pageS,
      pageNo: cPage,
      filterParam: "",
      sortBy: "",
    };

    try {
      const response = await blogService.getBlogListing(payload);
      setBlogList(response.data.result || []);
    } catch (error) {
      const errorMessage = handleErrors(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [cPage, pageS]);

  const handlePageChange = (newPage) => {
    setCPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageS(parseInt(event.target.value));
    setCPage(1);
  };

  return (
    <>
      <Helmet>
        <title>Blog - Latest News and Updates | Sowerent</title>
        <meta
          name="description"
          content="Stay updated with the latest news, articles, and tips from Sowerent. Explore insights on property management, real estate trends, and more."
        />
        <meta
          name="keywords"
          content="sowerent Technology LLP blog, property management tips, real estate insights, rental advice"
        />
      </Helmet>

      <section className="section-padding blog-page-section">
        <Container>
          <Row>
            <Col lg={10} className="mx-auto">
              <div className="section-heading">
                <h1>Blogs</h1>
              </div>
            </Col>
          </Row>
          <Row className="row-gap-gutter">
            {error ? (
              <ErrorHandlingDesign errorMessage={error} />
            ) : loading ? (
              <p>Loading...</p>
            ) : (
              <>
                {blogList?.map((blogItem, index) => (
                  <Col xl={3} lg={4} md={4} sm={6} key={`blog-${index}`}>
                    <BlogCard blogItem={blogItem} />
                  </Col>
                ))}

                {blogList.length > 0 && (
                  <Col lg={12}>
                    <div className="grid-pagination">
                      <Pagination
                        onPageChange={handlePageChange}
                        pageS={pageS}
                        cPage={cPage}
                        pCount={blogList[0]?.totalCount}
                      />
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BlogPage;
