import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  faArrowUpShortWide,
  faBorderAll,
  faFilter,
  faList,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import PropertyCard from "../components/common/property-card";
import propertyService from "../services/propertyService";
import Pagination from "../components/common/Pagination";
import CustomDropdown from "../components/common/CustomDropdown";
import PopertyLocationSearch from "../components/common/property-location-search";
import { handleErrors } from "../utils/errorHandler";
import { ErrorHandlingDesign } from "../components/common/errorHandlingDesign";
import { ProgressSpinner } from "primereact/progressspinner";
import { Helmet } from "react-helmet-async";

const PropertyListing = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObj = Object.fromEntries(queryParams.entries());
  const [selectOptions, setSelectedOptions] = useState([]);
  const [cPage, setCPage] = useState(1);
  const [pageS, setPageS] = useState(16);
  const [isGridView, setIsGridView] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState();
  const [allLocations, setAllLocations] = useState();
  const [locationError, setLocationError] = useState(null);
  const moreFilters = [
    {
      filterType: "Tags",
      filteroptionname: "tags",
      filterOptions: ["New Arrivals", "Upcoming Property"],
    },
    {
      filterType: "Available For",
      filteroptionname: "availabelfor",
      filterOptions: ["Couple", "Bachelors", "Family"],
    },
    {
      filterType: "Furnishing Type",
      filteroptionname: "furnishingtype",
      filterOptions: ["Fully Furnished", "Semi Furnished", "Unfurnished"],
    },
    {
      filterType: "Managed By",
      filteroptionname: "managedby",
      filterOptions: ["SoWeRent", "Owner"],
    },
    {
      filterType: "Availability",
      filteroptionname: "availability",
      filterOptions: ["Immediate", "Within 10 Days"],
    },

    {
      filterType: "Carpet Area",
      filteroptionname: "carpetarea",
      filterOptions: ["Below 500", "500 - 1000", "1000 - 1500", "1500 - 2000"],
    },
  ];

  const [propertyTypeItems, setPropertyTypeItems] = useState([]);
  const [availableFor, setAvailableFor] = useState(
    queryParamsObj.availableFor || ""
  );
  const [furnishingType, setFurnishingType] = useState(
    queryParamsObj.furnishingType || ""
  );
  const [managedBy, setManagedBy] = useState(queryParamsObj.managedBy || "");
  const [availability, setAvailability] = useState(
    queryParamsObj.availability || null
  );
  const [carpetArea, setCarpetArea] = useState();
  const [propertyType, setPropertyType] = useState(
    queryParamsObj.bhkType || ""
  );
  const [propertyTag, setPropertyTag] = useState(queryParamsObj.tags || "");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [formValues, setFormValues] = useState({
    location: queryParamsObj.location,
  });
  const [budget, setBudget] = useState();
  const [sortBy, setSortBy] = useState(queryParamsObj.sortBy || "");
  const budgetList = ["Premium 30k+", "Affordable 20k+", "Thrifty <20k"];

  useEffect(() => {
    const fetchBhkTypes = async () => {
      try {
        const response = await propertyService.getBhkTypes();

        if (response.data.isSuccess) {
          setPropertyTypeItems(
            response.data.result.map((item) => item.bhkType)
          );
        } else {
          setError("BHK Types not found");
        }
      } catch (error) {
        setError("BHK Types not found");
      } finally {
        setLoading(false);
      }
    };

    fetchBhkTypes();
  }, []);

  const mapCarpetAreaToRange = (carpetArea) => {
    switch (carpetArea) {
      case "Below 500":
        return [0, 500];
      case "500 - 1000":
        return [500, 1000];
      case "1000 - 1500":
        return [1000, 1500];
      case "1500 - 2000":
        return [1500, 2000];
      default:
        return [0, 5000];
    }
  };
  const mapBudgetToRange = (budgetType) => {
    switch (budgetType) {
      case "Premium 30k+":
        return [30000, 100000];
      case "Affordable 20k+":
        return [20000, 100000];
      case "Thrifty <20k":
        return [0, 20000];
      default:
        return [0, 1000000];
    }
  };

  const getBudgetType = (budgetFrom, budgetTo) => {
    if (budgetFrom === 30000 && budgetTo === 100000) {
      setBudget("Premium 30k+");
      return "Premium 30k+";
    } else if (budgetFrom === 20000 && budgetTo === 100000) {
      setBudget("Affordable 20k+");
      return "Affordable 20k+";
    } else if (budgetFrom === 0 && budgetTo === 20000) {
      setBudget("Thrifty <20k");
      return "Thrifty <20k";
    } else {
      return null;
    }
  };

  const getCarpetArea = (carpetAreaFrom, carpetAreaTo) => {
    if (carpetAreaFrom === 0 && carpetAreaTo === 500) {
      setCarpetArea("Below 500");
      return "Below 500 sqft";
    } else if (carpetAreaFrom === 500 && carpetAreaTo === 1000) {
      setCarpetArea("500 - 1000");
      return "500 - 1000 sqft";
    } else if (carpetAreaFrom === 1000 && carpetAreaTo === 1500) {
      setCarpetArea("1000 - 1500");
      return "1000 - 1500 sqft";
    } else if (carpetAreaFrom === 1500 && carpetAreaTo === 2000) {
      setCarpetArea("1500 - 2000");
      return "1500 - 2000 sqft";
    } else {
      return null;
    }
  };

  const fetchProperties = useCallback(
    async (cPage = 1) => {
      const [carpetAreaFrom, carpetAreaTo] = mapCarpetAreaToRange(carpetArea);
      const [budFrom, budTo] = mapBudgetToRange(budget);
      const budType = getBudgetType(
        Number(queryParamsObj.budgetFrom),
        Number(queryParamsObj.budgetTo)
      );
      const carpetType = getCarpetArea(
        Number(queryParamsObj.carpetAreaFrom),
        Number(queryParamsObj.carpetAreaTo)
      );
      let numAvail;
      if (queryParamsObj.availability === "Immediate") {
        numAvail = 0;
      } else if (queryParamsObj.availability === "Within 10 Days") {
        numAvail = 10;
      } else {
        numAvail = null;
      }

      let sorting;
      if (queryParamsObj.sortBy === "Price Low to High") {
        sorting = "L2H";
      } else if (queryParamsObj.sortBy === "Price High to Low") {
        sorting = "H2L";
      } else {
        sorting = "";
      }

      // Update state based on query params
      if (location.search !== "") {
        setPropertyType(queryParamsObj.bhkType);
        // setLocations([queryParamsObj.location]);
        setSelectedOptions((prevSelectedOptions) => {
          const {
            budgetFrom,
            budgetTo,
            carpetAreaFrom,
            carpetAreaTo,
            location,
            ...updatedFilter
          } = queryParamsObj;
          return {
            ...updatedFilter,
            ...(budType ? { budget: budType } : {}),
            ...(carpetType ? { carpet: carpetType } : {}),
          };
        });
        setFormValues({ ...formValues, location: queryParamsObj.location });
      }
      // Construct the payload for the API call
      const payload = {
        pageSize: pageS,
        pageNo: cPage,
        locations: [formValues.location], // Use the updated location
        bhkType: propertyType,
        preferredTenants: queryParamsObj.availableFor || availableFor,
        budgetFrom: Number(queryParamsObj.budgetFrom) || budFrom,
        budgetTo: Number(queryParamsObj.budgetTo) || budTo,
        carpetAreaFrom: Number(queryParamsObj.carpetAreaFrom) || null,
        carpetAreaTo: Number(queryParamsObj.carpetAreaTo) || null,
        tags: queryParamsObj.tags || propertyTag,
        furnished: queryParamsObj.furnishingType || furnishingType,
        managedBy: queryParamsObj.managedBy || managedBy,
        availability: numAvail,
        sortBy: sorting,
      };

      setIsPropertyLoading(true);
      try {
        const response = await propertyService.getPropertyListing(payload);
        setPropertyList(response.data.result || []);
      } catch (error) {
        const errorMessage = handleErrors(error);
        setError(errorMessage);
      } finally {
        setIsPropertyLoading(false);
      }
    },
    [
      availableFor,
      furnishingType,
      managedBy,
      carpetArea,
      propertyType,
      budget,
      selectedLocation,
      searchParams,
      availability,
      propertyTag,
    ]
  );

  const handleFilterClick = (filterType, filterItem) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [filterType]: filterItem,
    }));

    // Based on the filter type, update the corresponding state
    switch (filterType) {
      case "Available For":
        setAvailableFor(filterItem); // Single selection
        break;
      case "Furnishing Type":
        setFurnishingType(filterItem); // Single selection
        break;
      case "Managed By":
        setManagedBy(filterItem); // Single selection
        break;
      case "Availability":
        setAvailability(filterItem);
        // if (filterItem === "Immediate") {
        //   setAvailability(0); // Single selection
        // } else if (filterItem === "Within 10 Days") {
        //   setAvailability(10); // Single selection
        // } else {
        //   setAvailability(null);
        // }
        break;
      case "Carpet Area":
        setCarpetArea(filterItem); // Single selection
        break;
      case "Budget":
        setBudget(filterItem);
        break;
      case "Property Type" || "bhkType":
        setPropertyType(filterItem);
        break;
      case "Tags":
        setPropertyTag(filterItem);
        break;
      case "Locations":
        setSelectedLocation(filterItem);
        break;
      case "Sort By":
        if (filterItem === "Price Low to High") {
          setSortBy("L2H");
        } else if (filterItem === "Price High to Low") {
          setSortBy("H2L");
        } else {
          setSortBy("");
        }

      default:
        break;
    }

    // Use a callback function to ensure you're using the updated state
    setAvailableFor((prevAvailableFor) => {
      const newAvailableFor =
        filterType === "Available For" ? filterItem : prevAvailableFor;
      return newAvailableFor;
    });
    setFurnishingType((prevFurnishingType) => {
      const newFurnishingType =
        filterType === "Furnishing Type" ? filterItem : prevFurnishingType;
      return newFurnishingType;
    });
    setManagedBy((prevManagedBy) => {
      const newManagedBy =
        filterType === "Managed By" ? filterItem : prevManagedBy;
      return newManagedBy;
    });

    setCarpetArea((prevCarpetArea) => {
      return filterType === "Carpet Area" ? filterItem : prevCarpetArea;
    });

    setBudget((prevBudgetArea) => {
      return filterType === "Budget" ? filterItem : prevBudgetArea;
    });

    setPropertyType((prevPropType) => {
      return filterType === "Property Type" ? filterItem : prevPropType;
    });

    setPropertyTag((prevTag) => {
      return filterType === "Tags" ? filterItem : prevTag;
    });

    setAvailability((prevAvailability) => {
      return filterType === "Availability" ? filterItem : prevAvailability;
      // if (filterType === "Availability") {
      //   return filterItem === "Immediate"
      //     ? 0
      //     : filterItem === "Within 10 Days"
      //     ? 10
      //     : null;
      // }
      // return prevAvailability;
    });

    setSortBy((prevSort) => {
      if (filterType === "Sort By") {
        return filterItem === "Price Low to High"
          ? "L2H"
          : filterItem === "Price High to Low"
          ? "H2L"
          : "";
      }
      return filterType === "Sort By" ? filterItem : prevSort;
    });

    setSelectedLocation((prevPropType) => {
      return filterType === "Locations" ? filterItem : prevPropType;
    });
    // After updating the state, add the filter to the query params
    const updatedFilters = {
      availableFor: filterType === "Available For" ? filterItem : availableFor,
      furnishingType:
        filterType === "Furnishing Type" ? filterItem : furnishingType,
      managedBy: filterType === "Managed By" ? filterItem : managedBy,
      availability: filterType === "Availability" ? filterItem : availability,
      carpetArea: filterType === "Carpet Area" ? filterItem : carpetArea,
      budget: filterType === "Budget" ? filterItem : budget,
      bhkType: filterType === "Property Type" ? filterItem : propertyType,
      tags: filterType === "Tags" ? filterItem : propertyTag,
      location: filterType === "Locations" ? filterItem : formValues.location,
      sortBy: filterType === "Sort By" ? filterItem : sortBy,
    };
    updateSearchParams(updatedFilters);
  };

  const updateSearchParams = (filters) => {
    const params = new URLSearchParams();
    const [carpetAreaFrom, carpetAreaTo] = mapCarpetAreaToRange(
      filters?.carpetArea
    );
    const [budgetFrom, budgetTo] = mapBudgetToRange(filters?.budget);

    if (filters?.availableFor)
      params.set("availableFor", filters?.availableFor);
    if (filters?.furnishingType)
      params.set("furnishingType", filters?.furnishingType);
    if (filters?.managedBy) params.set("managedBy", filters?.managedBy);
    if (filters?.availability)
      params.set("availability", filters?.availability);
    // Set carpet area range in params
    if (filters?.carpetArea) {
      if (carpetAreaFrom !== null) params.set("carpetAreaFrom", carpetAreaFrom);
      if (carpetAreaTo !== null) params.set("carpetAreaTo", carpetAreaTo);
    }
    if (filters?.budget) {
      if (budgetFrom !== null) params.set("budgetFrom", budgetFrom);
      if (budgetTo !== null) params.set("budgetTo", budgetTo);
    }

    if (filters?.bhkType) params.set("bhkType", filters?.bhkType);
    if (filters?.tags) params.set("tags", filters?.tags);
    if (filters?.sortBy) params.set("sortBy", filters?.sortBy);
    if (filters?.location) params.set("location", filters?.location);

    // Use history.pushState or setSearchParams to update the URL
    setSearchParams(params);
  };

  useEffect(() => {
    fetchProperties(); // Update URL when any filter changes
    // updateSearchParams();
  }, [fetchProperties]);

  const handlePageChange = (newPage) => {
    setCPage(newPage);
    fetchProperties(newPage);
  };

  // const handlePageSizeChange = (event) => {
  //   setPageS(parseInt(event.target.value));
  //   setCPage(1);
  // };

  const fetchLocationSuggestions = async (value) => {
    if (value.trim() === "") {
      setLocations([]);
      return;
    }
    // setIsLoading(true);
    const payload = {
      locName: value,
    };
    try {
      const response = await propertyService.getLocationSuggestions(payload);
      const locationsres = response.data.result || [];
      setLocations(locationsres.map((item) => item.locationName));
    } catch (error) {
      setLocationError("No Locations FOund");
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formValues.location) {
      fetchLocationSuggestions(formValues.location);
    }
  }, [formValues.location]);

  useEffect(() => {
    const fetchAllLocations = async () => {
      try {
        const response = await propertyService.getPropertyLocations();
        const allLocations = response.data?.result || [];
        if (Array.isArray(allLocations)) {
          setLocations(allLocations.map((item) => item.locationName));
          setAllLocations(allLocations.map((item) => item.locationName));
        } else {
          setLocations([]);
        }
      } catch (error) {
        console.error("Error fetching property locations:", error);
        setLocations([]);
        setLocationError("Error fetching locations.");
      }
    };
    fetchAllLocations();
  }, []);

  const handleInputChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      location: value,
    }));
    if (value === "") {
      formValues.location = "";
      handleFilterClick("Locations", "");
      setLocations([...allLocations]);
    }
  };

  const handleSuggestionClick = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      location: value,
    }));
    handleFilterClick("Locations", value);
  };
  const clearAllFilter = () => {
    const deltedparams = new URLSearchParams(location.search);
    const paramsToRemove = [
      "availableFor",
      "furnishingType",
      "managedBy",
      "availability",
      "carpetAreaFrom",
      "carpetAreaTo",
      "budgetFrom",
      "budgetTo",
      "bhkType",
      "tags",
    ];

    // deltedparams.delete("availableFor");
    paramsToRemove.forEach((param) => deltedparams.delete(param));

    setAvailableFor("");
    setBudget(null);
    setPropertyType("");
    setFurnishingType("");
    setManagedBy("");
    setCarpetArea(null);
    setSortBy("");
    setAvailability(null);
    setPropertyTag("");
    setSelectedOptions(() => ({}));
    setSearchParams(deltedparams);
    setShowModal(false);
    return;
  };

  const removeFilter = (paramKey, itemToRemove) => {
    const params = new URLSearchParams(location.search);
    const qparams = {};
    params.forEach((value, key) => {
      qparams[key] = value;
    });
    if (paramKey === "availableFor") {
      params.delete(paramKey);
      setAvailableFor("");
    } else if (paramKey === "budget") {
      setBudget(null);
      params.delete("budgetFrom");
      params.delete("budgetTo");
    } else if (paramKey === "bhkType") {
      params.delete(paramKey);
      setPropertyType("");
    } else if (paramKey === "furnishingType") {
      params.delete(paramKey);
      setFurnishingType("");
    } else if (paramKey === "managedBy") {
      params.delete(paramKey);
      setManagedBy("");
    } else if (paramKey === "carpet") {
      setCarpetArea(null);
      params.delete("carpetAreaFrom");
      params.delete("carpetAreaTo");
    } else if (paramKey === "availability") {
      setAvailability(null);
      params.delete(paramKey);
    } else if (paramKey === "sortBy") {
      setSortBy(null);
      params.delete(paramKey);
    } else if (paramKey === "tags") {
      setPropertyTag("");
      params.delete(paramKey);
    } else {
      params.delete(paramKey);
    }

    if (paramKey === "location") handleInputChange("");
    setSearchParams(params);
    setSelectedOptions((prevOptions) => {
      const { [paramKey]: _, ...updatedProperty } = prevOptions;
      return updatedProperty;
    });
  };

  const handleSortSelect = (sortingType) => {
    handleFilterClick("Sort By", sortingType);
  };

  return (
    <>
      <Helmet>
        <title>
          {selectedLocation.length > 0 ? selectedLocation : "Bangalore"} -
          Property Details | Sowerent
        </title>
        <meta
          name="description"
          content={`Discover more about ${
            selectedLocation.length > 0 ? selectedLocation : "Bangalore"
          }. Learn about the features, pricing, and amenities of this property listed at Sowerent.`}
        />
      </Helmet>
      <section className="custom-breadcrumb dark property-listing-breadcrumb">
        <Container>
          <Row>
            <Col sm={12}>
              <ul className="bread-crumb-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="#">Bangalore</Link>
                </li>
                <li>
                  <Link to="#">{queryParamsObj.location || "Bangalore"}</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="property-filter-section">
        <Container>
          <Row>
            <Col>
              <div className="property-filter-card">
                <div className="filter-card">
                  <div className="search-wrap">
                    <PopertyLocationSearch
                      preSelectedValue={formValues.location}
                      onChange={handleInputChange}
                      handleSubmitAfterSuggestionClick={handleSuggestionClick}
                      locations={locationError ? locationError : locations}
                      value={formValues.location}
                    />
                  </div>
                  <div className="right-col">
                    <div className="filters-wrap">
                      <CustomDropdown
                        title="Budget"
                        items={budgetList}
                        onSelect={handleFilterClick}
                        isSearchEnabled={false}
                        filtertype="bugetlist"
                        selectOptions={selectOptions}
                      />
                      <CustomDropdown
                        title="Property Type"
                        items={propertyTypeItems}
                        onSelect={handleFilterClick}
                        isSearchEnabled={false}
                        filtertype="bhktype"
                        selectOptions={selectOptions}
                      />
                    </div>
                    <div className="more-filters">
                      <button
                        className="filter-btn"
                        onClick={() => setShowModal(true)}
                      >
                        <span>More Filters</span>
                        <FontAwesomeIcon icon={faFilter} />
                      </button>
                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        className="property-filter-modal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            Discover various home aesthetics with our
                            customization choices
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="filter-body-wrap tab-show">
                            <h2>Budget</h2>
                            <div className="filter-list-wrap">
                              {budgetList.map((filterItem, i) => (
                                <div
                                  key={`filter-item-${i}`}
                                  className="filter-list-item"
                                  onClick={() =>
                                    handleFilterClick("Budget", filterItem)
                                  }
                                >
                                  {filterItem}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="filter-body-wrap tab-show">
                            <h2>Property Type</h2>
                            <div className="filter-list-wrap">
                              {propertyTypeItems.map((filterItem, i) => (
                                <div
                                  key={`filter-item-${i}`}
                                  className="filter-list-item"
                                  onClick={() =>
                                    handleFilterClick(
                                      "Property Type",
                                      filterItem
                                    )
                                  } // Add click handler
                                >
                                  {filterItem}
                                </div>
                              ))}
                            </div>
                          </div>
                          {moreFilters.map((filter, index) => (
                            <div
                              className="filter-body-wrap"
                              key={`more-filter-wrap-${index}`}
                            >
                              <h2>{filter.filterType}</h2>
                              <div className="filter-list-wrap">
                                {filter.filterOptions.map((filterItem, i) => (
                                  <div
                                    key={`filter-item-${i}`}
                                    className={`filter-list-item ${
                                      (filter.filteroptionname === "tags" &&
                                        filterItem === selectOptions.tags) ||
                                      (filter.filteroptionname ===
                                        "availabelfor" &&
                                        filterItem ===
                                          selectOptions.availableFor) ||
                                      (filter.filteroptionname ===
                                        "furnishingtype" &&
                                        filterItem ===
                                          selectOptions.furnishingType) ||
                                      (filter.filteroptionname ===
                                        "managedby" &&
                                        filterItem ===
                                          selectOptions.managedBy) ||
                                      (filter.filteroptionname ===
                                        "availability" &&
                                        filterItem ===
                                          selectOptions.availability) ||
                                      (filter.filteroptionname ===
                                        "carpetarea" &&
                                        filterItem === selectOptions.carpet)
                                        ? "disabled"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      (filter.filteroptionname === "tags" &&
                                        filterItem === selectOptions.tags) ||
                                      (filter.filteroptionname ===
                                        "availabelfor" &&
                                        filterItem ===
                                          selectOptions.availableFor) ||
                                      (filter.filteroptionname ===
                                        "furnishingtype" &&
                                        filterItem ===
                                          selectOptions.furnishingType) ||
                                      (filter.filteroptionname ===
                                        "managedby" &&
                                        filterItem ===
                                          selectOptions.managedBy) ||
                                      (filter.filteroptionname ===
                                        "availability" &&
                                        filterItem ===
                                          selectOptions.availability) ||
                                      (filter.filteroptionname ===
                                        "carpetarea" &&
                                        filterItem === selectOptions.carpet)
                                        ? null
                                        : handleFilterClick(
                                            filter.filterType,
                                            filterItem
                                          )
                                    }
                                    style={
                                      (filter.filteroptionname === "tags" &&
                                        filterItem === selectOptions.tags) ||
                                      (filter.filteroptionname ===
                                        "availabelfor" &&
                                        filterItem ===
                                          selectOptions.availableFor) ||
                                      (filter.filteroptionname ===
                                        "furnishingtype" &&
                                        filterItem ===
                                          selectOptions.furnishingType) ||
                                      (filter.filteroptionname ===
                                        "managedby" &&
                                        filterItem ===
                                          selectOptions.managedBy) ||
                                      (filter.filteroptionname ===
                                        "availability" &&
                                        filterItem ===
                                          selectOptions.availability) ||
                                      (filter.filteroptionname ===
                                        "carpetarea" &&
                                        filterItem === selectOptions.carpet)
                                        ? {
                                            pointerEvents: "none",
                                            opacity: 0.5,
                                          }
                                        : {}
                                    }
                                  >
                                    {filterItem}
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={clearAllFilter}>
                            Clear All
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div className="sorting-dropdown">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="sort-btn"
                        >
                          Sort By <FontAwesomeIcon icon={faArrowUpShortWide} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleSortSelect("Price Low to High")
                            }
                          >
                            Price: Low to High
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleSortSelect("Price High to Low")
                            }
                          >
                            Price: High to Low
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="applied-filters">
                  {/* <h5>Applied Filters</h5> */}
                  <ul className="applied-filter-list">
                    {Object.entries(selectOptions).map(([key, value]) =>
                      value ? (
                        <li key={key}>
                          <span>{value}</span>
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => removeFilter(key, value)}
                          />
                        </li>
                      ) : null
                    )}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="property-listing-section">
        <Container>
          <Row>
            <Col>
              {propertyList.length === 0 && !isPropertyLoading && (
                <h5 className="text-center">No Properties Found</h5>
              )}
              {propertyList.length > 0 && (
                <div className="property-list-title">
                  <h1>
                    {propertyList[0].totalCount}
                    {propertyList[0].totalCount > 1
                      ? " Apartments"
                      : " Apartment"}{" "}
                    for Rent in {queryParamsObj.location || "Bangalore"}
                  </h1>
                  <div className="grid-view-option">
                    <ul>
                      <li
                        className={isGridView ? "active" : ""}
                        onClick={() => setIsGridView(true)}
                      >
                        <FontAwesomeIcon icon={faBorderAll} />
                        Grid View
                      </li>
                      <li
                        className={!isGridView ? "active" : ""}
                        onClick={() => setIsGridView(false)}
                      >
                        <FontAwesomeIcon icon={faList} />
                        List View
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          {error ? (
            <ErrorHandlingDesign errorMessage={error} />
          ) : (
            <div
              className={`property-card__row row-gap-gutter ${
                !isGridView ? "property-list-view" : ""
              }`}
            >
              {isPropertyLoading ? (
                <div className="min-h-300 mx-auto">
                  <ProgressSpinner
                    style={{ width: "50px", height: "50px" }}
                    strokeWidth="3"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                </div>
              ) : (
                propertyList.map((property) => (
                  <div className="property-card__col" key={property.id}>
                    <PropertyCard
                      property={{
                        ...property,
                        gallery: property.gallery.filter(
                          (media) => media.fileType !== "Video"
                        ),
                      }}
                      flatId={property.id}
                    />
                  </div>
                ))
              )}
            </div>
          )}
          {propertyList.length > 0 && (
            <Col lg={12}>
              <div className="grid-pagination">
                <Pagination
                  onPageChange={handlePageChange}
                  pageS={pageS}
                  cPage={cPage}
                  pCount={propertyList[0]?.totalCount}
                />
              </div>
            </Col>
          )}
        </Container>
      </section>
    </>
  );
};

export default PropertyListing;
