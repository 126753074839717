import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import faqsBanner from "../assets/images/faq-banner.jpg";
import faqs from "../assets/data/faqs.json";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";

const FaqPage = () => {
  return (
    <>
      <Helmet>
        <title>FAQ | Sowerent</title>
        <meta
          name="description"
          content="Get answers to the most frequently asked questions about Sowerent, our services, and how we can help you with property management and rentals."
        />
      </Helmet>
      <section className="about-us-page">
        <Container fluid className="px-0">
          <div className="about-banner__row">
            <div className="about-banner__col">
              <div className="about-banner">
                <img src={faqsBanner} alt="" />
              </div>
            </div>
            <div className="about-banner__col">
              <div className="section-heading">
                <span>FAQs</span>
                <h2>Have Any Questions ?</h2>
                <div className="about-us-content">
                  <p>
                    sowerent Technology LLP provides this FAQ section to offer
                    clear and concise answers to common inquiries regarding our
                    property management services. Whether you are a property
                    owner looking to lease your property or a tenant in search
                    of your ideal home.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="faq-page-section">
        <Container>
          <Row>
            <Col>
              <Accordion defaultActiveKey="0">
                {faqs.faq.map((faq, index) => {
                  return (
                    <Accordion.Item
                      key={index}
                      eventKey={`${index}`}
                      className="faq-card"
                    >
                      <Accordion.Header>
                        {faq.question} <FontAwesomeIcon icon={faChevronDown} />
                      </Accordion.Header>
                      <Accordion.Body>
                        {Array.isArray(faq.answer) ? (
                          faq.answer.map((item, index) => (
                            <p key={index}>{item}</p>
                          ))
                        ) : (
                          <div className="faq-info">{faq.answer}</div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FaqPage;
