import {
  faCheckCircle,
  faInr,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import bookingService from "../services/bookingService";
import { handleErrors } from "../utils/errorHandler";
import { ErrorHandlingDesign } from "../components/common/errorHandlingDesign";
import { Helmet } from "react-helmet-async";

const BookingFailure = () => {
  const [basicDetails, setBasicDetails] = useState([]);
  const [error, setErrors] = useState(null);
  const location = useLocation();
  const { orderId } = location.state || {};

  useEffect(() => {
    const fetchBasicDetails = async () => {
      try {
        const basicDetails = await bookingService.getBookingBasicDetails(
          orderId
        );
        setBasicDetails(basicDetails.data.result);
      } catch (error) {
        const errorMessage = handleErrors(error);
        setErrors(errorMessage);
      }
    };

    fetchBasicDetails();
  }, [orderId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  const todayDate = new Date();

  return (
    <>
      <Helmet>
        <title>Booking Failed | Sowerent</title>
        <meta
          name="description"
          content="Oops! Your booking could not be processed. Please try again later or contact support."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <section className="section-padding booking-confirmation">
        {error ? (
          <ErrorHandlingDesign errorMessage={error} />
        ) : (
          <Container className="booking-container">
            <Row>
              <Col>
                <div className="booking-confirmation-card">
                  <div className="title" style={{ color: "#e34242" }}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                    <h1 style={{ color: "#e34242" }}>Booking Failed</h1>
                  </div>
                  <div className="bookig-details">
                    <h6>
                      Something went wrong , Please contact our support team.
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </>
  );
};

export default BookingFailure;
