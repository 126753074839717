// import Cookies from "js-cookie";
export const handleErrors = (error) => {
  if (error.response) {
    const status = error.response.status;

    switch (status) {
      case 400:
        return "Bad Request: The server couldn't understand the request.";
      case 401:
        return "Unauthorized: Please log in to access this resource.";
      case 403:
        return "Forbidden: You don't have permission to access this.";      
      case 404:
        return "Not Found: The requested resource was not found.";
      case 409:
        return "Conflict: Already exist.";
      case 500:
        return "Internal Server Error: Please try again later.";
      default:
        // if(error.message == 'Network Error'){
        //   Cookies.remove("accessToken");
        //   window.location.href = '/login';
        // }else{
        //   return error.message;
        // }
        // break;
        return error.message;
    }
  } else if (error.request) {
    return "Error: No response received from the server. Please try again.";
  } else {
    return error;
  }
};
