import { isValidPhoneNumber } from "react-phone-number-input";

export const validateScheduleMeetingForm = (formData) => {
  const errors = {};
  // Validate Name
  if (!formData.name || formData.name.trim() === "") {
    errors.name = "Name is required";
  }

  // Validate Email
  if (!formData.email) {
    errors.email = "Email address is required";
  } else {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    if (!isValidEmail) {
      errors.email = "Email address is not valid";
    }
  }

  // Validate Phone Number (10 digits)
  // if (!formData.phoneNumber || formData.phoneNumber.trim() === "") {
  //   errors.phoneNumber = "Phone number is required";
  // } else {
  //   // Check if phone number is exactly 10 digits (assuming it's a numeric string)
  //   const phoneNumber = formData.phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
  //   if (phoneNumber.length !== 12) {
  //     errors.phoneNumber = "Phone number must be 10 digits";
  //   }
  // }
  if (!formData.phoneNumber) {
    errors.phoneNumber = "Phone  number is required.";
  } else if (!isValidPhoneNumber(formData.phoneNumber)) {
    errors.phoneNumber = "Please enter a valid phone number.";
  }


  // Validate Date Selection (arrival)
  if (!formData.arrival) {
    errors.arrival = "Please select a date for your visit.";
  }

  // Validate Visit Type (visit)
  if (!formData.visit) {
    errors.visit = "Please select a visit type.";
  }

  // Validate Time Slot (timeSlot)
  if (formData.timeSlot === "") {
    errors.timeSlot = "Please select a time slot for your visit.";
  }

  return errors;
};
