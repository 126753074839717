import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ blogItem }) => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };
  return (
    <div className="blog-card" data-aos="fade-down" data-aos-duration={2000}>
      <div className="blog-img">
        <Link to={`/blogs/${blogItem.blogUrl}-${blogItem.id}`}>
          <img src={blogItem.thumbImage} alt="" />
        </Link>
      </div>
      <div className="blog-content">
        <div className="d-flex flex-column">
          <div className="blog-property">
            <span>{blogItem.blogCategory}</span>
          </div>
          <div className="blog-title">
            <h3>
              <Link to={`/blogs/${blogItem.blogUrl}-${blogItem.id}`}>
                {blogItem.blogName}
              </Link>
            </h3>
          </div>
        </div>
        <ul className="property-category d-flex justify-content-between align-items-center">
          <li className="user-info">
            <div className="user-name">
              <p>{formatDate(blogItem.postedOn)}</p>
            </div>
          </li>
          <li>
            <Link to={`/blogs/${blogItem.blogUrl}-${blogItem.id}`}>
              <span>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlogCard;
