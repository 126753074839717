import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import customerRegistration from "../services/customer-service";
// import googleIcon from "../assets/images/google.png";
// import appleIcon from "../assets/images/apple-logo.png";
import PhoneInput from "react-phone-number-input";
import { handleErrors } from "./../utils/errorHandler";
import "react-phone-number-input/style.css";
import Cookies from "js-cookie";
import { useUser } from "../components/context/UserContext";
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhoneNo,
} from "../utils/validation/validate-register";
import { Link, useNavigate } from "react-router-dom";
import { ErrorHandlingDesign } from "../components/common/errorHandlingDesign";
import { Helmet } from "react-helmet-async";

const RegistrationPage = () => {
  const [isOtpFormActive, setIsOtpFormActive] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNo: "",
    whatsApp: "No",
  });
  const [isOtpSubmitDisabled, setIsOtpSubmitDisabled] = useState(false);
  const [isRequestOtpDisabled, setIsRequestOtpDisabled] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [errors, setErrors] = useState({});
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [timer, setTimer] = useState(60); // 1 minute timer
  const [isResendOtpActive, setIsResendOtpActive] = useState(false);
  const [custGuid, setCustGuid] = useState(""); // Store custGuid
  const inputRefs = useRef([]);
  const [isVerifying, setIsVerifying] = useState(false);
  const [registerError, setRegisterError] = useState(null);
  const [ApiError, setApiError] = useState(null);
  const { setAuthentication } = useUser();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {}, [custGuid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: null });
  };
  const handleToggleWhatsApp = (e) => {
    setFormData({ ...formData, whatsApp: e.target.checked ? "Yes" : "No" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    newErrors.firstName = validateFirstName(formData.firstName);
    newErrors.lastName = validateLastName(formData.lastName);
    newErrors.emailAddress = validateEmail(formData.emailAddress);

    const formattedPhoneNo = formData.phoneNo
      ? formData.phoneNo.replace(/^\+91/, "").trim()
      : "";
    newErrors.phoneNo = validatePhoneNo(formattedPhoneNo);

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return;
    }

    const payload = {
      ...formData,
      phoneNo: formattedPhoneNo, // Send only the 10-digit number
      pwd: "Hello@123",
    };
    setIsRequestOtpDisabled(true);
    setIsSubmitDisabled(true);

    try {
      const response = await customerRegistration.postCustomerRegistration(
        payload
      );
      setIsOtpFormActive(true);
      setCustGuid(response.data.result.cust_id);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        console.error("Registration conflict: User already exists.");
        setRegisterError(error.response.data.message);
      } else {
        const errorMessage = handleErrors(error);
        setApiError(errorMessage);
      }
    } finally {
      setIsRequestOtpDisabled(false);
      setIsSubmitDisabled(false);
    }
  };

  const handleChangeOtp = (e, index) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value.slice(-1);
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };
  const handleKeyDownOtp = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };
  const handleResendOtp = async () => {
    // if (!custGuid) {
    //   console.error("No customer ID found for resending OTP");
    //   return;
    // }
    try {
      const response = await customerRegistration.postresendOtp(custGuid);
      setTimer(60);
      setIsResendOtpActive(false);
    } catch (error) {
      const errorMessage = handleErrors(error);
      setApiError(errorMessage);
    }
  };
  useEffect(() => {
    let intervalId;
    if (isOtpFormActive && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendOtpActive(true);
    }
    return () => clearInterval(intervalId);
  }, [isOtpFormActive, timer]);

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const enteredOtp = otp.join("");
    if (enteredOtp.length !== 4) {
      setOtpError("Please enter a complete 4-digit OTP.");
      return;
    }

    setIsVerifying(true);
    setOtpError("");
    setIsOtpSubmitDisabled(true);

    try {
      const response = await customerRegistration.verifyOtp(
        custGuid,
        enteredOtp
      );

      if (response.data.isSuccess) {
        const accessToken = Cookies.get("accessToken");

        if (accessToken) {
          setAuthentication(!!accessToken);
        } else {
          setAuthentication(null);
        }

        // Navigate to profile after successful OTP verification
        navigate("/my-account/profile", { replace: true });
      } else {
        setIsVerifying(false);
        setOtpError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("OTP verification failed:", error);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setOtpError(error.response.data.message);
      } else {
        setOtpError("OTP verification failed. Please try again.");
      }

      setIsVerifying(false);
    } finally {
      setIsOtpSubmitDisabled(false); // Reset the button state
    }
  };

  return (
    <>
      <Helmet>
        <title>Register | Sowerent</title>
        <meta
          name="description"
          content="Create an account on sowerent Technology LLP to rent or list properties with ease."
        />
      </Helmet>

      <div className="login-wrapper">
        {ApiError ? (
          <ErrorHandlingDesign errorMessage={ApiError} />
        ) : (
          <div className="loginbox">
            <div className="login-auth">
              <div className="login-auth-wrap">
                <h1>Register and find your home with ease!</h1>
                {!isOtpFormActive ? (
                  <>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="firstName"
                              placeholder="Enter your First Name"
                              value={formData.firstName}
                              onChange={handleChange}
                              isInvalid={!!errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.firstName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="lastName"
                              placeholder="Enter your Last Name"
                              value={formData.lastName}
                              onChange={handleChange}
                              isInvalid={!!errors.lastName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.lastName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Contact Number</Form.Label>
                            <PhoneInput
                              international
                              defaultCountry="IN"
                              id="phone-number"
                              type="text"
                              maxLength="15"
                              name="phoneNo"
                              placeholder="Enter your phone number"
                              value={formData.phoneNo}
                              onChange={(value) => {
                                setFormData({ ...formData, phoneNo: value });
                                setErrors({ ...errors, phoneNo: null });
                              }}
                            />
                            {errors.phoneNo && (
                              <div className="text-danger">
                                {errors.phoneNo}
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="text"
                              name="emailAddress"
                              placeholder="Enter your email address"
                              value={formData.emailAddress}
                              onChange={handleChange}
                              isInvalid={!!errors.emailAddress}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.emailAddress}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          {registerError ? (
                            <div className="alert alert-danger" role="alert">
                              {registerError}
                            </div>
                          ) : (
                            ""
                          )}
                        </Col>

                        <Col lg={6}>
                          <Form.Group className="mb-3">
                            <div className="whatsapp-wrraper">
                              <Form.Check
                                className="p-0"
                                type="switch"
                                id="whatsapp-toggle"
                                label=""
                                checked={formData.whatsApp === "Yes"}
                                onChange={handleToggleWhatsApp}
                              />
                              <span>If WhatsApp?</span>
                            </div>
                          </Form.Group>
                        </Col>
                        {/* <Col lg={12}>
                          <div className="alert alert-danger">{error}</div>
                        </Col> */}
                      </Row>
                      <Button
                        type="submit"
                        className="btn btn-theme-blue w-100"
                        disabled={isRequestOtpDisabled}
                      >
                        {isRequestOtpDisabled
                          ? "Requesting OTP..."
                          : "Request OTP"}
                      </Button>
                      <br />
                      <p>
                        <b>Note:</b> Name should match your Aadhaar Name .
                      </p>
                    </Form>
                  </>
                ) : (
                  <div className="otp-form">
                    <div style={{ display: "flex", gap: "10px" }}></div>
                    <div className="d-flex gap-3 justify-content-center">
                      {otp.map((_, index) => (
                        <Form.Group key={index} className="mb-3 otp-input">
                          <Form.Control
                            ref={(el) => (inputRefs.current[index] = el)}
                            type="text"
                            maxLength="1"
                            value={otp[index]}
                            onChange={(e) => handleChangeOtp(e, index)}
                            onKeyDown={(e) => handleKeyDownOtp(e, index)}
                          />
                        </Form.Group>
                      ))}
                    </div>
                    <div className="resend-button-wrraper">
                      {timer > 0 ? (
                        <p className="mb-2">Time remaining: {timer} seconds</p>
                      ) : (
                        <p></p>
                      )}
                      {isResendOtpActive && (
                        <button
                          onClick={handleResendOtp}
                          className="resend-otp mb-2"
                        >
                          Resend OTP
                        </button>
                      )}
                      {otpError && <p className="text-danger">{otpError}</p>}
                    </div>
                    <Button
                      onClick={handleOtpSubmit}
                      className="btn btn-theme-blue w-100"
                      disabled={isOtpSubmitDisabled}
                    >
                      {isOtpSubmitDisabled ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                )}
                {/* <div className="or-content">
                  <span>OR</span>
                </div>
                <div className="direct-email-options">
                  <div className="item">
                    <img src={googleIcon} alt="Google" />
                    <span>Sign in with Google</span>
                  </div>
                  <div className="item">
                    <img src={appleIcon} alt="Apple" />
                    <span>Sign in with Apple</span>
                  </div>
                </div> */}
                <div className="dont-have-account">
                  <span>Already have an account?</span>
                  <Link to={"/login"}>Log in</Link>.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default RegistrationPage;
