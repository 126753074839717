import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SectionLeftIcon from "../../assets/images/property-sec-bg-1.png";
import SectionRightIcon from "../../assets/images/property-sec-bg-2.png";
import SectionRightBottomIcon from "../../assets/images/icons/red-circle.svg";
import PropertyIcon1 from "../../assets/images/icons/property-icon-1.svg";
import PropertyIcon2 from "../../assets/images/icons/property-icon-2.svg";
import PropertyIcon4 from "../../assets/images/icons/property-icon-4.svg";
import propertyService from "../../services/propertyService";
import { ProgressSpinner } from "primereact/progressspinner";
import { handleErrors } from "./../../utils/errorHandler";
import { ErrorHandlingDesign } from "../common/errorHandlingDesign";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const PropertyType = () => {
  const [bhkTypes, setBhkTypes] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error handling
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Update on window resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchBhkTypes = async () => {
      try {
        const response = await propertyService.getBhkTypes();

        if (response.data.isSuccess) {
          setBhkTypes(response.data.result);
        } else {
          setError("Failed to fetch BHK types.");
        }
      } catch (error) {
        const errorMessage = handleErrors(error);
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchBhkTypes();
  }, []);

  const handleCardClick = (bhkType) => {
    navigate(`/properties?bhkType=${bhkType}`);
  };

  return (
    <section className="property-type-sec">
      <div className="section-shape-imgs">
        <img src={SectionLeftIcon} className="rectangle-left" alt="" />
        <img src={SectionRightIcon} className="rectangle-right" alt="" />
        <img src={SectionRightBottomIcon} className="bg-09" alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div
              className="section-heading"
              data-aos="fade-down"
              data-aos-duration={1000}
            >
              <h2>Explore by Property Type</h2>
              <div className="sec-line">
                <span className="sec-line1" />
                <span className="sec-line2" />
              </div>
              <p>
                Discover properties tailored to your needs whether you're
                looking for cozy apartments, spacious villas, or modern office
                spaces. Find the perfect fit for your lifestyle or business.
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="row row-gap-gutter property-type-slider">
              {error ? (
                <ErrorHandlingDesign errorMessage={error} />
              ) : loading ? (
                <div className="col-12 text-center">
                  <ProgressSpinner
                    style={{ width: "50px", height: "50px" }}
                    strokeWidth="3"
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                </div>
              ) : error ? (
                <div className="col-12 text-center">
                  <h5 className="custom-error">{error}</h5>
                </div>
              ) : (
                <Swiper
                  slidesPerView={2}
                  modules={[Pagination]}
                  spaceBetween={24}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    576: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    991: {
                      slidesPerView: 3,
                      spaceBetween: 16,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 24,
                    },
                  }}
                >
                  {bhkTypes.map((bhk, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className="property-card"
                        data-aos="fade-down"
                        data-aos-duration={1000}
                        onClick={() => handleCardClick(bhk.bhkType)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="property-img">
                          <img
                            src={
                              bhk.bhkType === "1 BHK"
                                ? PropertyIcon1
                                : bhk.bhkType === "2 BHK"
                                ? PropertyIcon2
                                : PropertyIcon4
                            }
                            alt={bhk.bhkType}
                          />
                        </div>
                        <div className="property-name">
                          <h4>{bhk.bhkType}</h4>
                          <p>Properties</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertyType;
