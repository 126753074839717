import React from "react";
import LoginForm from "../components/common/LoginForm";
import { Helmet } from "react-helmet-async"; // For SEO

const LoginPage = () => {
  return (
    <>
      <Helmet>
        <title>Login | Sowerent</title>
        <meta
          name="description"
          content="Login to your sowerent Technology LLP account to manage your properties or find the perfect rental home."
        />
      </Helmet>
      <div>
        <LoginForm LoginFrom="LoginPage" />
      </div>
    </>
  );
};

export default LoginPage;
