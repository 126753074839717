import React, { useState } from "react";
import googleIcon from "../../assets/images/google.png";
import appleIcon from "../../assets/images/apple-logo.png";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  validateContactNumber,
  validateOtp,
} from "../../utils/validation/validate-login";
import customerRegistration from "../../services/customer-service";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../../styles/login-register.css";
import { useUser } from "../context/UserContext";
import { handleErrors } from "../../utils/errorHandler";
import { ErrorHandlingDesign } from "../../components/common/errorHandlingDesign";
import Cookies from "js-cookie";

const LoginForm = ({ LoginFrom = "LoginPage", onLoginSuccess }) => {
  const [isRequestOtpDisabled, setIsRequestOtpDisabled] = useState(false);
  const [isOtpFormActive, setIsOtpFormActive] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [contactError, setContactError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [apiError, setApiError] = useState("");
  const [resendTimer, setResendTimer] = useState(60);
  const [canResendOtp, setCanResendOtp] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setAuthentication } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleOtpChange = (index, value) => {
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      document.getElementById(`otp-digit-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-digit-${index - 1}`).focus();
    }
  };

  const handleRequestOtp = async () => {
    const formattedNumber = contactNumber.replace(/^\+91/, "");
    const error = validateContactNumber(formattedNumber);

    if (error) {
      setContactError(error);
    } else {
      setContactError("");
      setIsRequestOtpDisabled(true);
      try {
        const response = await customerRegistration.postRequestOtp(
          formattedNumber
        );
        if (response.data.isSuccess) {
          setIsOtpFormActive(true);
          setApiError("");
          startTimer();
        } else {
          setApiError("Failed to send OTP. Please try again.");
        }
      } catch (err) {
        setIsLoggedIn(true);
      } finally {
        setIsRequestOtpDisabled(false);
      }
    }
  };

  const startTimer = () => {
    setCanResendOtp(false);
    setResendTimer(60);
    const timer = setInterval(() => {
      setResendTimer((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setCanResendOtp(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const handleResendOtp = async () => {
    const formattedNumber = contactNumber.replace(/^\+91/, "");

    try {
      const response = await customerRegistration.postResendOtpLogin(
        formattedNumber
      );
      if (response.data.isSuccess) {
        setApiError("");
        startTimer();
      } else {
        setApiError("Failed to resend OTP. Please try again.");
      }
    } catch (err) {
      const errorMessage = handleErrors(err);
      setApiError(errorMessage);
    }
  };

  const handleSubmitOtp = async () => {
    const formattedNumber = contactNumber.replace(/^\+91/, "");
    const error = validateOtp(otp);

    if (error) {
      setOtpError(error);
    } else {
      setOtpError("");
      setIsSubmitting(true);
      try {
        const otpString = otp.join("");
        const response = await customerRegistration.verifyOtpLogin(
          formattedNumber,
          otpString
        );
        if (response.data.isSuccess === true) {
          const accessToken = Cookies.get("accessToken");
          if (accessToken) {
            setAuthentication(!!accessToken);
          } else {
            setAuthentication(null);
          }
          //  setUser(isAuthenticated);
          if (LoginFrom === "LoginPage") {
            // setUser(isAuthenticated);
            navigate("/my-account/profile");
          } else {
            // setUser(isAuthenticated);
            onLoginSuccess();
          }
        } else {
          setOtpError("Invalid OTP. Please try again.");
        }
      } catch (err) {
        // const errorMessage = handleErrors(err);
        setOtpError("Invalid OTP. Please try again.");
        // setApiError(errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleEnterKeyPressForPhone = (e) => {
    if (e.key === "Enter" && contactNumber) {
      e.preventDefault();
      handleRequestOtp();
      // handleSubmitOtp();
    }
  };

  return (
    <div className="login-wrapper">
      {apiError ? (
        <ErrorHandlingDesign errorMessage={apiError} />
      ) : (
        <div className="loginbox">
          <div className="login-auth">
            <div className="login-auth-wrap">
              <h1>Login to enjoy a hassle-free renting experience.</h1>
              <Form>
                {!isOtpFormActive ? (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Contact Number</Form.Label>
                      <PhoneInput
                        international
                        defaultCountry="IN"
                        id="contact-number"
                        type="text"
                        maxLength="15"
                        name="login-number"
                        placeholder="Enter your phone number"
                        value={contactNumber}
                        onChange={setContactNumber}
                        onKeyDown={handleEnterKeyPressForPhone}
                      />
                      {contactError && (
                        <div className="text-danger">{contactError}</div>
                      )}
                      {apiError && (
                        <div className="text-danger">{apiError}</div>
                      )}
                    </Form.Group>
                    {isLoggedIn ? (
                      <div className="alert alert-danger" role="alert">
                        User does not exist !
                      </div>
                    ) : (
                      ""
                    )}
                    <Button
                      type="button"
                      className="btn btn-theme-blue w-100"
                      onClick={handleRequestOtp}
                      disabled={isRequestOtpDisabled}
                    >
                      {isRequestOtpDisabled
                        ? "Requesting OTP..."
                        : "Request OTP"}
                    </Button>
                  </>
                ) : (
                  <div className="otp-form">
                    <div className="d-flex gap-3 justify-content-center">
                      {otp.map((digit, index) => (
                        <Form.Group key={index} className="mb-3 otp-input">
                          <Form.Control
                            id={`otp-digit-${index}`}
                            type="number"
                            maxLength="1"
                            value={digit}
                            onChange={(e) => {
                              const value = e.target.value.slice(0, 1);
                              handleOtpChange(index, value);
                            }}
                            onKeyDown={(e) => {
                              handleKeyDown(index, e);
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleSubmitOtp();
                              }
                            }}
                          />
                        </Form.Group>
                      ))}
                    </div>

                    {otpError && (
                      <div className="alert alert-danger">{otpError}</div>
                    )}
                    <div className="resend-button-wrraper">
                      {canResendOtp ? (
                        <button
                          type="button"
                          className="resend-otp mb-2"
                          onClick={handleResendOtp}
                        >
                          Resend OTP
                        </button>
                      ) : (
                        <div className="mt-2 mb-2">
                          Resend OTP in {resendTimer} seconds
                        </div>
                      )}
                    </div>

                    <Button
                      type="button"
                      className="btn btn-theme-blue w-100"
                      onClick={handleSubmitOtp}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                )}
              </Form>
              {/* 
              <div className="or-content">
                <span>OR</span>
              </div> */}
              {/* <div className="direct-email-options">
                <div className="item">
                  <img src={googleIcon} alt="Google" />
                  <span>Sign in with Google</span>
                </div>
                <div className="item">
                  <img src={appleIcon} alt="Apple" />
                  <span>Sign in with Apple</span>
                </div>
              </div> */}
              <div className="dont-have-account">
                <span>Don't have an account?</span>
                <Link to={"/registration"}>Register Now</Link>.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
