import { isValidPhoneNumber } from "react-phone-number-input";

export const validatePersonalInfo = (formData) => {
  let errors = {};

  // First Name Validation
  if (!formData.firstName.trim()) {
    errors.firstName = "First Name is required.";
  }
  if (!formData.fatherName.trim()) {
    errors.fatherName = "Father Name is required.";
  }

  // Last Name Validation
  if (!formData.lastName.trim()) {
    errors.lastName = "Last Name is required.";
  }

  if (!formData.nationality.trim()) {
    errors.nationality = "Nationality is required.";
  }

  // Email Validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Phone Number Validation
  if (!formData.phoneNumber) {
    errors.phoneNumber = "Phone number is required";
  } else if (!/^\+?[1-9]\d{1,14}$/.test(formData.phoneNumber)) {
    errors.phoneNumber = "Invalid phone number";
  }
  // if (!formData.altEmail) {
  //   errors.altEmail = "Alternate Email is required.";
  // } else if (!emailRegex.test(formData.altEmail)) {
  //   errors.altEmail = "Enter a valid alternate email address.";
  // }

  // WhatsApp Number Validation
  // if (
  //   formData.whatsApp &&
  //   !/^\+?[1-9]\d{1,14}$/.test(formData.whatsApp)
  // ) {
  //   errors.whatsApp = "Invalid WhatsApp number";
  // }
  // // **Alternate Number (altPhoneNo) Validation - Update**

  // if (!formData.whatsApp) {
  //   errors.whatsApp = "WhatsApp  number is required.";
  // } else if (!isValidPhoneNumber(formData.whatsApp)) {
  //   errors.whatsApp = "Please enter a valid phone number.";
  // }

  // if (!formData.altPhoneNo || formData.altPhoneNo.trim() === "") {
  //   errors.altPhoneNo = "Alternate phone number is required.";
  // } else {
  //   const altPhoneNo = formData.altPhoneNo.replace(/\D/g, '');
  //   if (altPhoneNo.length !== 12) {
  //     errors.altPhoneNo = "Phone number must be 10 digits.";
  //   }
  // }

  // if (!formData.altPhoneNo) {
  //   errors.altPhoneNo = "Alternate Phone number is required.";
  // } else if (!isValidPhoneNumber(formData.altPhoneNo)) {
  //   errors.altPhoneNo = "Please enter a valid phone number.";
  // }

  // Gender Validation
  if (!formData.gender) {
    errors.gender = "Gender is required.";
  }

  // Date of Birth Validation
  if (!formData.dob) {
    errors.dob = "Date of Birth is required.";
  }

  // Nationality Validation
  if (!formData.nationality.trim()) {
    errors.nationality = "Nationality is required.";
  }

  // Pincode Validation
  const pinCodeRegex = /^[1-9][0-9]{5}$/;
  if (!formData.pincode.trim()) {
    errors.pincode = "Pincode is required.";
  } else if (!pinCodeRegex.test(formData.pincode.trim())) {
    errors.pincode = "Enter a valid 6-digit pincode.";
  }

  // City Validation
  if (!formData.city.trim()) {
    errors.city = "City is required.";
  }

  // State Validation
  if (!formData.state.trim()) {
    errors.state = "State is required.";
  }

  // Country Validation
  if (!formData.country.trim()) {
    errors.country = "Country is required.";
  }

  // Street Address Validation
  if (!formData.streetAddress.trim()) {
    errors.streetAddress = "Street Address is required.";
  }

  return errors;
};
