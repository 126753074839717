import apiClient from "./axios.config";

const personalInfoServices = {
  getProfileInfo(payload) {
    return apiClient.get("/customer/profile/basic", payload);
  },

  updateProfileInfo(payload) {
    return apiClient.put("/customer/profile/basic", payload);
  },
  sendEmailOtp(emailAddress) {
    return apiClient.put(`/customer/profile/email-otp`, {
      emailAddress: emailAddress,
    });
  },
  sendPhoneOtp(phoneNumber) {
    return apiClient.put(
      `/customer/profile/phone-otp?phoneNumber=${phoneNumber}`
    );
  },
  sendWhatsAppOtp(whatsappNumber) {
    return apiClient.put(
      `/customer/profile/whatsapp-otp?whatsappNumber=${whatsappNumber}`
    );
  },
  verifyPhoneOtp(otp) {
    return apiClient.put(`/customer/profile/verify-phone-otp?otp=${otp}`);
  },
  verifyEmailOtp(otp) {
    return apiClient.put(`/customer/profile/verify-email-otp?otp=${otp}`);
  },
  verifyWhatsAppOtp(otp) {
    return apiClient.put(`/customer/profile/verify-whatsapp-otp?otp=${otp}`);
  },
};

export default personalInfoServices;
