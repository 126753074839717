import apiClient from "./axios.config";

const blogService = {
  getFeaturedBlog() {
    return apiClient.get("/website/blogs/featured");
  },
  getBlogListing(payload) {
    return apiClient.post("/website/blogs/listing", payload);
  },
  getBlogDetail(payload) {
    return apiClient.post(
      `/website/blog-detail?blogId=${payload.blogId}&blogURL=${payload.blogURL}`
    );
  },
};

export default blogService;
