import React, { useState } from "react";
import { validateForm } from "../utils/validation/validate-contact-us"; // Import validation logic
import mailIcon from "../assets/images/icons/mail.svg";
import mapPinIcon from "../assets/images/icons/map-pin.svg";
import phoneIcon from "../assets/images/icons/phone.svg";
import { Col, Container, Row } from "react-bootstrap"; // Import Button and Form from react-bootstrap
import { submitContactForm } from "../services/contact-service"; // Import the service function
import PhoneInput from "react-phone-number-input"; // Import PhoneInput
import "react-phone-number-input/style.css"; // Import PhoneInput styles
import { handleErrors } from "../utils/errorHandler";
import { getLeadRequestDetails } from "../services/contact-crm-service"; // Import the new service function
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "0",
    subject: "",
    description: "",
  });

  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false); // Track if the form is submitting

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm({
      ...formData,
      phone: phoneNumberValue,
    });
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const contactFormData = {
          ...formData,
          phone: phoneNumberValue,
        };

        const leadFormData = {
          FullName: formData.name,
          EmailId: formData.email,
          ContactNumber: phoneNumberValue,
          Country: formData.country,
          Notes: `${formData.subject} - ${formData.description}`,
        };

        const [contactResponse, leadResponse] = await Promise.all([
          submitContactForm(contactFormData),
          getLeadRequestDetails(leadFormData),
        ]);

        if (contactResponse.status === 200 && leadResponse) {
          setSubmissionStatus(
            "Thank you for contacting us! We’ll be in touch soon."
          );
          setFormData({
            name: "",
            email: "",
            country: "0",
            subject: "",
            description: "",
          });
          setPhoneNumberValue("");
        } else {
          throw new Error("Failed to submit the form.");
        }
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response && error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Sowerent</title>
        <meta
          name="description"
          content="Get in touch with us for any inquiries or assistance. We are here to help!"
        />
      </Helmet>
      <section className="grediant-section-bg pb-0">
        <Container>
          <Row>
            <Col lg={6} className="mx-auto">
              <div className="rent-prop-title text-center">
                <h1>Contact Us</h1>
                <p>
                  Connected residences might be owned by a single entity and
                  leased out, or owned separately with an agreement covering the
                  relationship between units and common areas and concerns.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section contact-info-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              {submissionStatus ? (
                <div
                  className={`alert ${
                    submissionStatus.includes("Thank you")
                      ? "alert-success"
                      : "alert-danger"
                  }`}
                  role="alert"
                >
                  {submissionStatus}
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="card">
                    <div className="card-header">
                      <h3>Get In Touch</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12 col-12">
                          <div className="form-group">
                            <label>Your Name</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Your Name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                            {errors.name && (
                              <div className="text-danger">{errors.name}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-12">
                          <div className="form-group">
                            <label>Phone Number</label>
                            <PhoneInput
                              international
                              type="phone"
                              maxLength="15"
                              placeholder="Enter phone number"
                              defaultCountry="IN"
                              value={phoneNumberValue}
                              onChange={setPhoneNumberValue} // Set the phone number state
                            />
                            {errors.phone && (
                              <div className="text-danger">{errors.phone}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-12">
                          <div className="form-group">
                            <label>Email Address</label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter Email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                            {errors.email && (
                              <div className="text-danger">{errors.email}</div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-12">
                          <div className="form-group">
                            <label>Country</label>
                            <select
                              name="country"
                              className="form-control"
                              value={formData.country}
                              onChange={handleChange}
                            >
                              <option value="0">Select </option>
                              <option value="India">India</option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="Australia">Australia</option>
                              <option value="Oman">Oman</option>
                              <option value="Qatar">Qatar</option>
                            </select>
                            {errors.country && (
                              <div className="text-danger">
                                {errors.country}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-12">
                          <div className="form-group">
                            <label>Subject</label>
                            <input
                              type="text"
                              name="subject"
                              className="form-control"
                              placeholder="Enter Subject"
                              value={formData.subject}
                              onChange={handleChange}
                            />
                            {errors.subject && (
                              <div className="text-danger">
                                {errors.subject}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Description</label>
                            <textarea
                              name="description"
                              className="form-control"
                              rows="5"
                              placeholder="Comments"
                              value={formData.description}
                              onChange={handleChange}
                            ></textarea>
                            {errors.description && (
                              <div className="text-danger">
                                {errors.description}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit Enquiry"}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>

            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="flex-fill">
                    <div className="contact-info-details d-flex align-items-center">
                      <span>
                        <img src={phoneIcon} alt="" />
                      </span>
                      <div>
                        <h4>Call Us At</h4>
                        <a href="tel:+918867319933">+91 8867319933</a>,
                        <a href="tel:+918867319944">+91 8867319944</a>,
                        <a href="tel:+918867319955">+91 8867319955</a>,
                        <a href="tel:+917204913042">+91 7204913042</a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="flex-fill">
                    <div className="contact-info-details d-flex align-items-center">
                      <span>
                        <img src={mailIcon} alt="" />
                      </span>
                      <div>
                        <h4>Email Us</h4>
                        <a href="mailto:helpdesk@sowerent.com">
                          helpdesk@sowerent.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 d-flex">
                  <div className="flex-fill">
                    <div className="contact-info-details d-flex align-items-center">
                      <span>
                        <img src={mapPinIcon} alt="" />
                      </span>
                      <div>
                        <h4>Location</h4>
                        <p>
                          #238/239, 2nd floor, Raheja Arcade,Koramanagala,
                          Bangalore,Karnataka-560095
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="map-location">
                <h3>Find Us On</h3>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2967.8862835683544!2d-73.98256668525309!3d41.93829486962529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e81a20b7359e41%3A0x4f3e23324ec66f82!2sB%C3%B6rger%20Medien!5e0!3m2!1sen!2sin!4v1639131337261!5m2!1sen!2sin"
                  width="100%"
                  height="400"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  title="Office Location"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
